* {
  outline: none !important;
}

html,
body {
  height: 100%;
  overflow: inherit !important;
}

:root {
  --main-color: #ca0444;
  --main-color-light: #e41c5b;
  --red-in-black: #ff397a;
  --dark-bg: #151515;
  --dark-bg-2: #0b0b0b;
  --french-rose: #f25472;
  --default-font-color: #151515;
  --secondary-font-color: #013841;
  --gray-color: #aeaeae;
  --dark-gray: #4b4a4a;
  --black: #000;
  --white: #fff;
  --body-color: #fdfdfd;
  --dark-gray-color: #585858;
  --gray-color2: #c4c4c4;
  --labels-color: #949494;
  --owl-nav-btn-color: #a6a6a6;

  --border-color: #d3d3d3;

  --default-font-size: 14px;
  --default-font-family: "Roboto", sans-serif;

  --default-box-shadow: 0px 0px 10px rgba(0, 10, 35, 0.09);
  --default-box-shadow-hover: 0px 0px 10px rgba(0, 50, 79, 0.25);
  --blocks-margin-size: 20px;
  --blocks-padding-size: 15px;
  --post-item-title-fs: 18px;
  --img-scale-transition: transform 0.6s ease-out;
  --sidebar-width: 340px;
  --sidebar-left-margin: 100px;
  --container-tablet-padding: 64px;
}

body.redesigned {
  --default-font-family: Montserrat, Arial, "Helvetica Neue", Helvetica, sans-serif;
}

@include for-md1279 {
  :root {
    --sidebar-left-margin: 50px;
  }
}

body {
  font-family: var(--default-font-family);
  font-size: var(--default-font-size);
  color: var(--default-font-color);
  min-width: 320px;
  min-height: 100%;
  background-color: var(--body-color);
  line-height: 1.6;

  &.modal-open {
    overflow: hidden !important;
  }
}

a {
  color: var(--main-color);
}

.btn-main {
  color: var(--white);
  background: linear-gradient(180deg, #ca0444 0%, #e01355 52.08%, #ca0444 100%);
  border-color: var(--main-color);
  border-radius: 0;
  font-size: 15px;
  font-weight: 700;
  transition: all 0.3s;
  padding: 21.5px 40px;
  border: 2px solid var(--main-color);
  line-height: 1;

  &:not(.__unchange) {
    @include for-xs {
      // padding: 16px 39px;
      // border-width: 1px;
    }
  }

  &.__smaller {
    padding: 19.5px 38px;
  }

  &.__small {
    padding: 14.5px 38px;
  }

  &.__white {
    background: white;
    color: var(--main-color);
  }

  &:hover {
    color: var(--white);
    background-color: var(--main-color-light);
    border-color: var(--main-color-light);
    box-shadow: 0 8px 6px rgba(#f25472, $alpha: 0.4);
  }
}

.link-btn {
  font-size: 15px;
  border-bottom: 1px solid var(--main-color);
  font-style: italic;
  @include dec-res;
  color: var(--main-color) !important;
}

a.btn-main {
  @include dec-res;
}

.btn-large {
  font-size: 16px;

  padding: {
    top: 19px;
    bottom: 19px;
    left: 30px;
    right: 30px;
  }
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.container {
  max-width: 1330px;

  @include for-tablet {
    padding-left: var(--container-tablet-padding);
    padding-right: var(--container-tablet-padding);

    .row {
      margin-left: calc(var(--container-tablet-padding) - ((var(--container-tablet-padding) * 2)));
      margin-right: calc(var(--container-tablet-padding) - ((var(--container-tablet-padding) * 2)));
    }
  }

  @include for-md639 {
    padding-left: var(--blocks-margin-size);
    padding-right: var(--blocks-margin-size);

    .row {
      margin-left: calc(var(--blocks-margin-size) - (var(--blocks-margin-size) * 2));
      margin-right: calc(var(--blocks-margin-size) - (var(--blocks-margin-size) * 2));
    }
  }
}

img {
  max-width: 100%;
}

.png-icon {
  display: inline-block;
  vertical-align: middle;
}

.title-1 {
  font-style: italic;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  color: var(--main-color);
  border-bottom: 2px solid var(--main-color);
  margin-bottom: 15px;
  margin-top: 0 !important;

  &.__smallerForMob {
    @include for-xs {
      font-size: 20px;
    }
  }

  &.__reset-border {
    border: none;
  }

  &.__blackColor {
    color: var(--dark-bg);
  }
}

.title-2 {
  font-style: italic;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 40px;
  margin-top: 0 !important;

  @include for-md639 {
    text-align: center;
  }
}

.sub-title-1 {
  font-style: italic;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: 0 !important;
}

.main-container {
  overflow: hidden;
}

.custom-checkbox {
  padding-left: 34px;
}

.custom-control-label::after,
.custom-control-label::before {
  width: 24px;
  height: 24px;
  left: -34px;
  border-radius: 0 !important;
  top: calc(50% - 12px);
}

.custom-control-label::before {
  background: transparent;
  border: 1px solid var(--border-color);
}

.form-control {
  background: var(--body-color);
}

.default-box-shadow {
  box-shadow: var(--default-box-shadow);
}

.text-checkbox {
  span {
    cursor: pointer;
    font-size: 14px;
    color: var(--default-font-color);
    border-bottom: 1px solid transparent;
  }

  input {
    display: none;

    &:checked + span {
      font-size: 15px;
      font-weight: 700;
      color: var(--main-color);
      font-style: italic;
      border-bottom-color: var(--main-color);
    }
  }
}

.color-checkbox {
  span {
    cursor: pointer;
    width: 30px;
    height: 30px;
    transition: transform 0.4s;
    display: block;

    @include for-xs {
      width: 40px;
      height: 40px;
    }
  }

  input {
    display: none;

    &:checked + span {
      transform: scale(1.15);
    }
  }
}

.tooltip .arrow {
  display: none;
}

.tooltip {
  box-shadow: var(--default-box-shadow);
}

.tooltip > .tooltip-inner {
  background-color: var(--white) !important;
  color: var(--default-font-color);
}
