// block justify 
@mixin justify {
	text-align:justify;
		&:after {
			display:inline-block;
			width:100%;
			content: "";
		}
		> li {
			@include inline-block;
		}
}



// text decoration
@mixin dec-y {
	text-decoration: underline;
		&:hover {
			text-decoration:none;
		}
}
@mixin dec-n {
	text-decoration: none;
		&:hover {
			text-decoration:underline;
		}
}
@mixin dec-res {
	text-decoration: none;
		&:hover {
			text-decoration: none;
		}
}
@mixin dec-under {
	text-decoration: underline;
}

// clearfix
@mixin clearfix {
	zoom:1;
		&:before, &:after {
			display:block;
			height:0;
			content: "";
			font-size:0;
			visibility: hidden;
		}
		&:after {
			clear:both;
		}
}

// reset ul|ol list
@mixin reset-list {
	margin:0;
	padding:0;
	list-style:none;
}


// opecity
/* @mixin opacity($f, $s) {
	opacity:$f;
	filter:alpha(opacity=$s);
} */

// flex content vertical align

// fa icons
@mixin icon($icon) {
    @include fa-icon;
    @extend .fa;
    @extend .fa-#{$icon}:before;
}


@mixin  swipeText($mainColor, $hoverColor) {
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-image: linear-gradient(
			to right,
			$hoverColor,
			$hoverColor 50%,
			$mainColor 50%);
			background-size: 200% 100%;
			background-position: 100%;
	&:hover {
		transition: all 0.5s cubic-bezier(0.000, 0.000, 0.230, 1);
		background-position: 0%;
	}
}