@charset "UTF-8";
/* @mixin opacity($f, $s) {
	opacity:$f;
	filter:alpha(opacity=$s);
} */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #80bdff; }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
      color: #000;
      text-decoration: none;
      opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: ""; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
    .modal-dialog-centered::before {
      height: calc(100vh - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: transform 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
      transition: none; } }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.png-icon-ex-point, .result blockquote::before {
  background-image: url("../images/sprite.png");
  background-position: -281px -78px;
  width: 10px;
  height: 65px; }
  .png-icon-ex-point + breakpoint(retina), .result blockquote::before + breakpoint(retina) {
    background-image: url("../images/sprite-2x.png");
    background-size: 304px 194px; }

.png-icon-mark, .list-1 li::before, .answer-arguments__details ul li::before, .answer-post-table ul li::before {
  background-image: url("../images/sprite.png");
  background-position: -207px -148px;
  width: 15px;
  height: 11px; }
  .png-icon-mark + breakpoint(retina), .list-1 li::before + breakpoint(retina), .answer-arguments__details ul li::before + breakpoint(retina), .answer-post-table ul li::before + breakpoint(retina) {
    background-image: url("../images/sprite-2x.png");
    background-size: 304px 194px; }

.png-icon-new {
  background-image: url("../images/sprite.png");
  background-position: -207px -78px;
  width: 74px;
  height: 70px; }
  .png-icon-new + breakpoint(retina) {
    background-image: url("../images/sprite-2x.png");
    background-size: 304px 194px; }

.png-icon-service-1 {
  background-image: url("../images/sprite.png");
  background-position: -97px -104px;
  width: 98px;
  height: 80px; }
  .png-icon-service-1 + breakpoint(retina) {
    background-image: url("../images/sprite-2x.png");
    background-size: 304px 194px; }

.png-icon-service-2 {
  background-image: url("../images/sprite.png");
  background-position: 0px -104px;
  width: 97px;
  height: 90px; }
  .png-icon-service-2 + breakpoint(retina) {
    background-image: url("../images/sprite-2x.png");
    background-size: 304px 194px; }

.png-icon-service-3 {
  background-image: url("../images/sprite.png");
  background-position: -102px 0px;
  width: 105px;
  height: 89px; }
  .png-icon-service-3 + breakpoint(retina) {
    background-image: url("../images/sprite-2x.png");
    background-size: 304px 194px; }

.png-icon-service-4 {
  background-image: url("../images/sprite.png");
  background-position: -207px 0px;
  width: 97px;
  height: 78px; }
  .png-icon-service-4 + breakpoint(retina) {
    background-image: url("../images/sprite-2x.png");
    background-size: 304px 194px; }

.png-icon-service-5 {
  background-image: url("../images/sprite.png");
  background-position: 0px 0px;
  width: 102px;
  height: 104px; }
  .png-icon-service-5 + breakpoint(retina) {
    background-image: url("../images/sprite-2x.png");
    background-size: 304px 194px; }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor; }

.icon-arrow-down {
  font-size: 0.6rem;
  width: 1.66667em; }

.icon-arrow-up {
  font-size: 1.8rem;
  width: 1em; }

.icon-check {
  font-size: 1.1rem;
  width: 1.36364em; }

.icon-close {
  font-size: 1.4rem;
  width: 1em; }

.icon-down {
  font-size: 2rem;
  width: 0.9em; }

.icon-email {
  font-size: 1.2rem;
  width: 1em; }

.icon-eye {
  font-size: 1.4rem;
  width: 1.42857em; }

.icon-fb {
  font-size: 1.2rem;
  width: 1em; }

.icon-folder {
  font-size: 2.4rem;
  width: 1.125em; }

.icon-google {
  font-size: 2.4rem;
  width: 1em; }

.icon-ic_adapt {
  font-size: 6.803rem;
  width: 1em; }

.icon-inst {
  font-size: 1.2rem;
  width: 1em; }

.icon-lock {
  font-size: 3.2rem;
  width: 0.75em; }

.icon-menu {
  font-size: 2.1rem;
  width: 0.2381em; }

.icon-pencil {
  font-size: 1.9rem;
  width: 1em; }

.icon-phone {
  font-size: 1.9rem;
  width: 1em; }

.icon-phone-contact {
  font-size: 2rem;
  width: 1em; }

.icon-photo {
  font-size: 2.7rem;
  width: 1.03704em; }

.icon-plus-add {
  font-size: 3rem;
  width: 1em; }

.icon-plus-thin {
  font-size: 2.4rem;
  width: 1em; }

.icon-save {
  font-size: 2.7rem;
  width: 1em; }

.icon-star {
  font-size: 2.3rem;
  width: 1.04348em; }

.icon-star-o {
  font-size: 2.3rem;
  width: 1.04348em; }

.icon-support {
  font-size: 7.5rem;
  width: 1.01333em; }

.icon-tg {
  font-size: 1.2rem;
  width: 1.16667em; }

.icon-trash {
  font-size: 3rem;
  width: 0.86667em; }

.icon-twitter {
  font-size: 1rem;
  width: 1.4em; }

.icon-unlock {
  font-size: 3.2rem;
  width: 0.75em; }

.icon-up {
  font-size: 2rem;
  width: 0.9em; }

.icon-user-o {
  font-size: 4.6rem;
  width: 1em; }

.icon-viber {
  font-size: 1.8rem;
  width: 0.94444em; }

.icon-vk {
  font-size: 0.9rem;
  width: 1.55556em; }

.icon-whatsapp {
  font-size: 1.4rem;
  width: 1em; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-MediumItalic.eot");
  src: url("../fonts/roboto/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-MediumItalic.woff2") format("woff2"), url("../fonts/roboto/Roboto-MediumItalic.woff") format("woff"), url("../fonts/roboto/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-Regular.eot");
  src: url("../fonts/roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Regular.woff2") format("woff2"), url("../fonts/roboto/Roboto-Regular.woff") format("woff"), url("../fonts/roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-Thin.eot");
  src: url("../fonts/roboto/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Thin.woff2") format("woff2"), url("../fonts/roboto/Roboto-Thin.woff") format("woff"), url("../fonts/roboto/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-Light.eot");
  src: url("../fonts/roboto/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Light.woff2") format("woff2"), url("../fonts/roboto/Roboto-Light.woff") format("woff"), url("../fonts/roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-Italic.eot");
  src: url("../fonts/roboto/Roboto-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Italic.woff2") format("woff2"), url("../fonts/roboto/Roboto-Italic.woff") format("woff"), url("../fonts/roboto/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-Black.eot");
  src: url("../fonts/roboto/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Black.woff2") format("woff2"), url("../fonts/roboto/Roboto-Black.woff") format("woff"), url("../fonts/roboto/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-BlackItalic.eot");
  src: url("../fonts/roboto/Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-BlackItalic.woff2") format("woff2"), url("../fonts/roboto/Roboto-BlackItalic.woff") format("woff"), url("../fonts/roboto/Roboto-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-BoldItalic.eot");
  src: url("../fonts/roboto/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-BoldItalic.woff2") format("woff2"), url("../fonts/roboto/Roboto-BoldItalic.woff") format("woff"), url("../fonts/roboto/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-Bold.eot");
  src: url("../fonts/roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Bold.woff2") format("woff2"), url("../fonts/roboto/Roboto-Bold.woff") format("woff"), url("../fonts/roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-ThinItalic.eot");
  src: url("../fonts/roboto/Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-ThinItalic.woff2") format("woff2"), url("../fonts/roboto/Roboto-ThinItalic.woff") format("woff"), url("../fonts/roboto/Roboto-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-Medium.eot");
  src: url("../fonts/roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Medium.woff2") format("woff2"), url("../fonts/roboto/Roboto-Medium.woff") format("woff"), url("../fonts/roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-LightItalic.eot");
  src: url("../fonts/roboto/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-LightItalic.woff2") format("woff2"), url("../fonts/roboto/Roboto-LightItalic.woff") format("woff"), url("../fonts/roboto/Roboto-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(1.6); }

.lds-ripple div {
  position: absolute;
  border: 4px solid var(--main-color);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s; }

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0; } }

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */ }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none; }

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }

.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */ }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item .owl-lazy[src^=""],
.owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-nav [class*='owl-'] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px; }

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none; }

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline; }

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px; }

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791; }

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: -19px;
  display: none; }
  @media (max-width: 1023px) {
    .ham {
      display: block; } }

.hamRotate.active {
  transform: rotate(45deg); }

.hamRotate180.active {
  transform: rotate(180deg); }

.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: var(--white);
  stroke-width: 4;
  stroke-linecap: round; }

.ham1 .top {
  stroke-dasharray: 40 139; }

.ham1 .bottom {
  stroke-dasharray: 40 180; }

.ham1.active .top {
  stroke-dashoffset: -93px; }

.ham1.active .bottom {
  stroke-dashoffset: -145px; }

.resizable {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 100px;
  left: 100px; }

.resizable .resizers {
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  box-sizing: border-box;
  transition: border-color 0.4s; }
  .resizable .resizers:hover {
    border-color: var(--main-color); }
    .resizable .resizers:hover .resizer,
    .resizable .resizers:hover .remove-img {
      opacity: 1; }

.resizable .resizers .resizer {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  /*magic to turn square into circle*/
  background: var(--white);
  border: 2px solid var(--main-color);
  position: absolute;
  opacity: 0;
  transition: opacity 0.4s; }

.resizable .resizers .resizer.top-left {
  left: -5px;
  top: -5px;
  cursor: nwse-resize;
  /*resizer cursor*/ }

.resizable .resizers .resizer.top-right {
  right: -5px;
  top: -5px;
  cursor: nesw-resize; }

.resizable .resizers .resizer.bottom-left {
  left: -5px;
  bottom: -5px;
  cursor: nesw-resize; }

.resizable .resizers .resizer.bottom-right {
  right: -5px;
  bottom: -5px;
  cursor: nwse-resize; }

@media (max-width: 1023px) {
  .flex-tablet-column {
    flex-direction: column; } }

@media (max-width: 1023px) {
  .flex-tablet-column-reverse {
    flex-direction: column-reverse; } }

.mb-70 {
  margin-bottom: 85px; }
  @media (max-width: 1023px) {
    .mb-70 {
      margin-bottom: 60px; } }

.bg-img {
  background-repeat: no-repeat;
  background-size: cover; }
  .bg-img img {
    opacity: 0; }

@media (max-width: 1023px) {
  .tablet-mb-50 {
    margin-bottom: 50px; } }

@media (max-width: 575px) {
  .text-xs-center {
    text-align: center; } }

.mb-6 {
  margin-bottom: 60px !important; }
  @media (max-width: 575px) {
    .mb-6 {
      margin-bottom: 40px; } }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-50 {
  margin-bottom: 50px; }

@media (max-width: 575px) {
  .mob-hide {
    display: none !important; } }

@media (max-width: 575px) {
  .mob-show {
    display: block !important; } }

.mt-60 {
  margin-top: 60px; }
  @media (max-width: 767px) {
    .mt-60 {
      margin-top: 40px; } }

.mb-60 {
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .mb-60 {
      margin-bottom: 40px; } }

body .owl-dots.disabled {
  display: none !important; }

.fs-16 {
  font-size: 16px; }

* {
  outline: none !important; }

html,
body {
  height: 100%;
  overflow: inherit !important; }

:root {
  --main-color: #ca0444;
  --main-color-light: #e41c5b;
  --red-in-black: #ff397a;
  --dark-bg: #151515;
  --dark-bg-2: #0b0b0b;
  --french-rose: #f25472;
  --default-font-color: #151515;
  --secondary-font-color: #013841;
  --gray-color: #aeaeae;
  --dark-gray: #4b4a4a;
  --black: #000;
  --white: #fff;
  --body-color: #fdfdfd;
  --dark-gray-color: #585858;
  --gray-color2: #c4c4c4;
  --labels-color: #949494;
  --owl-nav-btn-color: #a6a6a6;
  --border-color: #d3d3d3;
  --default-font-size: 14px;
  --default-font-family: "Roboto", sans-serif;
  --default-box-shadow: 0px 0px 10px rgba(0, 10, 35, 0.09);
  --default-box-shadow-hover: 0px 0px 10px rgba(0, 50, 79, 0.25);
  --blocks-margin-size: 20px;
  --blocks-padding-size: 15px;
  --post-item-title-fs: 18px;
  --img-scale-transition: transform 0.6s ease-out;
  --sidebar-width: 340px;
  --sidebar-left-margin: 100px;
  --container-tablet-padding: 64px; }

body.redesigned {
  --default-font-family: Montserrat, Arial, "Helvetica Neue", Helvetica, sans-serif; }

@media (max-width: 1279px) {
  :root {
    --sidebar-left-margin: 50px; } }

body {
  font-family: var(--default-font-family);
  font-size: var(--default-font-size);
  color: var(--default-font-color);
  min-width: 320px;
  min-height: 100%;
  background-color: var(--body-color);
  line-height: 1.6; }
  body.modal-open {
    overflow: hidden !important; }

a {
  color: var(--main-color); }

.btn-main {
  color: var(--white);
  background: linear-gradient(180deg, #ca0444 0%, #e01355 52.08%, #ca0444 100%);
  border-color: var(--main-color);
  border-radius: 0;
  font-size: 15px;
  font-weight: 700;
  transition: all 0.3s;
  padding: 21.5px 40px;
  border: 2px solid var(--main-color);
  line-height: 1; }
  .btn-main.__smaller {
    padding: 19.5px 38px; }
  .btn-main.__small {
    padding: 14.5px 38px; }
  .btn-main.__white {
    background: white;
    color: var(--main-color); }
  .btn-main:hover {
    color: var(--white);
    background-color: var(--main-color-light);
    border-color: var(--main-color-light);
    box-shadow: 0 8px 6px rgba(242, 84, 114, 0.4); }

.link-btn {
  font-size: 15px;
  border-bottom: 1px solid var(--main-color);
  font-style: italic;
  text-decoration: none;
  color: var(--main-color) !important; }
  .link-btn:hover {
    text-decoration: none; }

a.btn-main {
  text-decoration: none; }
  a.btn-main:hover {
    text-decoration: none; }

.btn-large {
  font-size: 16px;
  padding-top: 19px;
  padding-bottom: 19px;
  padding-left: 30px;
  padding-right: 30px; }

.btn:focus, .btn.focus {
  box-shadow: none; }

.container {
  max-width: 1330px; }
  @media (max-width: 1023px) {
    .container {
      padding-left: var(--container-tablet-padding);
      padding-right: var(--container-tablet-padding); }
      .container .row {
        margin-left: calc(var(--container-tablet-padding) - ((var(--container-tablet-padding) * 2)));
        margin-right: calc(var(--container-tablet-padding) - ((var(--container-tablet-padding) * 2))); } }
  @media (max-width: 639px) {
    .container {
      padding-left: var(--blocks-margin-size);
      padding-right: var(--blocks-margin-size); }
      .container .row {
        margin-left: calc(var(--blocks-margin-size) - (var(--blocks-margin-size) * 2));
        margin-right: calc(var(--blocks-margin-size) - (var(--blocks-margin-size) * 2)); } }

img {
  max-width: 100%; }

.png-icon {
  display: inline-block;
  vertical-align: middle; }

.title-1 {
  font-style: italic;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  color: var(--main-color);
  border-bottom: 2px solid var(--main-color);
  margin-bottom: 15px;
  margin-top: 0 !important; }
  @media (max-width: 575px) {
    .title-1.__smallerForMob {
      font-size: 20px; } }
  .title-1.__reset-border {
    border: none; }
  .title-1.__blackColor {
    color: var(--dark-bg); }

.title-2 {
  font-style: italic;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 40px;
  margin-top: 0 !important; }
  @media (max-width: 639px) {
    .title-2 {
      text-align: center; } }

.sub-title-1, .answer-arguments__details h3, .answer-post-table h3 {
  font-style: italic;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: 0 !important; }

.main-container {
  overflow: hidden; }

.custom-checkbox {
  padding-left: 34px; }

.custom-control-label::after,
.custom-control-label::before {
  width: 24px;
  height: 24px;
  left: -34px;
  border-radius: 0 !important;
  top: calc(50% - 12px); }

.custom-control-label::before {
  background: transparent;
  border: 1px solid var(--border-color); }

.form-control {
  background: var(--body-color); }

.default-box-shadow {
  box-shadow: var(--default-box-shadow); }

.text-checkbox span {
  cursor: pointer;
  font-size: 14px;
  color: var(--default-font-color);
  border-bottom: 1px solid transparent; }

.text-checkbox input {
  display: none; }
  .text-checkbox input:checked + span {
    font-size: 15px;
    font-weight: 700;
    color: var(--main-color);
    font-style: italic;
    border-bottom-color: var(--main-color); }

.color-checkbox span {
  cursor: pointer;
  width: 30px;
  height: 30px;
  transition: transform 0.4s;
  display: block; }
  @media (max-width: 575px) {
    .color-checkbox span {
      width: 40px;
      height: 40px; } }

.color-checkbox input {
  display: none; }
  .color-checkbox input:checked + span {
    transform: scale(1.15); }

.tooltip .arrow {
  display: none; }

.tooltip {
  box-shadow: var(--default-box-shadow); }

.tooltip > .tooltip-inner {
  background-color: var(--white) !important;
  color: var(--default-font-color); }

:root {
  --section-mb: 110px; }
  @media (max-width: 1023px) {
    :root {
      --section-mb: 90px; } }

.header {
  padding-bottom: 22px; }
  @media (max-width: 575px) {
    .header {
      padding-bottom: 30px; } }
  .header__top {
    padding: 44px 0 31px; }
    @media (max-width: 1023px) {
      .header__top {
        padding-top: 36px;
        padding-bottom: 60px;
        position: relative;
        margin-bottom: 35px; } }
    @media (max-width: 575px) {
      .header__top {
        padding: 12px 0;
        border-bottom: 1px solid var(--border-color);
        margin-bottom: 25px; }
        .header__top .social-nets {
          display: none !important; }
        .header__top .main-menu {
          display: none; } }
  .header__phone-number {
    font-size: 18px;
    text-decoration: none;
    transition: color 0.3s;
    color: var(--default-font-color);
    font-weight: bold; }
    .header__phone-number:hover {
      text-decoration: none; }
    .header__phone-number:hover {
      color: var(--main-color); }
    @media (max-width: 1023px) {
      .header__phone-number {
        font-size: 16px; } }
    @media (max-width: 575px) {
      .header__phone-number {
        font-size: 12px; } }
    .header__phone-number .icon {
      width: 19px; }
      @media (max-width: 575px) {
        .header__phone-number .icon {
          width: 14px;
          height: 14px; } }
  .header__bottom {
    position: relative; }
    @media (max-width: 575px) {
      .header__bottom .btn-main {
        font-size: 12px;
        line-height: 14px;
        white-space: normal;
        background: transparent;
        color: var(--main-color);
        padding: 9px 21px;
        margin-left: 30px;
        box-shadow: none;
        border-width: 1px;
        border-color: #dc1052; } }

@media (max-width: 575px) {
  .home-page .header__bottom {
    padding-bottom: calc(28px + var(--blocks-margin-size) * 2); } }

.logo {
  margin-left: -8px;
  max-width: 245px; }
  @media (max-width: 1023px) {
    .logo {
      max-width: 206px; } }
  @media (max-width: 575px) {
    .logo {
      margin-left: -5px;
      flex: 0 0 127px; } }

.social-nets a {
  width: 25px;
  height: 25px;
  background: linear-gradient(180deg, #CA0444 0%, #E01355 52.08%, #CA0444 100%);
  color: var(--white);
  text-decoration: none;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  transition: color 0.3s; }
  .social-nets a:hover {
    text-decoration: none; }
  .social-nets a:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--main-color-light);
    transition: 0.3s;
    transform: scale(0);
    opacity: 0;
    border-radius: 50%; }
    @media (max-width: 1023px) {
      .social-nets a:after {
        display: none; } }
  .social-nets a:hover::after {
    transform: scale(1);
    opacity: 1; }
  .social-nets a .icon {
    max-width: 13px;
    position: relative;
    z-index: 1; }

@media (max-width: 1023px) {
  .main-menu {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 calc(var(--container-tablet-padding) - var(--blocks-padding-size)); } }

@media (max-width: 639px) {
  .main-menu {
    padding: 0; } }

.main-menu ul {
  margin: 0;
  padding: 0;
  list-style: none; }
  @media (max-width: 1023px) {
    .main-menu ul {
      justify-content: space-between; } }
  .main-menu ul li {
    margin: 0 20px; }
    .main-menu ul li a {
      text-decoration: none;
      font-weight: 700;
      font-size: 16px;
      transition: color 0.3s;
      color: var(--default-font-color); }
      .main-menu ul li a:hover {
        text-decoration: none; }
      .main-menu ul li a:hover {
        color: var(--main-color); }
      @media (max-width: 1023px) {
        .main-menu ul li a {
          font-style: 16px; } }
      .main-menu ul li a.active {
        color: var(--main-color); }

.slogan {
  font-size: 19px;
  font-weight: normal;
  font-style: italic;
  max-width: 370px;
  color: var(--black); }
  @media (max-width: 1023px) {
    .slogan {
      display: none; } }

@media (max-width: 575px) {
  .home-page .slogan {
    display: block;
    font-size: 12px;
    margin-bottom: var(--blocks-padding-size) !important;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; } }

.main-categories {
  background: var(--dark-bg);
  position: relative;
  z-index: 9; }
  .main-categories.fixed {
    transition: transform 0.4s;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    transform: translate(0, -100%); }
    .main-categories.fixed.fixed-show {
      transform: translate(0); }
  .main-categories > .container {
    min-height: 70px; }
  .main-categories ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  @media (max-width: 1023px) {
    .main-categories__list-wrap {
      display: none;
      width: 320px;
      left: 0;
      top: 100%;
      position: absolute;
      background: var(--dark-bg);
      box-shadow: 0px 0px 4px rgba(238, 238, 238, 0.25);
      padding: 15px 30px 15px 15px !important;
      max-height: calc(100vh - 78px);
      overflow: auto; } }
  @media (max-width: 575px) {
    .main-categories__list-wrap {
      padding-right: 15px !important; } }
  .main-categories__list-wrap .main-menu {
    position: relative;
    top: 0;
    left: 0; }
    .main-categories__list-wrap .main-menu a,
    .main-categories__list-wrap .main-menu div {
      color: var(--white); }
    .main-categories__list-wrap .main-menu ul {
      flex-wrap: wrap; }
      .main-categories__list-wrap .main-menu ul li {
        width: 50%;
        margin: 0 0 10px; }
        .main-categories__list-wrap .main-menu ul li:nth-child(even) {
          text-align: right; }
  .main-categories__list-wrap .social-nets {
    justify-content: space-between;
    margin-bottom: 30px; }
    .main-categories__list-wrap .social-nets a {
      background: var(--white);
      color: var(--default-font-color); }
  .main-categories__list {
    margin-left: -15px;
    display: flex; }
    @media (max-width: 1023px) {
      .main-categories__list {
        flex-direction: column; } }
    @media (max-width: 575px) {
      .main-categories__list {
        margin-bottom: 25px !important; } }
    .main-categories__list .icon {
      width: 10px;
      margin-left: 10px;
      color: var(--owl-nav-btn-color); }
      @media (max-width: 1023px) {
        .main-categories__list .icon {
          margin-left: 0;
          margin-right: 1px;
          transition: transform 0.4s; } }
    .main-categories__list a {
      color: var(--white);
      text-decoration: none;
      transition: color 0.3s; }
      .main-categories__list a:hover {
        text-decoration: none; }
      @media (max-width: 1023px) {
        .main-categories__list a.active {
          border-bottom-color: transparent; }
          .main-categories__list a.active .icon {
            transform: rotate(180deg); } }
      .main-categories__list a:hover, .main-categories__list a.active {
        color: var(--main-color-light); }
    .main-categories__list li {
      overflow: hidden; }
      .main-categories__list li:hover {
        overflow: visible; }
        .main-categories__list li:hover > a {
          color: var(--main-color-light); }
        .main-categories__list li:hover > ul {
          opacity: 1; }
    .main-categories__list > li {
      position: relative; }
      .main-categories__list > li:not(:last-child) {
        margin-right: 45px; }
        @media (max-width: 1279px) {
          .main-categories__list > li:not(:last-child) {
            margin-right: 30px; } }
        @media (max-width: 1023px) {
          .main-categories__list > li:not(:last-child) {
            margin-right: 0; }
            .main-categories__list > li:not(:last-child) > a {
              border-bottom: 2px solid #464646; } }
      .main-categories__list > li > a,
      .main-categories__list > li div {
        font-weight: 700;
        display: block;
        padding: 35px 0;
        font-size: 16px;
        color: var(--white); }
        @media (max-width: 1023px) {
          .main-categories__list > li > a,
          .main-categories__list > li div {
            padding: 8px 0;
            display: flex;
            justify-content: space-between;
            align-items: center; } }
      .main-categories__list > li ul {
        position: absolute;
        top: 100%;
        left: 0;
        box-shadow: 0px 0px 4px rgba(238, 238, 238, 0.25);
        width: 230px;
        padding: 10px 0;
        background: var(--dark-bg);
        font-weight: normal;
        font-size: 15px;
        opacity: 0;
        transition: opacity 0.3s; }
        @media (max-width: 1023px) {
          .main-categories__list > li ul {
            position: relative;
            top: auto;
            left: auto;
            box-shadow: none;
            opacity: 1;
            display: none;
            width: auto;
            max-width: 90%; } }
        .main-categories__list > li ul a {
          display: flex;
          justify-content: space-between;
          align-items: center; }
        .main-categories__list > li ul .icon {
          transform: rotate(-90deg); }
          @media (max-width: 1023px) {
            .main-categories__list > li ul .icon {
              transform: rotate(0); } }
        .main-categories__list > li ul li {
          position: relative;
          padding: 5px 15px; }
          @media (max-width: 1023px) {
            .main-categories__list > li ul li {
              padding: 5px 0; } }
          .main-categories__list > li ul li:not(:last-child) {
            margin-bottom: 5px; }
        .main-categories__list > li ul ul {
          padding: 10px;
          font-size: 14px;
          left: 100%;
          top: 0; }
          @media (max-width: 1023px) {
            .main-categories__list > li ul ul {
              position: relative;
              top: auto;
              left: auto; } }
      .main-categories__list > li > ul {
        left: -15px; }
        @media (max-width: 1023px) {
          .main-categories__list > li > ul {
            left: 0; } }

.auth-block {
  color: var(--white); }
  @media (max-width: 575px) {
    .auth-block {
      position: relative; } }
  .auth-block h5 {
    font-size: 16px;
    font-weight: bold; }
    @media (max-width: 575px) {
      .auth-block h5 {
        display: none; } }
  .auth-block a {
    color: var(--white);
    text-decoration: none; }
    .auth-block a:hover {
      text-decoration: none; }
    .auth-block a br {
      display: none; }
      @media (max-width: 575px) {
        .auth-block a br {
          display: block; } }
  .auth-block .auth-img {
    margin-right: var(--blocks-margin-size); }
  @media (max-width: 575px) {
    .auth-block > div:not(.auth-img) {
      background: var(--dark-bg);
      top: calc(100% + 10px);
      right: -20px;
      position: absolute;
      padding: 14px 20px;
      text-align: center;
      max-width: 320px;
      font-size: 16px;
      white-space: nowrap;
      box-shadow: var(--default-box-shadow);
      transform: translate(105%, 0);
      transition: transform 0.4s; }
      .auth-block > div:not(.auth-img) .d-flex {
        flex-direction: column; }
      .auth-block > div:not(.auth-img).active {
        transform: translate(0); }
      .auth-block > div:not(.auth-img) a:not(:last-child) {
        margin-bottom: var(--blocks-padding-size); }
      .auth-block > div:not(.auth-img) span {
        display: none; } }

.auth-img {
  width: 49px;
  height: 49px; }
  .auth-img .icon-arrow-down {
    display: none; }
    @media (max-width: 575px) {
      .auth-img .icon-arrow-down {
        display: inline-block;
        vertical-align: top;
        width: 10px;
        height: 6px;
        color: var(--owl-nav-btn-color);
        position: absolute;
        top: calc(50% - 3px);
        left: calc(100% + 5px); } }
  @media (max-width: 575px) {
    .auth-img {
      position: relative;
      margin-right: 15px !important; } }
  .auth-img img {
    border-radius: 50%; }
  .auth-img img,
  .auth-img svg {
    width: 49px;
    height: 49px; }

main.content {
  padding: 55px 0 50px; }
  @media (max-width: 767px) {
    main.content {
      padding: 30px 0; } }

.content-body {
  flex: 1;
  max-width: calc(100% - (var(--sidebar-width) + var(--sidebar-left-margin))); }
  @media (max-width: 1023px) {
    .content-body {
      max-width: 100%; } }

.content-sidebar {
  width: var(--sidebar-width);
  margin-left: var(--sidebar-left-margin); }
  @media (max-width: 1023px) {
    .content-sidebar {
      width: auto;
      margin-left: 0; } }
  .content-sidebar .ad-block {
    margin: 60px 0; }

.services .service-item {
  width: calc(50% - 10px);
  box-shadow: var(--default-box-shadow);
  margin-bottom: var(--blocks-margin-size);
  position: relative;
  padding-bottom: 15px;
  overflow: hidden; }
  @media (max-width: 1023px) {
    .services .service-item {
      margin-bottom: 65px; }
      .services .service-item:nth-child(3), .services .service-item:nth-child(4) {
        margin-bottom: var(--blocks-margin-size); } }
  @media (max-width: 575px) {
    .services .service-item {
      width: 100%;
      margin: 0 0 var(--blocks-margin-size); } }
  .services .service-item:hover a {
    color: var(--main-color); }
  .services .service-item:hover .service-img img {
    transform: scale(1.1); }
  .services .service-item:hover .service-details {
    transform: translate(0); }
  .services .service-item:hover .service-desc {
    opacity: 1; }

.services .service-img {
  display: block;
  text-decoration: none;
  overflow: hidden;
  height: 180px;
  position: relative; }
  .services .service-img:hover {
    text-decoration: none; }
  .services .service-img img {
    display: block;
    width: 100%;
    height: 100%;
    transition: var(--img-scale-transition);
    object-fit: cover;
    object-position: center;
    position: absolute; }

.services .service-details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate(0, calc(100% - 55px));
  transition: transform 0.4s;
  background: var(--white);
  padding: 0 var(--blocks-padding-size) var(--blocks-padding-size); }
  @media (max-width: 1023px) {
    .services .service-details {
      transform: translate(0);
      position: relative; } }
  .services .service-details header {
    color: var(--main-color);
    font-weight: bold;
    font-size: var(--post-item-title-fs);
    align-items: flex-start !important;
    padding: 10px 0;
    line-height: 1.2; }
    .services .service-details header .text-uppercase {
      white-space: nowrap; }

.services .service-title {
  font-weight: 700;
  font-style: italic;
  transition: color 0.3s;
  color: var(--default-font-color);
  text-decoration: none; }
  .services .service-title:hover {
    color: var(--main-color); }
  .services .service-title:hover {
    text-decoration: none; }

.services .service-desc {
  transition: opacity 0.4s ease-out;
  opacity: 0.2; }
  @media (max-width: 1023px) {
    .services .service-desc {
      opacity: 1; } }

@media (max-width: 1023px) {
  .our-services {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 50px; } }

.our-services .service-item {
  box-shadow: var(--default-box-shadow);
  margin-bottom: 12px;
  padding: 15px 30px;
  transition: box-shadow 0.4s;
  background: var(--white);
  justify-content: space-between;
  position: relative; }
  .our-services .service-item:last-child {
    margin-bottom: -15px; }
    @media (max-width: 1023px) {
      .our-services .service-item:last-child {
        margin-bottom: 0; } }
  .our-services .service-item .png-icon-new {
    position: absolute;
    right: -3px;
    top: -3px;
    transform: scale(0.9); }
  @media (max-width: 1023px) {
    .our-services .service-item {
      width: calc(50% - 10px); } }
  @media (max-width: 575px) {
    .our-services .service-item {
      width: 100%;
      margin: 0 0 var(--blocks-margin-size); } }
  .our-services .service-item:hover {
    box-shadow: var(--default-box-shadow-hover); }
  .our-services .service-item:nth-child(even) {
    flex-direction: row-reverse; }
    .our-services .service-item:nth-child(even) .service-img {
      margin-left: 15px; }
  .our-services .service-item:nth-child(odd) .service-img {
    margin-right: 15px; }

.our-services .service-title {
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
  transition: color 0.3s;
  color: var(--default-font-color); }
  .our-services .service-title:hover {
    color: var(--main-color); }

.our-services .service-desc {
  font-weight: normal;
  font-size: 14px;
  line-height: 140%; }
  .our-services .service-desc a {
    color: var(--default-font-color); }

.inner-services-list .service-item:last-child {
  margin-bottom: 0; }

.post-item {
  box-shadow: var(--default-box-shadow);
  transition: box-shadow 0.3s;
  box-shadow: var(--default-box-shadow); }
  .post-item:hover {
    box-shadow: var(--default-box-shadow-hover); }
    .post-item:hover .post-item__img img {
      transform: scale(1.1); }
    .post-item:hover .post-item__title a {
      color: var(--main-color); }
  .post-item__img {
    position: relative;
    overflow: hidden;
    padding-top: 115px;
    display: block; }
    @media (max-width: 575px) {
      .post-item__img {
        padding-top: 55%; } }
    .post-item__img img {
      transition: var(--img-scale-transition);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: none;
      object-fit: cover;
      object-position: top; }
      @media (max-width: 575px) {
        .post-item__img img {
          width: 100%;
          max-width: 100%; } }
  .post-item__title {
    padding: 15px;
    font-weight: 700;
    font-size: 15px;
    display: block;
    color: var(--default-font-color);
    transition: color 0.3s;
    color: var(--default-font-color);
    text-decoration: none;
    background: var(--white); }
    .post-item__title:hover {
      color: var(--main-color); }
    .post-item__title:hover {
      text-decoration: none; }
    .post-item__title a {
      transition: color 0.3s;
      color: var(--default-font-color);
      text-decoration: none; }
      .post-item__title a:hover {
        color: var(--main-color); }
      .post-item__title a:hover {
        text-decoration: none; }

.posts-group {
  margin-bottom: var(--blocks-margin-size); }
  @media (max-width: 575px) {
    .posts-group {
      flex-direction: column; } }
  .posts-group .post-item:not(:last-child) {
    margin-bottom: var(--blocks-margin-size); }
  .posts-group .post-item.__large {
    margin-bottom: 0;
    min-height: 100%;
    position: relative; }
    @media (max-width: 575px) {
      .posts-group .post-item.__large {
        margin-bottom: var(--blocks-margin-size); } }
    .posts-group .post-item.__large .post-item__title {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0; }
      @media (max-width: 575px) {
        .posts-group .post-item.__large .post-item__title {
          position: relative; } }
    .posts-group .post-item.__large .post-item__img {
      padding-top: 53%;
      min-height: 100%; }
      @media (max-width: 575px) {
        .posts-group .post-item.__large .post-item__img {
          min-height: 0;
          padding-top: 55%; } }

.posts-group-2 .post-item {
  position: relative;
  width: calc(50% - var(--blocks-padding-size) / 2); }
  @media (max-width: 575px) {
    .posts-group-2 .post-item {
      width: 100%; } }
  .posts-group-2 .post-item__img {
    padding-top: 180px; }
    @media (max-width: 575px) {
      .posts-group-2 .post-item__img {
        padding-top: 135px; } }
  .posts-group-2 .post-item__title {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 28%;
    padding: 17px 24px;
    font-size: 20px;
    font-style: italic; }
    @media (max-width: 575px) {
      .posts-group-2 .post-item__title {
        font-size: 17px;
        position: relative;
        right: auto;
        line-height: 20px; } }
  .posts-group-2 .post-item:not(.__large) .post-item__title {
    right: 0; }
  .posts-group-2 .post-item.__large {
    width: 100%;
    margin-bottom: var(--blocks-padding-size); }
    .posts-group-2 .post-item.__large .post-item__img {
      padding-top: 400px; }
      @media (max-width: 575px) {
        .posts-group-2 .post-item.__large .post-item__img {
          padding-top: 135px; } }
    .posts-group-2 .post-item.__large .post-item__title {
      right: auto; }

@media (max-width: 575px) {
  .posts-horizontal-list {
    flex-direction: column; } }

@media (max-width: 575px) {
  .posts-horizontal-list .grouped-posts {
    flex-direction: column; } }

@media (max-width: 575px) {
  .posts-horizontal-list .grouped-posts .post-item {
    margin-bottom: var(--blocks-margin-size); } }

.posts-horizontal-list .grouped-posts .post-item:first-child {
  width: 55%; }
  @media (max-width: 575px) {
    .posts-horizontal-list .grouped-posts .post-item:first-child {
      width: 100%; } }

.posts-horizontal-list .grouped-posts .post-item:nth-child(2) {
  margin-left: var(--blocks-margin-size);
  width: calc(45% - 20px); }
  @media (max-width: 575px) {
    .posts-horizontal-list .grouped-posts .post-item:nth-child(2) {
      width: 100%;
      margin-left: 0; } }

.posts-horizontal-list.mob-carousel-posts .post-item {
  width: 35%; }
  .posts-horizontal-list.mob-carousel-posts .post-item:nth-child(2) {
    flex: 1;
    margin: 0 var(--blocks-margin-size); }
  @media (max-width: 575px) {
    .posts-horizontal-list.mob-carousel-posts .post-item {
      width: 100% !important;
      margin: 0 auto var(--blocks-margin-size) !important;
      max-width: 320px; } }

.grouped-posts {
  width: 65%;
  margin-right: var(--blocks-margin-size);
  min-width: 424px; }
  @media (max-width: 639px) {
    .grouped-posts {
      min-width: 320px;
      width: 320px; } }
  @media (max-width: 575px) {
    .grouped-posts {
      width: 100%;
      min-width: 0; } }

@media (max-width: 1023px) {
  .articles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .articles .article-item {
      width: calc(50% - var(--blocks-margin-size)); } }

@media (max-width: 575px) {
  .articles .article-item {
    width: 100%; } }

.services-list .article-item:nth-child(even) {
  flex-direction: row; }
  @media (max-width: 1023px) {
    .services-list .article-item:nth-child(even) {
      flex-direction: row; } }

.article-item {
  line-height: 1.3; }
  @media (max-width: 1023px) {
    .article-item {
      align-items: flex-start !important; } }
  .article-item:hover .article-item__img {
    border-radius: 0 20px 0 20px;
    box-shadow: var(--default-box-shadow-hover); }
  .article-item:hover .article-item__title {
    color: var(--main-color); }
  .article-item:not(:last-child) {
    margin-bottom: var(--blocks-margin-size); }
  .article-item:nth-child(even) {
    flex-direction: row-reverse; }
    @media (max-width: 1023px) {
      .article-item:nth-child(even) {
        flex-direction: row; } }
  .article-item__img {
    display: block;
    max-width: 160px;
    box-shadow: var(--default-box-shadow);
    overflow: hidden;
    transition: border-radius 0.4s, box-shadow 0.4s ease-out;
    height: 140px;
    width: 42%;
    position: relative; }
    .article-item__img img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      max-width: none;
      position: absolute;
      top: 0;
      left: 0; }
  .article-item__details {
    width: calc(58% - var(--blocks-margin-size));
    line-height: 1.4; }
    @media (max-width: 1023px) {
      .article-item__details {
        width: auto;
        flex: 1;
        margin-left: var(--blocks-margin-size); } }
  .article-item__title {
    font-weight: 700;
    font-size: var(--post-item-title-fs);
    line-height: 1.2;
    margin-bottom: 7px;
    font-style: italic;
    display: block;
    transition: color 0.3s;
    color: var(--default-font-color);
    text-decoration: none; }
    .article-item__title:hover {
      color: var(--main-color); }
    .article-item__title:hover {
      text-decoration: none; }
  .article-item__date {
    color: var(--gray-color);
    margin-bottom: 7px; }
  .article-item__state {
    color: var(--main-color);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 17px;
    margin: 8px 0; }

.ad-block {
  box-shadow: var(--default-box-shadow);
  overflow: hidden;
  position: relative; }
  .ad-block img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
    @media (max-width: 767px) {
      .ad-block img:not(.__mob) {
        display: none; } }
    .ad-block img.__mob {
      display: none; }
      @media (max-width: 767px) {
        .ad-block img.__mob {
          display: block; } }

.color-posts-section {
  position: relative;
  padding: 65px 0 calc(70px - var(--blocks-margin-size)); }
  @media (max-width: 1023px) {
    .color-posts-section {
      background: var(--dark-bg);
      margin-left: calc(var(--container-tablet-padding) - ((var(--container-tablet-padding) * 2)));
      margin-right: calc(var(--container-tablet-padding) - ((var(--container-tablet-padding) * 2)));
      padding: 55px var(--container-tablet-padding) 35px; } }
  @media (max-width: 639px) {
    .color-posts-section {
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px; } }
  .color-posts-section .sub-title-1, .color-posts-section .answer-arguments__details h3, .answer-arguments__details .color-posts-section h3, .color-posts-section .answer-post-table h3, .answer-post-table .color-posts-section h3 {
    color: var(--white); }
  .color-posts-section::before, .color-posts-section::after {
    position: absolute;
    top: 0;
    bottom: 0;
    content: "";
    display: block;
    background: var(--dark-bg); }
    @media (max-width: 1023px) {
      .color-posts-section::before, .color-posts-section::after {
        display: none; } }
  .color-posts-section::before {
    right: 100%;
    width: 50vw; }
  .color-posts-section::after {
    width: calc(100% - var(--sidebar-width)); }
  .color-posts-section.__full {
    padding-top: 45px;
    padding-bottom: 45px; }
    .color-posts-section.__full::after {
      width: auto;
      right: -40px;
      left: 0; }
    .color-posts-section.__full .title-1 {
      color: var(--white);
      border-color: var(--white); }
    .color-posts-section.__full .posts-grid .post-item:hover a {
      color: var(--main-color-light); }
    .color-posts-section.__full .posts-grid .post-item__title {
      background: none; }
      .color-posts-section.__full .posts-grid .post-item__title a {
        color: var(--white); }
  .color-posts-section > * {
    position: relative;
    z-index: 1; }
  @media (max-width: 1023px) {
    .color-posts-section .posts-group {
      flex-direction: column; }
      .color-posts-section .posts-group > div {
        display: flex;
        justify-content: space-between; } }
    @media (max-width: 1023px) and (max-width: 575px) {
      .color-posts-section .posts-group > div {
        flex-direction: column; } }
  @media (max-width: 1023px) {
        .color-posts-section .posts-group > div .post-item {
          width: calc(50% - 10px);
          margin-bottom: 0; } }
      @media (max-width: 1023px) and (max-width: 575px) {
        .color-posts-section .posts-group > div .post-item {
          width: 100%;
          margin-bottom: var(--blocks-margin-size); } }
  @media (max-width: 575px) {
    .color-posts-section .posts-group {
      margin-bottom: 0; } }
  .color-posts-section .post-item {
    width: 310px;
    position: relative; }
    .color-posts-section .post-item.__large {
      width: auto;
      flex: 1;
      margin-right: var(--blocks-margin-size); }
      @media (max-width: 1023px) {
        .color-posts-section .post-item.__large {
          margin-bottom: 30px;
          margin-right: 0; } }
      @media (max-width: 575px) {
        .color-posts-section .post-item.__large {
          margin-bottom: var(--blocks-margin-size); } }
      .color-posts-section .post-item.__large .post-item__img {
        height: 100%; }
        @media (max-width: 1023px) {
          .color-posts-section .post-item.__large .post-item__img {
            height: 400px;
            padding-top: 0; }
            .color-posts-section .post-item.__large .post-item__img img {
              min-height: 100%; } }
        @media (max-width: 575px) {
          .color-posts-section .post-item.__large .post-item__img {
            height: 240px; } }
      .color-posts-section .post-item.__large .post-item__title {
        right: 50%; }
    .color-posts-section .post-item.__medium {
      width: auto;
      margin-right: 0;
      margin-left: calc((var(--sidebar-left-margin) - (var(--sidebar-left-margin) * 2)) + var(--blocks-margin-size));
      height: calc(100% - var(--blocks-margin-size)); }
      .color-posts-section .post-item.__medium:hover .post-item__img img {
        transform: translate(-10%, 0) scale(1.1); }
      @media (max-width: 1023px) {
        .color-posts-section .post-item.__medium {
          margin-left: 0;
          height: 400px; }
          .color-posts-section .post-item.__medium:hover .post-item__img img {
            transform: scale(1.1); } }
      @media (max-width: 575px) {
        .color-posts-section .post-item.__medium {
          height: 240px; } }
      .color-posts-section .post-item.__medium .post-item__img {
        height: 100%;
        background-position: center; }
        .color-posts-section .post-item.__medium .post-item__img img {
          display: block;
          min-height: 100%; }
      .color-posts-section .post-item.__medium .post-item__title {
        right: 40%;
        max-width: 250px; }
        @media (max-width: 575px) {
          .color-posts-section .post-item.__medium .post-item__title {
            right: 0; } }
    .color-posts-section .post-item__img {
      padding-top: 200px; }
    .color-posts-section .post-item__title {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding-top: 12px;
      padding-bottom: 12px; }

.about strong {
  color: var(--main-color);
  font-weight: 600; }

.about p {
  margin-bottom: 25px; }

.about img {
  height: auto;
  width: auto; }
  @media (max-width: 1023px) {
    .about img {
      width: 100%; } }

.about .flex-tablet-column-reverse {
  margin-bottom: 0 !important; }
  .about .flex-tablet-column-reverse .about-right {
    margin-bottom: var(--blocks-margin-size); }
  .about .flex-tablet-column-reverse .about-left {
    margin-bottom: 0; }

.about-left {
  width: 550px; }
  @media (max-width: 1023px) {
    .about-left {
      width: auto;
      margin-bottom: var(--blocks-margin-size); } }

.about-right {
  position: relative;
  width: 640px; }
  @media (max-width: 1023px) {
    .about-right {
      width: auto; } }
  .about-right > div {
    position: absolute;
    top: 30px;
    max-width: 270px;
    right: 30px;
    font-weight: 700;
    font-size: 16px;
    color: var(--secondary-font-color); }
    @media (max-width: 575px) {
      .about-right > div {
        display: none; } }

.footer {
  background: var(--dark-bg);
  color: var(--white); }
  .footer-logo {
    margin-left: -8px;
    max-width: 250px; }
    @media (max-width: 575px) {
      .footer-logo {
        margin-left: 0; } }
  .footer .social-nets a {
    background: var(--white);
    color: var(--dark-bg); }
    .footer .social-nets a::after {
      background-color: var(--body-color); }
    .footer .social-nets a:hover {
      color: var(--main-color-light); }
  .footer-top {
    padding: 110px 0; }
    @media (max-width: 1023px) {
      .footer-top {
        padding: 75px 0; } }
    @media (max-width: 575px) {
      .footer-top {
        position: relative;
        padding: 50px 0 115px; } }
    .footer-top__left {
      width: 250px; }
      @media (max-width: 1023px) {
        .footer-top__left {
          width: auto;
          display: flex;
          justify-content: space-between; } }
      @media (max-width: 575px) {
        .footer-top__left {
          flex-direction: column;
          align-items: center; } }
  @media (max-width: 1023px) {
    .footer-social-nets {
      padding-top: 10px; } }
  @media (max-width: 575px) {
    .footer-social-nets {
      position: absolute;
      bottom: 50px;
      display: flex;
      left: 0;
      right: 0;
      padding: 0 15px;
      justify-content: center; }
      .footer-social-nets a {
        margin: 0 7px; } }
  .footer-nav {
    padding-left: 200px; }
    @media (max-width: 1023px) {
      .footer-nav {
        padding-left: 0; } }
    .footer-nav ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      .footer-nav ul a {
        color: var(--white); }
    .footer-nav .icon {
      display: none; }
      @media (max-width: 575px) {
        .footer-nav .icon {
          display: inline-block;
          color: var(--owl-nav-btn-color);
          width: 10px;
          transition: transform 0.4s; } }
    @media (max-width: 1023px) {
      .footer-nav > ul {
        justify-content: space-between; } }
    .footer-nav > ul > li {
      width: 25%;
      padding-right: var(--blocks-margin-size); }
      @media (max-width: 1023px) {
        .footer-nav > ul > li {
          width: calc(25% - var(--blocks-margin-size));
          padding-right: 0; } }
      @media (max-width: 575px) {
        .footer-nav > ul > li {
          width: 100%;
          padding-right: 0; }
          .footer-nav > ul > li > a {
            border-bottom: 2px solid #464646;
            transition: #dee2e6 0.4s; }
            .footer-nav > ul > li > a.active {
              border-bottom-color: transparent; }
              .footer-nav > ul > li > a.active .icon {
                transform: rotate(180deg); } }
      .footer-nav > ul > li > a {
        font-weight: 700;
        font-size: 16px;
        display: inline-block;
        margin-bottom: var(--blocks-margin-size); }
        @media (max-width: 575px) {
          .footer-nav > ul > li > a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0;
            margin-bottom: 0;
            text-decoration: none; }
            .footer-nav > ul > li > a:hover {
              text-decoration: none; } }
    @media (max-width: 575px) {
      .footer-nav > ul ul {
        display: none; } }
    .footer-nav > ul ul li {
      margin-bottom: 10px; }
  .footer-bottom {
    background: var(--dark-bg-2);
    padding: 12px 0;
    color: var(--dark-gray-color); }
    .footer-bottom br {
      display: none; }
      @media (max-width: 575px) {
        .footer-bottom br {
          display: block; } }
    .footer-bottom address {
      margin: 0 !important; }
      @media (max-width: 575px) {
        .footer-bottom address {
          display: block !important; } }
    .footer-bottom a {
      color: var(--dark-gray-color);
      transition: color 0.4s; }
      @media (max-width: 575px) {
        .footer-bottom a {
          margin: 0 !important; } }
      .footer-bottom a:hover {
        color: var(--main-color-light); }
  .footer .block-mod {
    color: black; }

.default-modal .modal-content {
  background: var(--body-color);
  border: none;
  border-radius: 0; }

.default-modal .modal-header {
  padding: 0;
  border: none; }

.default-modal .close {
  position: absolute;
  left: calc(100% + 5px);
  bottom: calc(100% + 19px);
  color: var(--white);
  opacity: 1;
  padding: 0;
  height: 14px; }
  @media (max-width: 639px) {
    .default-modal .close {
      left: calc(100% - 20px);
      bottom: calc(100% + 25px); } }
  .default-modal .close .icon-close {
    width: 14px;
    height: 14px;
    vertical-align: top; }

.default-modal.__w484 .modal-dialog {
  max-width: 484px; }

.default-modal.__w650 .modal-dialog {
  max-width: 650px; }

.default-modal .modal-dialog {
  margin: 0 auto;
  padding: 2rem 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.modal-backdrop.show {
  opacity: 0.68; }

.default-tabs {
  border-bottom: none; }
  .default-tabs .nav-link {
    background: var(--dark-bg);
    transition: background-color 0.4s, color 0.3s;
    border-radius: 0 !important;
    border: none !important;
    font-weight: 700;
    font-size: 20px;
    color: var(--white);
    font-style: italic;
    text-align: center;
    padding: 25px 0; }
    .default-tabs .nav-link.active {
      color: var(--default-font-color);
      background: var(--body-color); }

.auth-nets {
  margin-bottom: var(--blocks-margin-size); }
  .auth-nets a {
    width: 75px;
    height: 50px;
    color: var(--white); }
    .auth-nets a.twitter {
      background: #55ace3; }
      .auth-nets a.twitter svg {
        width: 24px;
        height: 19px; }
    .auth-nets a.fb {
      background: #4e6297; }
      .auth-nets a.fb svg {
        width: 21px;
        height: 21px; }
    .auth-nets a.vk {
      background: #7294c7; }
      .auth-nets a.vk svg {
        width: 26px;
        height: 15px; }
    .auth-nets a.google {
      background: #c5331e; }
      .auth-nets a.google svg {
        width: 23px;
        height: 23px; }

.auth-hr {
  overflow: hidden;
  margin-bottom: var(--blocks-margin-size); }
  .auth-hr span::before, .auth-hr span::after {
    content: "";
    height: 2px;
    width: 350px;
    position: absolute;
    top: calc(50% - 1px);
    background: var(--border-color); }
  .auth-hr span::before {
    right: calc(100% + 10px); }
  .auth-hr span::after {
    left: calc(100% + 10px); }

input.form-control,
textarea.form-control {
  border: 1px solid var(--border-color);
  border-radius: 0; }

input.form-control {
  height: 50px; }

.auth-modal .custom-control-label,
.auth-modal .control-label {
  line-height: 1.1; }

.help-block {
  margin-bottom: 0; }

.help-text {
  display: none; }
  @media (max-width: 575px) {
    .help-text {
      display: block;
      font-size: 12px; } }

.to-top {
  position: fixed;
  width: 60px;
  height: 60px;
  background: #787878;
  color: var(--white) !important;
  cursor: pointer;
  bottom: 70px;
  right: 15px;
  border: none;
  border-radius: 50%;
  transition: opacity 0.4s;
  opacity: 0;
  z-index: -1; }
  @media (max-width: 575px) {
    .to-top {
      bottom: 45px; } }
  .to-top.active {
    opacity: 0.6;
    z-index: 19; }
    .to-top.active:hover:not(:active) {
      opacity: 1; }
  .to-top .icon {
    width: 18px; }

@media (max-width: 1023px) {
  .profile-wrap {
    flex-direction: column; } }

.profile-sidebar {
  flex: 0 0 310px;
  margin-right: var(--blocks-margin-size);
  background: var(--dark-bg);
  position: relative;
  padding: 70px 60px 70px 0;
  box-shadow: var(--default-box-shadow);
  border-radius: 2px; }
  @media (max-width: 1023px) {
    .profile-sidebar {
      width: auto;
      padding: 40px;
      margin: 0 calc(var(--container-tablet-padding) - (var(--container-tablet-padding) * 2) + var(--blocks-margin-size) + 4px) 8px;
      font-weight: normal;
      flex: 1; } }
  @media (max-width: 639px) {
    .profile-sidebar {
      margin: 0 -15px 8px;
      padding: 30px 0 10px 0; } }
  .profile-sidebar::before {
    width: 100px;
    content: "";
    top: 0;
    bottom: 0;
    right: 100%;
    position: absolute;
    background: var(--dark-bg); }
    @media (max-width: 1023px) {
      .profile-sidebar::before {
        display: none; } }
  .profile-sidebar ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    @media (max-width: 1023px) {
      .profile-sidebar ul {
        display: flex;
        justify-content: space-between; } }
    @media (max-width: 639px) {
      .profile-sidebar ul {
        flex-wrap: wrap; } }
    .profile-sidebar ul li {
      margin-bottom: var(--blocks-padding-size); }
      @media (max-width: 1023px) {
        .profile-sidebar ul li {
          margin-bottom: 0; } }
      @media (max-width: 639px) {
        .profile-sidebar ul li {
          width: 50%;
          text-align: center;
          margin-bottom: var(--blocks-margin-size); } }
      .profile-sidebar ul li a {
        display: inline-block;
        border-bottom: 2px solid transparent;
        transition: color 0.4s;
        color: var(--white);
        font-size: 20px;
        font-weight: 700;
        font-style: italic;
        text-decoration: none;
        padding-bottom: 5px;
        display: inline-block; }
        .profile-sidebar ul li a:hover {
          text-decoration: none; }
        @media (max-width: 1023px) {
          .profile-sidebar ul li a {
            height: 100%;
            font-size: 16px;
            margin: 0 var(--blocks-margin-size);
            text-align: center;
            font-style: normal;
            line-height: 1.2; } }
        .profile-sidebar ul li a:hover, .profile-sidebar ul li a.active {
          color: var(--red-in-black); }
        .profile-sidebar ul li a.active {
          border-color: var(--red-in-black); }

.profile-content {
  position: relative;
  padding: 70px 0 70px 110px;
  border-radius: 2px;
  max-width: calc(100% - 310px + var(--blocks-margin-size)); }
  @media (max-width: 1279px) {
    .profile-content {
      padding-left: 80px; } }
  @media (max-width: 1023px) {
    .profile-content {
      margin: 0 calc(var(--container-tablet-padding) - (var(--container-tablet-padding) * 2) + var(--blocks-margin-size) + 4px);
      max-width: none;
      background: var(--white);
      box-shadow: var(--default-box-shadow);
      padding-left: 40px;
      padding-right: 40px; } }
  @media (max-width: 767px) {
    .profile-content {
      padding: 50px 40px; } }
  @media (max-width: 639px) {
    .profile-content {
      margin: 0 -15px; } }
  @media (max-width: 575px) {
    .profile-content {
      padding: 50px 15px; } }
  .profile-content > * {
    position: relative;
    z-index: 1; }
  .profile-content::after {
    position: absolute;
    background: var(--white);
    box-shadow: var(--default-box-shadow);
    top: 0;
    left: 0;
    bottom: 0;
    right: -110px;
    content: "";
    z-index: 0; }
    @media (max-width: 1023px) {
      .profile-content::after {
        display: none; } }
  .profile-content .section {
    margin-bottom: 50px; }
    .profile-content .section:last-child {
      margin-bottom: 0; }
      .profile-content .section:last-child .sub-title-1, .profile-content .section:last-child .answer-arguments__details h3, .answer-arguments__details .profile-content .section:last-child h3, .profile-content .section:last-child .answer-post-table h3, .answer-post-table .profile-content .section:last-child h3 {
        margin-bottom: 25px; }

.profile-header {
  margin-bottom: 60px; }
  @media (max-width: 575px) {
    .profile-header {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin-bottom: 30px; } }
  .profile-header__img {
    margin-right: 40px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    width: 130px;
    height: 130px; }
    @media (max-width: 575px) {
      .profile-header__img {
        margin-right: 0;
        margin-bottom: var(--blocks-padding-size); } }
    .profile-header__img a {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      flex-direction: column;
      color: var(--white) !important;
      text-decoration: none;
      font-size: 12px;
      transition: opacity 0.4s;
      opacity: 0;
      border-radius: 50%; }
      .profile-header__img a:hover {
        text-decoration: none; }
      .profile-header__img a:hover {
        opacity: 1; }
      .profile-header__img a .icon {
        width: 18px;
        height: 18px;
        margin-bottom: 5px; }
  .profile-header__header .sub-title-1, .profile-header__header .answer-arguments__details h3, .answer-arguments__details .profile-header__header h3, .profile-header__header .answer-post-table h3, .answer-post-table .profile-header__header h3 {
    margin-bottom: 12px; }
  .profile-header__header .rating {
    margin-bottom: 14px; }
  .profile-header__header a {
    color: var(--gray-color2);
    transition: color 0.3s; }
    .profile-header__header a:hover {
      color: var(--main-color); }

@media (max-width: 639px) {
  .profile-data {
    flex-direction: column; } }

.profile-data h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--main-color);
  margin-bottom: 15px; }

.profile-data__details {
  width: 420px;
  margin-right: var(--blocks-margin-size);
  font-size: 16px; }
  @media (max-width: 1279px) {
    .profile-data__details {
      width: 370px; } }
  @media (max-width: 1023px) {
    .profile-data__details {
      width: 360px; } }
  @media (max-width: 639px) {
    .profile-data__details {
      width: auto;
      margin-right: 0;
      margin-bottom: var(--blocks-margin-size); } }
  .profile-data__details div {
    margin-bottom: 12px; }
    .profile-data__details div span:first-child {
      width: calc(50% - 10px);
      margin-right: var(--blocks-margin-size);
      color: var(--labels-color); }

.profile-data__form {
  max-width: 320px; }
  @media (max-width: 767px) {
    .profile-data__form {
      max-width: none;
      flex: 1; } }
  .profile-data__form .form-group {
    margin-bottom: var(--blocks-padding-size);
    position: relative; }
    .profile-data__form .form-group button {
      position: absolute;
      border: none;
      background: none;
      color: var(--border-color);
      top: calc(50% - 6.5px);
      right: 0;
      padding: 0;
      height: 13px;
      cursor: pointer;
      transition: color 0.4s; }
      .profile-data__form .form-group button:hover, .profile-data__form .form-group button.active {
        color: var(--black); }
      .profile-data__form .form-group button .icon {
        width: 19px;
        height: 13px;
        vertical-align: top; }
  .profile-data__form .form-control {
    height: 26px;
    padding: 5px 0;
    background: var(--white);
    color: #888888;
    transition: border-color 0.4s;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid var(--border-color); }
    .profile-data__form .form-control:focus {
      box-shadow: none !important;
      border-bottom-color: var(--black); }
  .profile-data__form .btn-main {
    padding: 10px 35px; }

.rating {
  color: var(--main-color);
  white-space: nowrap;
  display: flex;
  position: relative; }
  .rating.vote-rating {
    color: var(--border-color); }
    .rating.vote-rating:hover .active-stars {
      z-index: 0; }
    .rating.vote-rating .icon {
      transition: color 0.4s; }
    .rating.vote-rating.__isVoted {
      pointer-events: none; }
    .rating.vote-rating span {
      cursor: pointer; }
      .rating.vote-rating span:hover, .rating.vote-rating span.active, .rating.vote-rating span.hover {
        color: var(--main-color); }
  .rating span {
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 1; }
    .rating span:not(:last-child) {
      margin-right: 4px; }
    .rating span.inner-span {
      margin: 0;
      display: block; }
  .rating .active-stars {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    z-index: 2; }
  .rating .icon {
    width: 24px;
    height: 24px;
    line-height: 24px;
    vertical-align: top; }
    .rating .icon.icon-star-o {
      color: var(--white);
      stroke: var(--main-color); }

.review-modal .modal-header {
  padding-top: 60px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: var(--blocks-margin-size); }

.review-modal .modal-body {
  padding: 0 60px 60px; }
  @media (max-width: 639px) {
    .review-modal .modal-body {
      padding: 0 30px 30px; } }
  @media (max-width: 575px) {
    .review-modal .modal-body {
      padding: 0 15px 15px; } }

.review-modal .close {
  left: auto;
  bottom: auto;
  color: var(--default-font-color);
  top: 30px;
  right: 30px; }

.review-modal .form-group {
  display: flex; }
  @media (max-width: 575px) {
    .review-modal .form-group {
      flex-direction: column; } }
  .review-modal .form-group label {
    width: 140px;
    font-weight: 700;
    color: var(--default-font-color);
    font-size: 16px; }
  .review-modal .form-group > div {
    flex: 1; }
  .review-modal .form-group textarea {
    max-width: 330px;
    padding: var(--blocks-padding-size) var(--blocks-margin-size); }

.owl-theme .owl-dots .owl-dot {
  vertical-align: middle; }
  .owl-theme .owl-dots .owl-dot span {
    width: 9px;
    height: 9px;
    margin: 0 3.5px;
    background: #c7c7c7; }
  .owl-theme .owl-dots .owl-dot.active span {
    background: var(--main-color);
    width: 11px;
    height: 11px; }

@media (max-width: 575px) {
  .owl-theme .owl-dots {
    display: block !important;
    margin-top: 20px; } }

.my-services {
  margin: 0 -10px; }
  .my-services.owl-carousel {
    margin: 0; }
  .my-services .owl-nav,
  .my-services .owl-dots {
    display: none; }
  @media (max-width: 1023px) {
    .my-services .owl-nav {
      position: absolute;
      left: -28px;
      right: -28px;
      margin: 0;
      font-size: 44px;
      line-height: 22px;
      top: calc(50% - 13px);
      display: flex;
      justify-content: space-between; }
      .my-services .owl-nav button {
        padding: 0;
        margin: 0 !important;
        border: none;
        color: var(--owl-nav-btn-color) !important;
        background: none !important; }
        .my-services .owl-nav button span {
          display: block; } }
  .my-services .owl-stage {
    display: flex;
    padding: 5px; }
    .my-services .owl-stage-outer {
      margin: 0 -5px;
      position: relative;
      z-index: 2; }
  .my-services .service-item {
    box-shadow: var(--default-box-shadow);
    transition: box-shadow 0.4s;
    width: 200px;
    margin: 0 10px var(--blocks-margin-size);
    max-width: 100%; }
    .my-services .service-item .btn-main {
      font-size: 15px;
      padding: 16px 30px;
      line-height: 1; }
      @media (max-width: 575px) {
        .my-services .service-item .btn-main {
          width: 100%;
          text-align: center; } }
    .my-services .service-item-cont {
      margin: 0 10px var(--blocks-margin-size); }
      @media (max-width: 575px) {
        .my-services .service-item-cont {
          width: 100%; } }
      .my-services .service-item-cont .service-item {
        margin: 0;
        min-height: calc(100% - 38px); }
        @media (max-width: 575px) {
          .my-services .service-item-cont .service-item {
            width: 100%; } }
    @media (max-width: 575px) {
      .my-services .service-item {
        width: 280px; } }
    .my-services .service-item.__reset-bg {
      background: none;
      box-shadow: none !important;
      margin-bottom: 0 !important; }
    .my-services .service-item:hover {
      box-shadow: var(--default-box-shadow-hover); }
  .my-services .service-img {
    display: block;
    width: 100%;
    height: 105px;
    object-fit: cover;
    object-position: center; }
    .my-services .service-img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .my-services .service-details {
    padding: var(--blocks-padding-size); }
    .my-services .service-details p {
      color: var(--main-color);
      line-height: 22px;
      margin: 0; }
  .my-services .service-title {
    font-weight: 700;
    padding-right: 10px;
    transition: color 0.3s;
    color: var(--default-font-color);
    line-height: 1.3;
    font-size: 15px; }
    .my-services .service-title:hover {
      color: var(--main-color); }
  .my-services .service-price {
    color: var(--main-color);
    font-size: 16px;
    white-space: nowrap; }
  .my-services.owl-carousel .service-item {
    height: 100%;
    margin: 0; }

.breadcrumb {
  margin-bottom: 50px; }
  @media (max-width: 1023px) {
    .breadcrumb {
      margin-bottom: 44px; } }
  @media (max-width: 575px) {
    .breadcrumb {
      margin-bottom: 25px; } }
  .breadcrumb-item.active {
    color: var(--border-color) !important; }
  .breadcrumb-item a {
    font-size: 16px;
    color: var(--default-font-color); }
    @media (max-width: 767px) {
      .breadcrumb-item a {
        font-size: 14px; } }

.breadcrumb-item + .breadcrumb-item::before {
  content: "—";
  color: var(--default-font-color);
  font-size: 16px; }

.inner-content__body {
  flex: 1; }

.inner-content__sidebar {
  flex: 0 0 340px;
  margin-left: 100px; }

.table-of-contents {
  background: #f2f5f9;
  margin-bottom: 30px; }
  .table-of-contents__header {
    padding: 15px 30px;
    font-weight: 700;
    font-size: 1.1em; }
    .table-of-contents__header span {
      cursor: pointer; }
      .table-of-contents__header span::after {
        content: "﹀";
        margin-left: 12px;
        line-height: 1;
        font-size: 14px;
        display: inline-block;
        height: 8px; }
    .table-of-contents__header.active span::after {
      content: "︿"; }
  .table-of-contents ol {
    margin: 0 30px 0;
    padding: 0 0 15px 0;
    list-style: none;
    display: block; }
    .table-of-contents ol li {
      padding-left: 0;
      margin-bottom: 0; }
      .table-of-contents ol li::before {
        display: inline-block;
        content: counter(point) ". ";
        opacity: 0.5;
        margin-right: 5px;
        margin-left: 0; }
      .table-of-contents ol li.level-1 {
        counter-increment: point;
        counter-reset: sublist;
        margin-top: 0.4em;
        margin-bottom: 0.2em; }
        .table-of-contents ol li.level-1::before {
          width: 1.5em; }
      .table-of-contents ol li.level-2 {
        counter-reset: sublist3;
        margin-top: 0.3em;
        margin-bottom: 0.3em;
        margin-left: 30px;
        font-size: 0.92em; }
        .table-of-contents ol li.level-2::before {
          content: counter(point) "." counter(sublist) ". ";
          counter-increment: sublist; }

.article {
  font-size: 16px;
  line-height: 1.7; }
  .article blockquote {
    position: relative;
    margin-bottom: 1.5em; }
    .article blockquote:not(.__accent-block):not(.__check-block) {
      font-style: italic;
      padding: 0.5em 3em; }
      .article blockquote:not(.__accent-block):not(.__check-block)::before {
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        background-image: url(../images/quote.png);
        content: "";
        left: 0;
        top: 0; }
      .article blockquote:not(.__accent-block):not(.__check-block)::after {
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        background-image: url(../images/quote.png);
        background-position: -20px 0;
        content: "";
        right: 0;
        bottom: 0; }
    .article blockquote.__accent-block ul,
    .article blockquote.__accent-block ol, .article blockquote.__check-block ul,
    .article blockquote.__check-block ol {
      list-style: none;
      margin: 25px 0;
      padding: 0; }
      .article blockquote.__accent-block ul li::before,
      .article blockquote.__accent-block ol li::before, .article blockquote.__check-block ul li::before,
      .article blockquote.__check-block ol li::before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 2px solid #4d3bfe;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        margin-right: 1.3em; }
    .article blockquote.__accent-block {
      background: #e3f1f4;
      background: -webkit-gradient(linear, left top, right top, from(#e3f1f4), to(#e3edff));
      background: -webkit-linear-gradient(left, #e3f1f4 0, #e3edff 100%);
      background: -o-linear-gradient(left, #e3f1f4 0, #e3edff 100%);
      background: linear-gradient(to right, #e3f1f4 0, #e3edff 100%);
      padding: 20px 30px 20px 70px; }
      .article blockquote.__accent-block::before {
        content: "ℹ";
        color: #3da2e0;
        border: 3px solid #3da2e0;
        border-radius: 50%;
        position: absolute;
        width: 30px;
        height: 30px;
        top: calc(50% - 15px);
        left: 20px;
        font-size: 24px;
        line-height: 26px;
        text-align: center; }
    .article blockquote.__check-block {
      background: #def9e5;
      background: -webkit-gradient(linear, left top, right top, from(#def9e5), to(#effbce));
      background: -webkit-linear-gradient(left, #def9e5 0, #effbce 100%);
      background: -o-linear-gradient(left, #def9e5 0, #effbce 100%);
      background: linear-gradient(to right, #def9e5 0, #effbce 100%);
      padding: 20px 20px 20px 65px !important; }
      .article blockquote.__check-block::before {
        background: url(../images/ok.png) no-repeat center center;
        width: 30px;
        height: 30px;
        top: calc(50% - 15px);
        left: 20px;
        content: "";
        position: absolute; }
  .article h1,
  .article h2,
  .article h3,
  .article h4,
  .article h5,
  .article h6 {
    font-weight: 700;
    font-style: normal; }
  .article h1 {
    font-weight: 700;
    font-size: 2.43em;
    margin-bottom: 0.8em; }
    @media (max-width: 767px) {
      .article h1 {
        font-size: 1.625em; } }
  .article img {
    height: auto; }
  .article strong,
  .article b {
    color: var(--main-color);
    font-weight: 700; }
  .article span.words {
    display: inline-block; }
  .article p {
    margin: 0 0 25px; }
    @media (max-width: 575px) {
      .article p {
        margin: 0 0 20px; } }
  .article .ad-block {
    margin: 60px 0; }
    @media (max-width: 575px) {
      .article .ad-block {
        margin: 45px 0; } }
  .article h2 {
    font-size: 1.9375em;
    margin-bottom: 0.77419355em; }
  .article h3 {
    font-size: 1.5375em;
    margin: 0 0 25px; }
  .article h4 {
    font-size: 1.2375em;
    margin: 0 0 25px; }
  .article-header {
    position: relative;
    margin-bottom: 30px; }
    .article-header .__cat {
      display: inline-block;
      padding: 0.3em 1.4em;
      margin-bottom: 1.2em;
      background: var(--main-color);
      color: #fff;
      -webkit-border-radius: 1em;
      border-radius: 1em;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 0.7em;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      position: absolute;
      bottom: 15px;
      left: 15px;
      max-width: calc(100% - 30px); }
    .article-header img {
      width: 100%;
      display: block;
      box-shadow: var(--default-box-shadow); }
    .article-header h2 {
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 80%;
      background: var(--white);
      box-shadow: var(--default-box-shadow);
      font-size: 25px;
      font-weight: 700;
      margin: 0;
      font-style: italic;
      padding: 23px 48px; }
      @media (max-width: 575px) {
        .article-header h2 {
          font-size: 17px;
          padding: 18px 23px;
          position: relative;
          width: 100%;
          max-width: none; } }
  .article-footer {
    margin: 40px 0; }
    @media (max-width: 575px) {
      .article-footer {
        flex-direction: column;
        align-items: flex-start !important; } }
    @media (max-width: 575px) {
      .article-footer .rating {
        margin-bottom: 30px; } }
    .article-footer h5 {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 8px; }
    .article-footer .social-nets a {
      background: rgba(0, 0, 0, 0.04);
      width: calc(100% / 7);
      height: 45px;
      border-radius: 0;
      margin: 0 !important;
      color: var(--main-color);
      transition: all 0.3s; }
      .article-footer .social-nets a::after {
        display: none; }
      .article-footer .social-nets a:hover {
        color: var(--white);
        background: var(--main-color); }
      .article-footer .social-nets a .icon {
        font-size: 1.2em !important;
        max-width: none; }
  .article-meta {
    border-bottom: 3px solid #f2f2f2;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 30px;
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1.4; }
    .article-meta > * {
      margin-right: 20px;
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px; }
      @media (max-width: 767px) {
        .article-meta > * {
          max-width: calc(50% - 20px); } }
      .article-meta > * i.fa {
        margin-right: 15px;
        font-size: 2em;
        color: var(--main-color); }
      .article-meta > * span {
        font-weight: 400;
        color: #666;
        display: block;
        font-size: 0.8em;
        line-height: 1;
        text-transform: uppercase; }

.post-img-table {
  border: none;
  margin: 25px 0 !important;
  display: block; }
  .post-img-table.__margin-reset td {
    margin-bottom: 0 !important;
    padding-top: 0 !important; }
  .post-img-table tbody {
    display: block;
    width: 100%; }
  .post-img-table tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 575px) {
      .post-img-table tr {
        flex-direction: column; } }
    .post-img-table tr:first-child td:first-child {
      margin-bottom: -140px; }
      @media (max-width: 1023px) {
        .post-img-table tr:first-child td:first-child {
          margin-bottom: 0; } }
    .post-img-table tr:nth-child(2) td:first-child {
      padding-top: 140px; }
      @media (max-width: 1023px) {
        .post-img-table tr:nth-child(2) td:first-child {
          padding-top: 0; } }
  .post-img-table td {
    width: calc(50% - 7.5px) !important; }
    @media (max-width: 575px) {
      .post-img-table td {
        width: 100% !important;
        padding: 15px 0 !important; } }
  .post-img-table img {
    display: block;
    width: 100%;
    height: auto; }

.post-imgs-list-table {
  display: block;
  margin: 25px 0; }
  @media (max-width: 575px) {
    .post-imgs-list-table {
      display: none; } }
  .post-imgs-list-table tbody {
    display: block;
    width: 100%; }
  .post-imgs-list-table tr {
    display: flex;
    justify-content: space-between; }
  .post-imgs-list-table td {
    width: calc(33% - 12.5px) !important; }
  .post-imgs-list-table img {
    display: block;
    width: 100%;
    height: auto; }

figure.image {
  display: flex;
  align-items: center;
  margin: 25px 0; }
  @media (max-width: 1023px) {
    figure.image {
      align-items: flex-start; } }
  @media (max-width: 575px) {
    figure.image {
      flex-direction: column; } }
  figure.image p:first-child {
    margin-top: 0; }
  figure.image p:last-child {
    margin-bottom: 0; }
  figure.image img.mr-2 {
    margin-right: var(--blocks-margin-size) !important; }
  figure.image img.ml-2 {
    margin-left: var(--blocks-margin-size) !important; }
  @media (max-width: 575px) {
    figure.image img.mr-2, figure.image img.ml-2 {
      margin: 0 auto var(--blocks-margin-size) !important; } }

.inner-posts {
  justify-content: space-between;
  margin-bottom: 40px; }
  @media (max-width: 575px) {
    .inner-posts {
      margin-bottom: 30px; } }
  @media (max-width: 575px) {
    .inner-posts {
      flex-direction: column; } }
  .inner-posts .post-item {
    width: calc(50% - 10px); }
    @media (max-width: 575px) {
      .inner-posts .post-item {
        width: 100%;
        margin-bottom: var(--blocks-margin-size); }
        .inner-posts .post-item:last-child {
          margin-bottom: 0; } }
    .inner-posts .post-item__img {
      padding-top: 210px; }
      @media (max-width: 575px) {
        .inner-posts .post-item__img {
          padding-top: 160px; } }

.has-bg {
  position: relative; }
  .has-bg > * {
    position: relative;
    z-index: 1; }

.blog-content-bg {
  position: absolute;
  right: calc(var(--sidebar-width));
  top: 0;
  z-index: 0; }
  @media (max-width: 1023px) {
    .blog-content-bg {
      width: 208px;
      right: -30px; } }

.answer {
  font-size: 16px; }
  .answer-header {
    position: relative;
    box-shadow: var(--default-box-shadow); }
    .answer-header__details {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 28px 48px;
      background: var(--white); }
      @media (max-width: 1023px) {
        .answer-header__details {
          position: relative;
          padding: 25px 40px; } }
      @media (max-width: 575px) {
        .answer-header__details {
          padding: 23px 36px;
          flex-direction: column;
          text-align: center; }
          .answer-header__details .btn-main {
            margin-top: var(--blocks-margin-size); } }
      .answer-header__details p {
        color: var(--main-color); }
  @media (max-width: 1023px) {
    .answer-arguments {
      align-items: flex-start !important;
      margin-bottom: 40px; } }
  @media (max-width: 575px) {
    .answer-arguments {
      flex-direction: column; } }
  .answer-arguments__img {
    flex: 0 0 310px;
    display: block;
    margin-right: var(--blocks-margin-size);
    position: relative;
    padding-top: 280px; }
    @media (max-width: 1023px) {
      .answer-arguments__img {
        flex: 0 0 200px;
        padding-top: 226px; } }
    @media (max-width: 575px) {
      .answer-arguments__img {
        width: 100%;
        margin-bottom: 30px; } }
    .answer-arguments__img img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .answer-arguments__details h3 {
    margin-bottom: var(--blocks-margin-size); }
    @media (max-width: 575px) {
      .answer-arguments__details h3 {
        text-align: center; } }
  @media (max-width: 575px) {
    .answer-arguments__details-footer {
      flex-direction: column-reverse;
      align-items: flex-start !important; } }
  .answer-arguments__details .answer-price {
    margin-left: 30px; }
    @media (max-width: 575px) {
      .answer-arguments__details .answer-price {
        margin-left: 0;
        margin-bottom: var(--blocks-margin-size); } }
  .answer-price {
    color: var(--main-color);
    font-weight: 700;
    font-size: 20px;
    font-style: italic; }
  .answer-post-table ul {
    margin-bottom: 0; }
  @media (max-width: 1023px) {
    .answer-post-table {
      position: relative;
      padding-top: 40px; }
      .answer-post-table tr {
        align-items: flex-start !important; }
      .answer-post-table h3 {
        position: absolute;
        left: 0;
        right: 0;
        top: 0; } }
  @media (max-width: 575px) {
    .answer-post-table {
      padding-top: 0; }
      .answer-post-table h3 {
        position: relative; }
      .answer-post-table .list-1, .answer-post-table .answer-arguments__details ul, .answer-arguments__details .answer-post-table ul, .answer-post-table ul {
        margin-bottom: var(--blocks-margin-size) !important; } }

.list-1, .answer-arguments__details ul, .answer-post-table ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin: var(--blocks-margin-size) 0; }
  .list-1 li, .answer-arguments__details ul li, .answer-post-table ul li {
    position: relative;
    padding-left: 25px;
    line-height: 1.4; }
    .list-1 li:not(:last-child), .answer-arguments__details ul li:not(:last-child), .answer-post-table ul li:not(:last-child) {
      margin-bottom: 8px; }
    .list-1 li::before, .answer-arguments__details ul li::before, .answer-post-table ul li::before {
      content: "";
      position: absolute;
      left: 0;
      top: 5px; }

.note {
  line-height: 1.6;
  margin: 40px 0; }
  @media (max-width: 575px) {
    .note {
      align-items: flex-start !important; } }
  .note .icon-support {
    width: 75px;
    height: 75px;
    flex: 0 0 75px;
    margin-right: var(--blocks-margin-size);
    color: var(--main-color); }

.default-card {
  font-size: 16px;
  margin: 0 -48px 0;
  padding: 34px 48px; }
  @media (max-width: 1023px) {
    .default-card {
      margin: 0 calc(var(--container-tablet-padding) - ((var(--container-tablet-padding) * 2)) + 15px); } }
  @media (max-width: 575px) {
    .default-card {
      margin: 0 calc(var(--blocks-margin-size) - (var(--blocks-margin-size) * 2) + 8px);
      padding: 25px 15px; }
      .default-card.d-flex {
        flex-direction: column; } }

.result {
  line-height: 1.6; }
  .result blockquote {
    display: block;
    position: relative;
    padding-left: 76px;
    margin: 25px 0; }
    @media (max-width: 575px) {
      .result blockquote {
        padding-left: 55px; } }
    .result blockquote::before {
      content: "";
      position: absolute;
      top: 6px;
      left: 30px; }
      @media (max-width: 575px) {
        .result blockquote::before {
          left: 12px; } }
  .result-header {
    margin-bottom: 30px; }
    .result-header div {
      color: var(--main-color); }
      .result-header div .title-1 {
        margin: 0; }
  .result-card p {
    margin-bottom: 12px; }
  .result-card h2,
  .result-card h3,
  .result-card h4,
  .result-card h5 {
    margin-bottom: 12px; }
  .result-card.color-result-card .result-tab-nav {
    margin-right: 360px; }
    @media (max-width: 1023px) {
      .result-card.color-result-card .result-tab-nav {
        justify-content: space-between; }
        .result-card.color-result-card .result-tab-nav .nav-link {
          margin-right: 0;
          width: calc(50% - var(--blocks-margin-size)); } }
    @media (max-width: 639px) {
      .result-card.color-result-card .result-tab-nav {
        margin-right: 0; }
        .result-card.color-result-card .result-tab-nav .nav-link {
          width: auto;
          margin-right: 30px; } }
    @media (max-width: 575px) {
      .result-card.color-result-card .result-tab-nav .nav-link {
        margin-right: 0;
        width: calc(50% - var(--blocks-margin-size)); } }
  .result-card.color-result-card .result-img {
    margin-right: 0;
    float: right;
    margin-left: var(--blocks-margin-size);
    margin-top: -60px;
    width: 310px;
    height: 320px;
    border: none;
    padding: 0;
    margin-bottom: 80px; }
    @media (max-width: 1023px) {
      .result-card.color-result-card .result-img {
        margin-top: -88px; } }
    @media (max-width: 639px) {
      .result-card.color-result-card .result-img {
        margin-top: 0; } }
    @media (max-width: 575px) {
      .result-card.color-result-card .result-img {
        width: 100%;
        margin: 0 0 var(--blocks-margin-size);
        float: none; }
        .result-card.color-result-card .result-img.has-color-choise {
          margin-bottom: 70px; } }
  .result-card.color-result-card .tab-pane {
    position: relative; }
    .result-card.color-result-card .tab-pane .hair-colors-table {
      position: absolute;
      right: 0;
      top: -42px; }
      @media (max-width: 1023px) {
        .result-card.color-result-card .tab-pane .hair-colors-table {
          top: -70px; } }
      @media (max-width: 639px) {
        .result-card.color-result-card .tab-pane .hair-colors-table {
          top: 18px; } }
      @media (max-width: 575px) {
        .result-card.color-result-card .tab-pane .hair-colors-table {
          width: 100%; } }
  .result-card.default-box-shadow {
    background: var(--white); }
    .result-card.default-box-shadow .result-tab {
      max-width: calc(100% - (310px + 20px)); }
      @media (max-width: 1023px) {
        .result-card.default-box-shadow .result-tab {
          max-width: calc(100% - 200px); } }
      @media (max-width: 575px) {
        .result-card.default-box-shadow .result-tab {
          max-width: 100%; } }
  .result-img {
    flex: 0 0 310px;
    height: 310px;
    border: 15px solid transparent;
    position: relative;
    margin-right: 40px;
    margin-left: -15px;
    transition: border-color 0.4s;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
    @media (max-width: 1023px) {
      .result-img {
        flex: 0 0 200px;
        height: 200px;
        border-width: 12px;
        margin-right: 8px;
        margin-left: -11px; } }
    @media (max-width: 575px) {
      .result-img {
        flex: auto;
        width: 100%;
        min-height: 248px;
        padding-top: 70%;
        margin-right: 0;
        border-width: 15px;
        margin-left: 0; } }
  .result-tab {
    max-width: 100%; }
    .result-tab-nav {
      padding-top: 10px;
      border-bottom: none;
      justify-content: space-between;
      margin-bottom: 20px; }
      @media (max-width: 1023px) {
        .result-tab-nav {
          justify-content: flex-start; } }
      .result-tab-nav .nav-link {
        font-size: 17px;
        font-weight: 700;
        color: var(--default-font-color);
        transition: border-color 0.4s;
        padding: 3px 0;
        margin-right: 32px;
        font-style: italic;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 2px solid transparent; }
        .result-tab-nav .nav-link:last-child {
          margin-right: 0; }
        .result-tab-nav .nav-link:hover, .result-tab-nav .nav-link.active {
          border-color: var(--main-color);
          color: var(--main-color); }

.hair-colors-table {
  width: 310px;
  position: relative;
  display: block;
  overflow: hidden;
  text-indent: -9999px;
  font-size: 0; }
  .hair-colors-table > tbody {
    display: block; }
    .hair-colors-table > tbody > tr {
      display: block; }
      .hair-colors-table > tbody > tr > td {
        width: 100%;
        display: block; }
      .hair-colors-table > tbody > tr:first-child {
        padding-bottom: 35px; }
        .hair-colors-table > tbody > tr:first-child td {
          width: 86px;
          height: 285px;
          margin-left: var(--blocks-margin-size);
          transition: background-color 0.4s; }
      .hair-colors-table > tbody > tr table {
        display: block; }
        .hair-colors-table > tbody > tr table tbody {
          display: block; }
        .hair-colors-table > tbody > tr table tr {
          display: flex;
          justify-content: space-between; }
          .hair-colors-table > tbody > tr table tr td {
            display: block;
            width: 35px;
            height: 35px;
            transition: transform 0.4s;
            cursor: pointer; }
            .hair-colors-table > tbody > tr table tr td:hover {
              transform: scale(1.15); }

.result-card-carousel .owl-stage-outer {
  position: relative;
  z-index: 2; }

.result-card-carousel .owl-item {
  height: 1px;
  overflow: hidden; }
  .result-card-carousel .owl-item.active {
    height: auto;
    overflow: inherit; }

.result-card-carousel .owl-nav {
  position: absolute;
  left: -28px;
  right: -28px;
  margin: 0;
  font-size: 44px;
  line-height: 22px;
  top: calc(50% - 22px);
  display: flex;
  justify-content: space-between; }
  @media (max-width: 575px) {
    .result-card-carousel .owl-nav {
      display: none; } }
  .result-card-carousel .owl-nav button {
    padding: 0;
    margin: 0 !important;
    border: none;
    color: var(--owl-nav-btn-color) !important;
    background: none !important; }
    .result-card-carousel .owl-nav button span {
      display: block; }

.img-grid-table {
  margin: 25px 0; }
  @media (max-width: 575px) {
    .img-grid-table tr {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start; } }
  .img-grid-table td {
    padding-right: var(--blocks-margin-size); }
    @media (max-width: 575px) {
      .img-grid-table td {
        display: block;
        width: calc(50% - 10px);
        padding-right: 0;
        margin-bottom: var(--blocks-margin-size); } }
    .img-grid-table td img {
      box-shadow: var(--default-box-shadow);
      transition: transform 0.4s;
      cursor: pointer; }
      .img-grid-table td img:hover {
        transform: scale(1.15); }
      @media (max-width: 575px) {
        .img-grid-table td img {
          width: 100%; } }
    .img-grid-table td:last-child {
      padding-right: 0; }

.color-table {
  display: block;
  margin: 25px 0 7px; }
  .color-table tbody {
    display: block; }
  .color-table tr {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -11px 0 -9px; }
    @media (max-width: 575px) {
      .color-table tr {
        margin-left: -5px;
        margin-right: -5px; } }
  .color-table td {
    display: block;
    height: 50px;
    width: 50px;
    margin: 0 9px 18px;
    transition: transform 0.4s;
    cursor: pointer; }
    .color-table td:hover {
      transform: scale(1.15); }
    @media (max-width: 1023px) {
      .color-table td {
        width: calc(25% - 18px); } }
    @media (max-width: 575px) {
      .color-table td {
        width: calc(25% - 10px);
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 10px; } }

.slider-table {
  border: none;
  width: 100%; }
  .slider-table > tbody > tr > td {
    padding: 0; }

.wig-list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 25px 0;
  display: block; }
  .wig-list .owl-stage-outer {
    z-index: 2; }
  .wig-list .owl-dots {
    margin-top: 15px; }
  .wig-list .owl-nav {
    position: absolute;
    left: -15px;
    right: -15px;
    margin: 0;
    font-size: 44px;
    line-height: 22px;
    top: calc(50% - 13px);
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1023px) {
      .wig-list .owl-nav {
        margin-left: -30px;
        margin-right: -30px; } }
    .wig-list .owl-nav button {
      padding: 0;
      margin: 0 !important;
      border: none;
      color: var(--owl-nav-btn-color) !important;
      background: none !important; }
      .wig-list .owl-nav button span {
        display: block; }

.feedback-block {
  padding: 55px;
  box-shadow: var(--default-box-shadow);
  clear: both; }
  @media (max-width: 575px) {
    .feedback-block > div.d-flex {
      margin-bottom: var(--blocks-margin-size); } }
  @media (max-width: 639px) {
    .feedback-block {
      padding: 40px; } }
  @media (max-width: 575px) {
    .feedback-block {
      padding: 38px 24px;
      margin-bottom: 40px; } }
  .feedback-block .icon-support {
    color: var(--main-color);
    width: 50px;
    height: 50px;
    min-width: 50px; }
  .feedback-block p {
    margin: 0 50px 0 30px;
    font-style: italic;
    font-weight: 700;
    font-size: 17px; }
    @media (max-width: 575px) {
      .feedback-block p {
        margin-right: 0;
        margin-left: var(--blocks-padding-size); } }
  .feedback-block .btn-main {
    padding-top: 15.5px;
    padding-bottom: 15.5px; }

.makeup-table {
  width: 100%;
  display: block; }
  .makeup-table > tbody {
    display: block; }
    .makeup-table > tbody > tr {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start; }
      .makeup-table > tbody > tr:not(:last-child) {
        margin-bottom: 50px; }
        @media (max-width: 575px) {
          .makeup-table > tbody > tr:not(:last-child) {
            margin-bottom: var(--blocks-margin-size); } }
      .makeup-table > tbody > tr > td {
        width: 200px;
        display: block;
        margin-right: 50px; }
        @media (max-width: 639px) {
          .makeup-table > tbody > tr > td {
            margin-bottom: 20px;
            margin-right: 0; }
            .makeup-table > tbody > tr > td:last-child {
              margin-bottom: 0; } }
        @media (max-width: 575px) {
          .makeup-table > tbody > tr > td {
            width: 100%; } }
        .makeup-table > tbody > tr > td > * {
          margin: 0; }
        .makeup-table > tbody > tr > td table {
          width: 100%;
          margin-top: 10px !important;
          flex-wrap: wrap; }
          .makeup-table > tbody > tr > td table tr {
            display: flex;
            justify-content: space-between; }
            .makeup-table > tbody > tr > td table tr td {
              display: block;
              width: 50px;
              height: 50px;
              transition: transform 0.4s;
              cursor: pointer; }
              .makeup-table > tbody > tr > td table tr td:hover {
                transform: scale(1.15); }
              @media (max-width: 575px) {
                .makeup-table > tbody > tr > td table tr td {
                  width: calc(33.33% - 10px); } }

.blog-categories-list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
  line-height: 1.4; }
  @media (max-width: 575px) {
    .blog-categories-list {
      margin: 0; } }
  .blog-categories-list > li {
    width: calc(25% - var(--blocks-margin-size));
    margin: 0 10px var(--blocks-margin-size); }
    @media (max-width: 1023px) {
      .blog-categories-list > li {
        width: calc(33% - var(--blocks-margin-size)); } }
    @media (max-width: 575px) {
      .blog-categories-list > li {
        width: 100%;
        margin: 0 0 var(--blocks-padding-size); } }
    .blog-categories-list > li > a {
      font-weight: 700;
      font-style: italic;
      font-size: 17px;
      text-decoration: none;
      display: inline-block;
      position: relative;
      padding-right: var(--blocks-margin-size);
      color: var(--main-color); }
      .blog-categories-list > li > a:hover {
        text-decoration: none; }
      @media (max-width: 575px) {
        .blog-categories-list > li > a {
          display: block;
          position: relative;
          padding-right: 15px; }
          .blog-categories-list > li > a br {
            display: none; } }
      .blog-categories-list > li > a .icon {
        width: 10px;
        height: 6px;
        position: absolute;
        right: 0;
        top: 10px;
        transition: transform 0.4s; }
        @media (max-width: 575px) {
          .blog-categories-list > li > a .icon {
            position: absolute;
            right: 0;
            top: 10px; } }
      .blog-categories-list > li > a.active .icon {
        transform: rotate(180deg); }
    .blog-categories-list > li ul {
      margin: 0;
      padding: 0;
      list-style: none;
      padding-top: var(--blocks-margin-size);
      display: none; }
      .blog-categories-list > li ul li {
        margin-bottom: 10px; }
        .blog-categories-list > li ul li a {
          transition: color 0.3s;
          color: var(--default-font-color); }
          .blog-categories-list > li ul li a:hover {
            color: var(--main-color); }
  @media (max-width: 1023px) {
    .blog-categories-list__toggle {
      margin-bottom: var(--blocks-margin-size); } }
  @media (max-width: 575px) {
    .blog-categories-list__toggle {
      margin-bottom: 50px; } }
  @media (max-width: 575px) {
    .blog-categories-list__toggle a {
      width: 100%;
      text-align: center; } }
  .blog-categories-list__toggle a span:last-child {
    display: none; }
  .blog-categories-list__toggle a span:first-child {
    display: inline; }
  .blog-categories-list__toggle a.active span:first-child {
    display: none; }
  .blog-categories-list__toggle a.active span:last-child {
    display: inline; }

.simple-post-list {
  margin: 0 -10px; }
  .simple-post-list.flex-wrap .article-item {
    margin-bottom: var(--blocks-margin-size); }
  @media (max-width: 575px) {
    .simple-post-list {
      flex-direction: column;
      margin: 0; } }
  .simple-post-list .article-item {
    width: calc(33.33% - 20px);
    margin: 0 10px; }
    @media (max-width: 575px) {
      .simple-post-list .article-item {
        width: 100%;
        margin: 0 0 var(--blocks-margin-size); } }
    .simple-post-list .article-item__img {
      width: 100%;
      max-width: none;
      height: 200px;
      border-radius: 0 !important;
      margin-bottom: var(--blocks-margin-size); }
    .simple-post-list .article-item__details {
      width: auto;
      margin: 0 !important; }

.posts-grid {
  margin: 0 -10px; }
  @media (max-width: 575px) {
    .posts-grid {
      margin: 0; } }
  .posts-grid .post-item {
    width: calc(25% - 20px);
    margin: 0 10px; }
    @media (max-width: 575px) {
      .posts-grid .post-item {
        width: 100%;
        margin: 0 0 var(--blocks-margin-size); } }
    .posts-grid .post-item__img {
      padding-top: 160px; }
    .posts-grid .post-item__title {
      position: relative;
      padding: var(--blocks-padding-size) 0;
      font-style: italic;
      font-size: 17px;
      line-height: 20px; }

.pagination {
  margin: 50px 0; }
  .pagination a {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--main-color);
    color: var(--white); }
    .pagination a.disabled {
      background-color: var(--border-color);
      pointer-events: none; }
    .pagination a .icon {
      height: 7px;
      width: 11px; }
  .pagination-prev .icon {
    transform: rotate(90deg); }
  .pagination-next .icon {
    transform: rotate(-90deg); }
  .pagination-body {
    margin: 0 var(--blocks-margin-size);
    font-size: 20px; }
    .pagination-body span {
      color: #aeaeae; }

.transformation-steps {
  margin: 45px 0;
  font-size: 14px;
  line-height: 1.6; }
  @media (max-width: 1023px) {
    .transformation-steps {
      flex-direction: column; } }
  .transformation-steps__img {
    flex: 0 0 310px;
    height: 398px;
    position: relative;
    margin-right: var(--blocks-padding-size);
    overflow: hidden; }
    @media (max-width: 1023px) {
      .transformation-steps__img {
        flex: auto;
        width: 100%;
        margin-bottom: 40px;
        height: 287px;
        margin-right: 0; } }
    @media (max-width: 575px) {
      .transformation-steps__img {
        margin-bottom: 30px; } }
    @media (max-width: 575px) {
      .transformation-steps__img {
        height: 260px; } }
    .transformation-steps__img img {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: top;
      display: none; }
      .transformation-steps__img img:nth-child(1) {
        display: block; }
        @media (max-width: 1023px) {
          .transformation-steps__img img:nth-child(1) {
            display: none; } }
      @media (max-width: 1023px) {
        .transformation-steps__img img:nth-child(2) {
          display: block; } }
      @media (max-width: 575px) {
        .transformation-steps__img img:nth-child(2) {
          display: none; } }
      @media (max-width: 575px) {
        .transformation-steps__img img:nth-child(3) {
          display: block; } }
  .transformation-steps__list {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: list; }
    @media (max-width: 575px) {
      .transformation-steps__list {
        flex-direction: column; } }
    .transformation-steps__list li {
      width: calc(50% - 10px);
      padding: 44px 0 0;
      position: relative; }
      @media (max-width: 575px) {
        .transformation-steps__list li {
          width: 100%; } }
      .transformation-steps__list li > * {
        position: relative;
        z-index: 1; }
      .transformation-steps__list li::before {
        counter-increment: list;
        content: "0" counter(list);
        position: absolute;
        top: 10px;
        left: 0;
        font-size: 90px;
        line-height: 1;
        color: var(--main-color);
        font-style: italic;
        font-weight: 700;
        opacity: 0.2; }
  .transformation-steps__title {
    font-size: 20px;
    color: var(--main-color);
    font-style: italic;
    margin-bottom: 30px;
    line-height: 23px;
    font-weight: 700;
    padding-left: 55px; }

.tariffs {
  margin: 0 -10px; }
  .tariffs .sub-title-1, .tariffs .answer-arguments__details h3, .answer-arguments__details .tariffs h3, .tariffs .answer-post-table h3, .answer-post-table .tariffs h3 {
    margin-bottom: var(--blocks-margin-size); }
  @media (max-width: 1023px) {
    .tariffs {
      text-align: center;
      margin: 0 0 55px; }
      .tariffs-list {
        text-align: left;
        flex-direction: column;
        justify-content: center !important; } }
  @media (max-width: 575px) {
    .tariffs p br {
      display: none; } }
  .tariffs .tariff {
    width: calc(33.33% - 10px);
    margin: 0 10px;
    max-width: 100%; }
    @media (max-width: 1023px) {
      .tariffs .tariff {
        width: 420px;
        margin-bottom: 25px; }
        .tariffs .tariff:last-child {
          margin-bottom: 0; } }
    .tariffs .tariff.active {
      width: calc(33.33% + 34px);
      box-shadow: var(--default-box-shadow-hover); }
      @media (max-width: 1023px) {
        .tariffs .tariff.active {
          width: 460px;
          margin-bottom: 40px; } }
      .tariffs .tariff.active img {
        box-shadow: none; }
      .tariffs .tariff.active .tariff-details {
        padding: 26px 30px;
        background: var(--white); }
        @media (max-width: 575px) {
          .tariffs .tariff.active .tariff-details {
            padding: 20px 13px; } }

.tariff {
  font-size: 14px;
  line-height: 1.4; }
  .tariff p {
    margin-bottom: 8px; }
  .tariff-img {
    position: relative;
    height: 200px;
    box-shadow: var(--default-box-shadow); }
    .tariff-img img {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      object-position: center; }
  .tariff-details {
    padding: var(--blocks-padding-size) 5px; }
    @media (max-width: 1023px) {
      .tariff-details {
        padding-left: 0;
        padding-right: 0; } }
    .tariff-details__header {
      font-weight: 700;
      font-size: 17px;
      margin-bottom: 10px;
      line-height: 20px;
      font-style: italic; }
  .tariff-price {
    color: var(--main-color); }
  .tariff .btn-main {
    padding: 15.5px 20px;
    border-width: 1px; }

@media (max-width: 767px) {
  .wardrobe {
    flex-direction: column; } }

.wardrobe-body {
  width: 420px;
  max-width: 100%; }
  @media (max-width: 767px) {
    .wardrobe-body {
      margin: 0 auto 35px; } }
  .wardrobe-body__inner {
    height: 700px;
    border-radius: 4px;
    box-shadow: var(--default-box-shadow-hover);
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 95%;
    background-color: var(--white); }
    @media (max-width: 575px) {
      .wardrobe-body__inner {
        height: 466px; } }
    .wardrobe-body__inner .placeholder {
      padding: var(--blocks-padding-size);
      display: inline-block;
      background: rgba(255, 255, 255, 0.5); }
    .wardrobe-body__inner.wedding-wardrobe, .wardrobe-body__inner.women-wardrobe {
      background-image: url(../images/girl.jpg); }
    .wardrobe-body__inner.men-wardrobe {
      background-image: url(../images/boy.jpg); }
    .wardrobe-body__inner.photo-uploaded {
      background-image: none !important; }
      .wardrobe-body__inner.photo-uploaded .wardrobe-main-img {
        display: block; }
      .wardrobe-body__inner.photo-uploaded .wardrobe-upload {
        display: none !important; }
    .wardrobe-body__inner.dress-uploaded .dress-img {
      display: block;
      z-index: 4; }
    .wardrobe-body__inner.veil-uploaded .veil-img {
      display: block;
      z-index: 5; }
    .wardrobe-body__inner.bouquet-uploaded .bouquet-img {
      display: block; }

.wardrobe-sidebar {
  flex: 1;
  max-width: calc(100% - 420px - 40px);
  margin-left: calc(var(--blocks-margin-size) * 2);
  padding-top: 10px; }
  @media (max-width: 1023px) {
    .wardrobe-sidebar {
      margin-left: var(--blocks-margin-size);
      max-width: calc(100% - 420px - var(--blocks-margin-size)); } }
  @media (max-width: 767px) {
    .wardrobe-sidebar {
      flex: 1;
      margin: 0;
      width: 100%;
      text-align: center;
      max-width: none; } }
  .wardrobe-sidebar .sub-title-1, .wardrobe-sidebar .answer-arguments__details h3, .answer-arguments__details .wardrobe-sidebar h3, .wardrobe-sidebar .answer-post-table h3, .answer-post-table .wardrobe-sidebar h3 {
    color: var(--main-color); }
  .wardrobe-sidebar .default-tabs {
    margin-bottom: var(--blocks-padding-size);
    flex-wrap: nowrap;
    margin-bottom: 25px; }
    @media (max-width: 767px) {
      .wardrobe-sidebar .default-tabs {
        justify-content: space-between; } }
    .wardrobe-sidebar .default-tabs .nav-link {
      background: none;
      color: var(--default-font-color);
      position: relative;
      padding: 0 0 0 25px;
      line-height: 1.2;
      font-size: 16px;
      font-style: normal; }
      @media (max-width: 1023px) {
        .wardrobe-sidebar .default-tabs .nav-link {
          font-weight: normal;
          font-size: 16px; } }
      .wardrobe-sidebar .default-tabs .nav-link span {
        height: 16px;
        width: 16px;
        border: 1px solid var(--default-font-color);
        display: block;
        position: absolute;
        top: 2px;
        left: 0;
        border-radius: 50%;
        transition: border-color 0.4s; }
        .wardrobe-sidebar .default-tabs .nav-link span::after {
          content: "";
          height: 10px;
          width: 10px;
          position: absolute;
          left: calc(50% - 5px);
          top: calc(50% - 5px);
          background: var(--main-color);
          border-radius: 50%;
          opacity: 0;
          transition: opacity 0.4s, transform 0.4s;
          transform: scale(0.2); }
      .wardrobe-sidebar .default-tabs .nav-link.active {
        background: none;
        color: var(--main-color); }
        .wardrobe-sidebar .default-tabs .nav-link.active span {
          border-color: var(--main-color); }
          .wardrobe-sidebar .default-tabs .nav-link.active span::after {
            transform: scale(1);
            opacity: 1; }
      .wardrobe-sidebar .default-tabs .nav-link:first-child {
        margin-right: 50px; }
        @media (max-width: 1023px) {
          .wardrobe-sidebar .default-tabs .nav-link:first-child {
            margin-right: var(--blocks-margin-size); } }

.wardrobe-upload {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  color: var(--white);
  opacity: 0;
  transition: opacity 0.4s; }
  .wardrobe-upload:hover {
    opacity: 1; }
  .wardrobe-upload .sub-title-1, .wardrobe-upload .answer-arguments__details h3, .answer-arguments__details .wardrobe-upload h3, .wardrobe-upload .answer-post-table h3, .answer-post-table .wardrobe-upload h3 {
    color: var(--white); }
  .wardrobe-upload p {
    margin-bottom: var(--blocks-margin-size); }
  .wardrobe-upload .btn-main {
    background: var(--white);
    color: var(--default-font-color);
    padding: 16.5px 40px;
    font-size: 15px;
    border: none;
    box-shadow: none !important; }
    .wardrobe-upload .btn-main:hover {
      background: var(--main-color);
      color: var(--white); }

.wardrobe-watermark {
  position: absolute;
  bottom: var(--blocks-margin-size);
  left: var(--blocks-margin-size);
  width: 180px;
  z-index: 6;
  opacity: 0.6; }
  @media (max-width: 767px) {
    .wardrobe-watermark {
      width: 140px; } }
  @media (max-width: 575px) {
    .wardrobe-watermark {
      width: 90px; } }

.wardrobe .resizable {
  left: 0;
  top: 0;
  z-index: 3;
  width: auto;
  display: none;
  height: 50%; }
  .wardrobe .resizable.bouquet-img {
    height: 20%; }
  .wardrobe .resizable.veil-img {
    height: 40px; }
  .wardrobe .resizable.wardrobe-main-img {
    height: 100%; }
    .wardrobe .resizable.wardrobe-main-img img {
      height: auto; }
  .wardrobe .resizable .resizers {
    width: auto; }
  .wardrobe .resizable img {
    max-height: 100%;
    min-width: 100%;
    height: 100%; }

.wardrobe-footer {
  padding: var(--blocks-margin-size) 0; }
  @media (max-width: 639px) {
    .wardrobe-footer header {
      flex-direction: column; } }
  @media (max-width: 575px) {
    .wardrobe-footer footer {
      padding-top: 10px;
      text-align: center !important; } }
  .wardrobe-footer .default-tabs .nav-link {
    padding: 5px var(--blocks-padding-size);
    margin: 0 5px;
    background-color: var(--border-color); }
    @media (max-width: 575px) {
      .wardrobe-footer .default-tabs .nav-link {
        padding: 3px 10px; } }
    .wardrobe-footer .default-tabs .nav-link.active {
      background: none;
      color: var(--main-color); }

.wardrobe-actions {
  padding-top: var(--blocks-margin-size); }
  .wardrobe-actions .btn {
    padding: 14.5px 28px !important; }
    @media (max-width: 767px) {
      .wardrobe-actions .btn {
        width: 100%;
        text-align: center; } }
  .wardrobe-actions a:not(.btn) {
    font-size: 15px;
    font-style: italic;
    border-bottom: 2px solid var(--main-color);
    text-decoration: none;
    font-weight: 700;
    color: var(--main-color); }
    .wardrobe-actions a:not(.btn):hover {
      text-decoration: none; }

.resizable {
  cursor: grab; }

.dresses {
  position: relative; }
  @media (max-width: 1023px) {
    .dresses {
      display: none;
      padding: 0; } }
  .dresses.__for-tablet {
    display: none; }
    @media (max-width: 1023px) {
      .dresses.__for-tablet {
        display: block; } }
    @media (max-width: 767px) {
      .dresses.__for-tablet {
        display: none; } }
  .dresses.__for-mob {
    display: none; }
    @media (max-width: 767px) {
      .dresses.__for-mob {
        display: block; } }
  .dresses.__for-mob .dresses-item a, .dresses.__for-tablet .dresses-item a {
    width: calc(50% - 20px);
    height: 120px; }
    @media (max-width: 767px) {
      .dresses.__for-mob .dresses-item a, .dresses.__for-tablet .dresses-item a {
        height: 205px; } }
  .dresses .owl-stage-outer {
    padding: 20px;
    margin: -20px; }
  .dresses-item {
    width: 100%; }
    @media (max-width: 1279px) {
      .dresses-item {
        margin-bottom: 7.5px; } }
    .dresses-item__inner {
      margin: 0 -10px; }
    .dresses-item a {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(33% - 20px);
      transition: border-color 0.4s;
      color: var(--main-color);
      margin: 0 10px 20px;
      height: 160px;
      box-shadow: var(--default-box-shadow);
      background: var(--white);
      transition: transform 0.4s; }
      .dresses-item a.active {
        transform: scale(1.1); }
        .dresses-item a.active .remove-img {
          display: block; }
      @media (max-width: 767px) {
        .dresses-item a {
          width: auto; } }
      .dresses-item a img {
        max-width: 95%;
        max-height: 95%;
        width: auto !important; }
      .dresses-item a .remove-img {
        position: absolute;
        top: 8px;
        right: 8px;
        height: 10px;
        display: none;
        width: 10px;
        left: auto;
        bottom: auto;
        opacity: 1;
        color: var(--main-color); }
        .dresses-item a .remove-img .icon {
          width: 10px;
          height: 10px;
          z-index: 2;
          vertical-align: top; }
  .dresses > button {
    position: absolute;
    width: 100%;
    text-align: center;
    color: var(--owl-nav-btn-color);
    border: none;
    background: none;
    cursor: pointer; }
    .dresses > button.disabled {
      opacity: 0.4; }
    .dresses > button .icon {
      width: 20px;
      height: 20px; }
    .dresses > button.prev-dress {
      top: 0; }
      .dresses > button.prev-dress .icon {
        transform: rotate(180deg); }

.accessory-carousel {
  margin-bottom: 30px; }
  .accessory-carousel .owl-stage-outer {
    padding: 20px 10px;
    margin: -20px -10px; }
  .accessory-carousel .resizeable-img {
    height: 140px;
    box-shadow: var(--default-box-shadow);
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white);
    transition: transform 0.4s; }
    .accessory-carousel .resizeable-img.active {
      transform: scale(1.1); }
      .accessory-carousel .resizeable-img.active .remove-img {
        display: block; }
    @media (max-width: 767px) {
      .accessory-carousel .resizeable-img {
        width: auto; } }
    .accessory-carousel .resizeable-img img {
      max-width: 95%;
      max-height: 95%;
      width: auto !important; }
    .accessory-carousel .resizeable-img .remove-img {
      position: absolute;
      top: 8px;
      right: 8px;
      height: 10px;
      display: none;
      width: 10px;
      left: auto;
      bottom: auto;
      opacity: 1;
      color: var(--main-color); }
      .accessory-carousel .resizeable-img .remove-img .icon {
        width: 10px;
        height: 10px;
        z-index: 2;
        vertical-align: top; }

.wardrobe-price {
  position: absolute;
  font-size: 20px;
  font-weight: 700;
  right: 10px;
  bottom: 10px;
  color: var(--main-color);
  background: rgba(255, 255, 255, 0.6); }

.remove-img {
  position: absolute;
  bottom: calc(100% - 30px);
  left: calc(100% - 30px);
  transition: color 0.3s;
  color: var(--default-font-color);
  opacity: 0;
  transition: opacity 0.4s; }
  .remove-img:hover {
    color: var(--main-color); }
  .remove-img .icon {
    width: 15px;
    height: 15px; }
  .remove-img.__outside {
    bottom: calc(100% + 10px);
    left: calc(100% + 10px); }

.resizer {
  position: absolute;
  background: #000; }

.resize-drag {
  touch-action: none; }

.resize-top, .resize-bottom {
  width: 100%;
  height: 2px; }

.resize-top {
  left: -7.5px;
  top: -7.5px; }

.resize-bottom {
  right: -7.5px;
  bottom: -7.5px; }

.screen-reader-text {
  display: none !important; }

.tests-list .article-item {
  width: calc(50% - 20px); }
  @media (max-width: 639px) {
    .tests-list .article-item {
      width: 100%; } }
  .tests-list .article-item__price {
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--main-color-light);
    font-weight: 700;
    font-size: 17px;
    z-index: 2; }
  .tests-list .article-item__img {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    position: relative; }
    .tests-list .article-item__img .btn-main {
      background: rgba(255, 255, 255, 0.4);
      color: var(--main-color-light);
      position: relative;
      z-index: 2;
      margin-bottom: var(--blocks-padding-size); }

.lips {
  padding: 0 15px;
  margin: 0 20px;
  line-height: 40px;
  font-size: 16px;
  overflow: hidden;
  background: #fff;
  color: var(--main-color);
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  border-radius: 5px;
  border: 3px solid var(--main-color);
  transition: background-color 0.3s, border-color 0.3s, color 0.3s; }

.lips:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: url(../img/lips.png) 0 0 no-repeat;
  width: 27px;
  height: 15px;
  margin-right: 15px; }

.lips:hover,
.lips.active {
  background: var(--main-color);
  color: #fff; }

.lips:hover:before,
.lips.active:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: url(../img/lips2.png) 0 0 no-repeat;
  width: 27px;
  height: 15px;
  transition: 0.3s; }

.usi {
  padding: 0 15px;
  margin: 0 20px;
  line-height: 40px;
  font-size: 16px;
  overflow: hidden;
  background: #fff;
  color: #000;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  border-radius: 5px;
  border: 3px solid #000;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s; }

.usi:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: url(../img/usi.png) 0 0 no-repeat;
  width: 39px;
  height: 13px;
  margin-right: 15px; }

.usi:hover,
.usi.active {
  background: #000;
  color: #fff; }

.usi:hover:before,
.usi.active:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: url(../img/usi2.png) 0 0 no-repeat;
  width: 39px;
  height: 13px;
  transition: 0.3s; }

.wedd {
  padding: 0 15px;
  margin: 0 20px;
  line-height: 40px;
  font-size: 16px;
  overflow: hidden;
  background: #fff;
  color: #000;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  border-radius: 5px;
  border: 3px solid #000;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s; }

.wedd:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: url(../img/rings.png) 0 0 no-repeat;
  width: 31px;
  height: 23px;
  margin-right: 15px; }

.wedd:hover,
.wedd.active {
  background: var(--main-color);
  color: #fff; }

.wedd:hover:before,
.wedd.active:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: url(../img/rings2.png) 0 0 no-repeat;
  width: 31px;
  height: 23px;
  transition: 0.3s; }

.tests-btns {
  border-bottom: none; }
  .tests-btns a {
    text-decoration: none; }
    .tests-btns a:hover {
      text-decoration: none; }
    @media (max-width: 575px) {
      .tests-btns a {
        width: 100%;
        margin: 5px 0;
        text-align: center; } }

.owl-carousel.owl-custom-btns .owl-nav {
  padding-top: 0;
  margin-top: 0;
  text-align: right; }
  @media (max-width: 767px) {
    .owl-carousel.owl-custom-btns .owl-nav {
      text-align: center; } }
  .owl-carousel.owl-custom-btns .owl-nav button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--main-color);
    color: var(--white);
    font-size: 17px; }
    .owl-carousel.owl-custom-btns .owl-nav button.disabled {
      background-color: var(--border-color);
      pointer-events: none; }
    .owl-carousel.owl-custom-btns .owl-nav button .icon {
      height: 7px;
      width: 11px; }

.collage {
  box-shadow: var(--default-box-shadow);
  --collage-body-height: 500px; }
  @media (max-width: 767px) {
    .collage {
      --collage-body-height: 360px; } }
  @media (max-width: 575px) {
    .collage {
      flex-direction: column; } }
  .collage-body {
    background: #d6d8d8;
    max-width: calc(100% - 74px);
    position: relative;
    height: var(--collage-body-height); }
    .collage-body.__savedCollage {
      max-width: none; }
    @media (max-width: 575px) {
      .collage-body {
        max-width: none;
        height: calc(100vh - 74px);
        margin: 0 -20px; } }
    .collage-body.collages-uploaded .collage-upload {
      display: none !important; }
  .collage-sidebar {
    width: 74px;
    background: var(--white);
    padding: var(--blocks-padding-size);
    --icon-color: var(--default-font-color); }
    @media (max-width: 575px) {
      .collage-sidebar {
        width: auto;
        margin: 0 -20px;
        flex-direction: row !important;
        padding-bottom: 0; } }
    .collage-sidebar > * {
      width: 100%;
      display: block;
      padding: var(--blocks-margin-size) 0;
      color: var(--icon-color);
      text-align: center;
      transition: color 0.4s; }
      .collage-sidebar > *:hover {
        color: var(--main-color); }
      @media (max-width: 575px) {
        .collage-sidebar > * {
          margin-bottom: 20px;
          display: inline-block;
          vertical-align: top;
          width: auto; } }
      .collage-sidebar > *:not(:last-child) {
        border-bottom: 1px solid var(--border-color); }
        @media (max-width: 575px) {
          .collage-sidebar > *:not(:last-child) {
            border-bottom: none;
            border-right: 1px solid var(--border-color); } }
      .collage-sidebar > *:hover, .collage-sidebar > *.active {
        --icon-color: var(--main-color); }
      @media (max-width: 767px) {
        .collage-sidebar > * {
          padding: 12px 0; } }
      @media (max-width: 575px) {
        .collage-sidebar > * {
          padding: 0 10px; }
          .collage-sidebar > *.hide-for-mob, .collage-sidebar > *.toggle-for-mob {
            display: none; } }
      .collage-sidebar > *.lock-btn:not(.active) .icon:last-child {
        display: none; }
      .collage-sidebar > *.lock-btn.active .icon:first-child {
        display: none; }
      .collage-sidebar > * .icon {
        stroke: var(--icon-color);
        display: block;
        margin: 0 auto;
        transition: stroke 0.4s; }
        .collage-sidebar > * .icon-photo {
          width: 25px;
          height: 25px; }
          @media (max-width: 767px) {
            .collage-sidebar > * .icon-photo {
              width: 20px;
              height: 20px; } }
          @media (max-width: 767px) {
            .collage-sidebar > * .icon-photo {
              width: 30px;
              height: 30px; } }
        .collage-sidebar > * .icon-up, .collage-sidebar > * .icon-down {
          width: 17px;
          height: 19px; }
          @media (max-width: 767px) {
            .collage-sidebar > * .icon-up, .collage-sidebar > * .icon-down {
              width: 15px;
              height: 17px; } }
          @media (max-width: 575px) {
            .collage-sidebar > * .icon-up, .collage-sidebar > * .icon-down {
              width: 19px;
              height: 22px; } }
        .collage-sidebar > * .icon-lock, .collage-sidebar > * .icon-unlock {
          width: 23px;
          height: 31px; }
          @media (max-width: 767px) {
            .collage-sidebar > * .icon-lock, .collage-sidebar > * .icon-unlock {
              width: 17px;
              height: 23px; } }
          @media (max-width: 575px) {
            .collage-sidebar > * .icon-lock, .collage-sidebar > * .icon-unlock {
              width: 24px;
              height: 32px; } }
        .collage-sidebar > * .icon-trash {
          width: 25px;
          height: 29px; }
          @media (max-width: 767px) {
            .collage-sidebar > * .icon-trash {
              width: 19px;
              height: 22px; } }
          @media (max-width: 575px) {
            .collage-sidebar > * .icon-trash {
              width: 25px;
              height: 30px; } }
        .collage-sidebar > * .icon-save {
          width: 24px;
          height: 25px; }
          @media (max-width: 767px) {
            .collage-sidebar > * .icon-save {
              width: 19px;
              height: 20px; } }
          @media (max-width: 575px) {
            .collage-sidebar > * .icon-save {
              width: 26px;
              height: 27px; } }
        .collage-sidebar > * .icon-folder {
          width: 24px;
          height: 22px; }
          @media (max-width: 767px) {
            .collage-sidebar > * .icon-folder {
              width: 22px;
              height: 20px; } }
          @media (max-width: 575px) {
            .collage-sidebar > * .icon-folder {
              width: 30px;
              height: 27px; } }
        .collage-sidebar > * .icon-menu {
          height: 20px;
          width: 4px; }
        .collage-sidebar > * .icon-photo, .collage-sidebar > * .icon-save, .collage-sidebar > * .icon-folder {
          fill: none; }
        .collage-sidebar > * .icon-up, .collage-sidebar > * .icon-down, .collage-sidebar > * .icon-lock, .collage-sidebar > * .icon-unlock, .collage-sidebar > * .icon-trash {
          stroke: none; }
  .collage-uploaded-images {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto; }
    .collage-uploaded-images img {
      width: 100%;
      height: 100%; }
  .collage-upload {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(../images/temp/collage-bg.jpg) no-repeat left center/cover; }
    @media (max-width: 575px) {
      .collage-upload .sub-title-1, .collage-upload .answer-arguments__details h3, .answer-arguments__details .collage-upload h3, .collage-upload .answer-post-table h3, .answer-post-table .collage-upload h3 {
        font-size: 15px; } }
    .collage-upload p {
      margin-bottom: var(--blocks-margin-size);
      padding: 0 var(--blocks-margin-size); }
  .collage-filter > a {
    transition: color 0.3s;
    color: var(--default-font-color);
    font-weight: 700;
    text-decoration: none; }
    .collage-filter > a:hover {
      color: var(--main-color); }
    .collage-filter > a:hover {
      text-decoration: none; }
    .collage-filter > a .icon {
      width: 12px;
      height: 8px;
      margin-left: 5px; }
  .collage-filter__content {
    width: 400px;
    padding: 30px 45px;
    box-shadow: var(--default-box-shadow-hover);
    background: #fff;
    position: absolute;
    right: 0;
    top: calc(100% + var(--blocks-padding-size));
    z-index: 2;
    max-width: 100%; }
    @media (max-width: 575px) {
      .collage-filter__content {
        padding: 30px 10px; } }
    .collage-filter__content h4 {
      font-size: 17px;
      font-weight: 700;
      color: var(--default-font-color);
      margin-bottom: var(--blocks-margin-size);
      line-height: 1; }
  .collage-filter .form-group {
    margin-bottom: 35px; }
  .collage-filter__gender .text-checkbox:first-child {
    margin-right: 47px; }
  .collage-filter__colors {
    margin: 0 -5px; }
    .collage-filter__colors .color-checkbox {
      margin: 0 10px var(--blocks-margin-size); }
      @media (max-width: 575px) {
        .collage-filter__colors .color-checkbox {
          margin: 0 5px 10px; } }
  .collage-filter__clothes .form-group {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px; }
    .collage-filter__clothes .form-group .text-checkbox {
      width: calc(33.33% - var(--blocks-margin-size));
      margin: 0 10px var(--blocks-margin-size); }
  .collage-catalog header {
    margin-bottom: 30px;
    position: relative;
    z-index: 3; }
    .collage-catalog header .sub-title-1, .collage-catalog header .answer-arguments__details h3, .answer-arguments__details .collage-catalog header h3, .collage-catalog header .answer-post-table h3, .answer-post-table .collage-catalog header h3 {
      color: var(--main-color); }
    .collage-catalog header > * {
      margin: 0; }
  .collage-catalog__body {
    margin: 0 -10px; }
  .collage-catalog__item {
    margin: 0 10px var(--blocks-margin-size);
    width: calc(16.66% - var(--blocks-margin-size));
    position: relative;
    padding-top: 14%;
    overflow: hidden; }
    @media (max-width: 767px) {
      .collage-catalog__item {
        width: calc(25% - var(--blocks-margin-size));
        padding-top: 22%; } }
    @media (max-width: 575px) {
      .collage-catalog__item {
        width: calc(50% - var(--blocks-margin-size));
        padding-top: 44%; } }
    .collage-catalog__item img,
    .collage-catalog__item .adsbygoogle {
      width: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
      height: 100%;
      max-height: 100%; }
    .collage-catalog__item .icon {
      position: absolute;
      z-index: 2;
      width: 29px;
      height: 29px;
      top: calc(50% - 14.5px);
      left: calc(50% - 14.5px);
      color: var(--white);
      opacity: 0;
      transition: opacity 0.4s; }
    .collage-catalog__item:not(.__ad-block)::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      background: rgba(0, 0, 0, 0.2);
      opacity: 0;
      transition: opacity 0.4s; }
    .collage-catalog__item:not(.__ad-block):hover::before,
    .collage-catalog__item:not(.__ad-block):hover .icon {
      opacity: 1; }
  .collage-catalog .pagination {
    margin-top: 10px;
    margin-bottom: 0; }
  .collage-img.active .resizers {
    border-color: var(--main-color) !important; }
  .collage-img.locked .resizer {
    display: none; }

.collage-notification {
  text-align: center; }
  .collage-notification .close {
    position: absolute;
    right: var(--blocks-padding-size);
    top: var(--blocks-padding-size); }
    .collage-notification .close .icon {
      height: 12px;
      width: 12px;
      vertical-align: top; }
  .collage-notification .modal-content {
    padding: 50px;
    border-radius: 0; }
  .collage-notification .modal-header {
    border: none;
    margin-bottom: var(--blocks-margin-size);
    padding: 0; }
    .collage-notification .modal-header .sub-title-1, .collage-notification .modal-header .answer-arguments__details h3, .answer-arguments__details .collage-notification .modal-header h3, .collage-notification .modal-header .answer-post-table h3, .answer-post-table .collage-notification .modal-header h3 {
      color: var(--main-color); }
  .collage-notification .modal-body {
    padding: 0; }
  .collage-notification .btn-main {
    margin-bottom: var(--blocks-padding-size); }

.collages-list {
  box-shadow: none;
  background: none; }
  .collages-list .collage-item {
    width: calc(50% - var(--blocks-padding-size));
    padding-bottom: 42%;
    position: relative;
    margin-bottom: 30px;
    position: relative;
    cursor: pointer; }
    .collages-list .collage-item::before {
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      content: "";
      z-index: 1;
      transition: opacity 0.4s;
      opacity: 0; }
    .collages-list .collage-item .icon {
      position: absolute;
      z-index: 2;
      width: 29px;
      height: 29px;
      top: calc(50% - 14.5px);
      left: calc(50% - 14.5px);
      color: var(--white);
      opacity: 0;
      transition: opacity 0.4s; }
    .collages-list .collage-item:hover::before,
    .collages-list .collage-item:hover .icon {
      opacity: 1; }
    .collages-list .collage-item .collage-body {
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0; }
      .collages-list .collage-item .collage-body .collage-uploaded-images {
        overflow: hidden; }

.styles-list {
  box-shadow: none;
  background: none; }
  .styles-list .style-item {
    width: calc(33.33% - var(--blocks-padding-size));
    padding-bottom: 42%;
    position: relative;
    margin-bottom: 30px;
    position: relative;
    cursor: pointer; }
    .styles-list .style-item .resizer {
      display: none !important; }
    .styles-list .style-item .resizers {
      border: none !important; }
    .styles-list .style-item::before {
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      content: "";
      z-index: 4;
      transition: opacity 0.4s;
      opacity: 0; }
    .styles-list .style-item .icon {
      position: absolute;
      z-index: 5;
      width: 29px;
      height: 29px;
      top: calc(50% - 14.5px);
      left: calc(50% - 14.5px);
      color: var(--white);
      opacity: 0;
      transition: opacity 0.4s; }
    .styles-list .style-item:hover::before,
    .styles-list .style-item:hover .icon {
      opacity: 1; }
    .styles-list .style-item .wardrobe-body {
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      overflow: hidden; }
      .styles-list .style-item .wardrobe-body__inner {
        width: 420px; }

.collage-zoomed .modal-header,
.style-zoomed .modal-header {
  border: none;
  margin: 0;
  padding: 0; }
  .collage-zoomed .modal-header .close,
  .style-zoomed .modal-header .close {
    position: absolute;
    bottom: 100%;
    left: 100%;
    color: var(--white) !important;
    padding: 10px; }
    .collage-zoomed .modal-header .close .icon,
    .style-zoomed .modal-header .close .icon {
      vertical-align: top;
      width: 15px;
      height: 15px; }

.collage-zoomed .modal-body,
.style-zoomed .modal-body {
  padding: 0; }

.collage-zoomed .modal-dialog,
.style-zoomed .modal-dialog {
  max-width: 768px; }

.style-zoomed .modal-dialog {
  width: 420px; }

.style-zoomed .resizers {
  border: none !important; }

.style-zoomed .remove-img,
.style-zoomed .resizer {
  display: none; }

.style-zoomed .wardrobe-body__inner {
  overflow: hidden; }

.all-services h2 {
  font-size: 25px;
  font-style: italic;
  font-weight: 700;
  margin-bottom: 30px; }

.all-services h3 {
  font-size: 20px;
  font-style: italic;
  margin-bottom: 20px;
  color: var(--main-color); }

.all-services h4 {
  font-size: 17px;
  font-style: italic;
  margin-bottom: 20px;
  color: var(--main-color); }

.all-services strong {
  font-weight: 700;
  color: var(--main-color); }

.all-services .btn-main {
  font-size: 16px; }

.all-services__header {
  box-shadow: var(--default-box-shadow);
  margin-bottom: var(--section-mb); }
  .all-services__header .__img-cont {
    height: 336px; }
    .all-services__header .__img-cont img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      display: block; }
  .all-services__header .__capture {
    padding: 30px 40px 30px 50px;
    font-size: 16px;
    flex-wrap: wrap; }
    @media (max-width: 1023px) {
      .all-services__header .__capture {
        flex-direction: column; } }
    @media (max-width: 767px) {
      .all-services__header .__capture {
        padding: 30px 20px;
        font-size: 15px; } }
    .all-services__header .__capture > div {
      flex: 1; }
    .all-services__header .__capture h2 {
      margin-bottom: 15px; }
      @media (max-width: 767px) {
        .all-services__header .__capture h2 {
          font-size: 17px; } }
    .all-services__header .__capture .btn-main {
      margin-left: 35px; }
      @media (max-width: 1023px) {
        .all-services__header .__capture .btn-main {
          margin-left: 0;
          width: 100%; } }

.all-services__steps {
  margin-bottom: var(--section-mb); }
  @media (max-width: 767px) {
    .all-services__steps {
      flex-direction: column; } }
  .all-services__steps p {
    font-size: 18px; }
  .all-services__steps .flex-1 {
    flex: 1; }
    @media (max-width: 767px) {
      .all-services__steps .flex-1 {
        margin-bottom: 40px; }
        .all-services__steps .flex-1 .btn {
          width: 100%; } }
  .all-services__steps .list-owl-carousel {
    width: 380px;
    margin-left: 40px;
    max-width: 100%; }
    .all-services__steps .list-owl-carousel .steps-slider-item {
      width: 380px;
      height: 500px;
      max-width: 100%; }
      .all-services__steps .list-owl-carousel .steps-slider-item img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
    .all-services__steps .list-owl-carousel .owl-nav {
      position: absolute;
      top: 50%;
      height: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      font-size: 70px;
      line-height: 1;
      margin: 0; }
      .all-services__steps .list-owl-carousel .owl-nav button {
        opacity: 1 !important; }
        .all-services__steps .list-owl-carousel .owl-nav button span {
          color: var(--main-color);
          display: block;
          line-height: 0; }
        .all-services__steps .list-owl-carousel .owl-nav button.disabled span {
          color: #d3d3d3; }
    .all-services__steps .list-owl-carousel .owl-dots {
      position: absolute;
      bottom: 30px;
      left: 0;
      right: 0; }

.consulting {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
  margin-bottom: var(--section-mb); }
  @media (max-width: 1023px) {
    .consulting {
      grid-gap: 20px; } }
  @media (max-width: 767px) {
    .consulting {
      display: block; } }
  @media (max-width: 767px) {
    .consulting-item:not(:last-child) {
      margin-bottom: 40px; } }
  .consulting-item__img {
    height: 235px;
    box-shadow: var(--default-box-shadow);
    margin-bottom: 17px; }
    .consulting-item__img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .consulting h3 {
    font-size: 20px;
    font-weight: 700;
    color: var(--main-color);
    margin-bottom: 10px; }

.consulting-steps {
  margin-bottom: var(--section-mb); }
  @media (max-width: 767px) {
    .consulting-steps h2 {
      text-align: center; } }
  .consulting-steps__inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    text-align: center;
    font-size: 14px; }
    @media (max-width: 1023px) {
      .consulting-steps__inner {
        grid-template-columns: 1fr 1fr;
        grid-gap: 40px; } }
    @media (max-width: 767px) {
      .consulting-steps__inner {
        display: block; } }
    .consulting-steps__inner h3 {
      margin-bottom: 10px; }
    .consulting-steps__inner img {
      margin-bottom: 10px; }
  .consulting-steps.__serv2 .consulting-steps__item img {
    max-width: 118px; }
    @media (max-width: 1023px) {
      .consulting-steps.__serv2 .consulting-steps__item img {
        max-width: 158px; } }
  @media (max-width: 767px) {
    .consulting-steps__item:not(:last-child) {
      margin-bottom: 40px; } }
  .consulting-steps.__spec-sel .consulting-steps__inner {
    grid-template-columns: 1fr 1fr 1fr; }

.consulting-tarrifs {
  margin-bottom: var(--section-mb); }
  @media (max-width: 767px) {
    .consulting-tarrifs h2,
    .consulting-tarrifs h3 {
      text-align: center; } }
  .consulting-tarrifs .d-flex {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1023px) {
      .consulting-tarrifs .d-flex {
        flex-direction: column; } }
  .consulting-tarrifs .btn {
    width: 100%;
    text-align: center; }
  .consulting-tarrifs.__serv2 {
    background: #fff3f7;
    padding: 50px 55px; }
    @media (max-width: 767px) {
      .consulting-tarrifs.__serv2 {
        margin: 0 -20px 50px;
        padding: 50px 20px; } }
    @media (max-width: 1023px) {
      .consulting-tarrifs.__serv2 .d-flex {
        flex-direction: row; } }
    @media (max-width: 767px) {
      .consulting-tarrifs.__serv2 .d-flex {
        flex-direction: column; } }
    .consulting-tarrifs.__serv2 .consulting-tarrifs__left {
      box-shadow: none;
      width: 50%;
      padding: 0; }
      @media (max-width: 767px) {
        .consulting-tarrifs.__serv2 .consulting-tarrifs__left {
          width: 100%; } }
      .consulting-tarrifs.__serv2 .consulting-tarrifs__left:first-child {
        padding-right: 30px; }
        @media (max-width: 767px) {
          .consulting-tarrifs.__serv2 .consulting-tarrifs__left:first-child {
            padding-right: 0; } }
    .consulting-tarrifs.__serv2 h2 {
      max-width: 60%; }
      @media (max-width: 1023px) {
        .consulting-tarrifs.__serv2 h2 {
          max-width: 100%;
          text-align: center; } }
    .consulting-tarrifs.__serv2 h3 {
      font-size: 20px;
      color: var(--default-font-color); }
    .consulting-tarrifs.__serv2 .__old-price {
      color: #b3b3b3;
      font-size: 20px;
      font-weight: 700; }
      .consulting-tarrifs.__serv2 .__old-price span {
        position: relative; }
        .consulting-tarrifs.__serv2 .__old-price span::after {
          position: absolute;
          width: 100%;
          height: 0px;
          left: 0;
          top: 10px;
          content: "";
          border: 1px solid #f19386;
          transform: rotate(15deg); }
    .consulting-tarrifs.__serv2 .__price {
      margin-bottom: 30px; }
      .consulting-tarrifs.__serv2 .__price strong {
        font-weight: 700;
        font-size: 24px;
        color: var(--main-color);
        margin-right: 25px; }
  .consulting-tarrifs__left {
    background: #fff3f7;
    box-shadow: 0px 0px 10px rgba(0, 10, 35, 0.09);
    padding: 50px 55px;
    flex: 1; }
    @media (max-width: 1023px) {
      .consulting-tarrifs__left {
        margin-bottom: 40px; } }
    @media (max-width: 767px) {
      .consulting-tarrifs__left {
        padding: 30px 20px; } }
  .consulting-tarrifs__right {
    width: 360px;
    margin-left: 36px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 10, 35, 0.04);
    padding: 50px 35px 50px 50px; }
    @media (max-width: 1023px) {
      .consulting-tarrifs__right {
        width: auto;
        margin: 0; } }
    @media (max-width: 767px) {
      .consulting-tarrifs__right {
        padding: 30px 20px; } }
    .consulting-tarrifs__right h3 {
      color: var(--default-font-color); }

.gift-block {
  position: relative;
  padding: 50px 50% 50px 54px;
  color: var(--white);
  min-height: 344px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: var(--section-mb); }
  @media (max-width: 1023px) {
    .gift-block {
      padding-right: 40%; } }
  @media (max-width: 767px) {
    .gift-block {
      color: var(--default-font-color);
      padding: 30px 20px 0; } }
  .gift-block.__sale {
    padding-right: 35%; }
    @media (max-width: 1023px) {
      .gift-block.__sale {
        padding-right: 25%; } }
    @media (max-width: 767px) {
      .gift-block.__sale {
        padding: 30px 0 0; } }
    .gift-block.__sale h2 {
      margin: 0; }
    .gift-block.__sale ul {
      margin: 30px 0;
      padding: 0;
      list-style: none; }
      .gift-block.__sale ul li {
        background: url(../images/temp/ok.png) no-repeat left 5px;
        padding-left: 25px; }
        .gift-block.__sale ul li:not(:last-child) {
          margin-bottom: 16px; }
        @media (max-width: 767px) {
          .gift-block.__sale ul li {
            background: url(../images/temp/ok-red.png) no-repeat left 5px; } }
    .gift-block.__sale .d-flex {
      white-space: nowrap;
      width: 100%; }
      @media (max-width: 767px) {
        .gift-block.__sale .d-flex {
          flex-wrap: wrap;
          margin-bottom: 30px;
          flex-direction: column-reverse;
          align-items: flex-start !important;
          justify-content: flex-start; }
          .gift-block.__sale .d-flex h2 {
            color: var(--main-color); }
          .gift-block.__sale .d-flex .btn-main {
            width: 100%;
            margin-top: 15px; } }
      .gift-block.__sale .d-flex .btn-main {
        margin-right: 35px; }
  .gift-block > *:not(img) {
    z-index: 2;
    position: relative; }
  .gift-block img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1; }
    @media (max-width: 767px) {
      .gift-block img {
        position: relative;
        object-position: right;
        min-height: 190px; } }
  @media (min-width: 768px) {
    .gift-block:not(.__sale) .btn-main {
      background: var(--white);
      color: var(--main-color);
      border: none; } }
  @media (max-width: 767px) {
    .gift-block:not(.__sale) .btn-main {
      width: 100%;
      margin-bottom: 33px; } }
  .gift-block h2 {
    margin-bottom: 15px; }
  .gift-block p {
    margin-bottom: 20px; }

.faq-block {
  display: flex;
  margin-bottom: var(--section-mb); }
  @media (max-width: 767px) {
    .faq-block {
      position: relative;
      padding-top: 90px;
      flex-direction: column; } }
  @media (max-width: 767px) {
    .faq-block h2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center; } }
  .faq-block ul {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: list; }
    .faq-block ul li {
      margin-bottom: 25px; }
      .faq-block ul li > h4 {
        color: var(--default-font-color);
        position: relative;
        padding-right: 50px;
        cursor: pointer; }
        .faq-block ul li > h4.active {
          color: var(--main-color); }
          .faq-block ul li > h4.active::after {
            background: url(../images/arrow-up.png) no-repeat; }
        .faq-block ul li > h4::before {
          counter-increment: list 1;
          content: counter(list) ". "; }
        .faq-block ul li > h4::after {
          content: "";
          width: 20px;
          height: 12px;
          background: url(../images/arrow-down.png) no-repeat;
          position: absolute;
          right: 0;
          top: 8px; }
      .faq-block ul li div {
        display: none; }
  .faq-block__list {
    width: 390px;
    margin-left: 50px; }
    @media (max-width: 767px) {
      .faq-block__list {
        width: auto;
        margin-left: 0; } }
  .faq-block .__img-cont {
    flex: 1; }
    @media (max-width: 767px) {
      .faq-block .__img-cont {
        margin-bottom: 30px;
        text-align: center; } }
    .faq-block .__img-cont img {
      max-width: 100%; }
  .faq-block .btn {
    width: 100%; }

.feedback-form {
  display: flex;
  margin-bottom: var(--section-mb);
  font-style: 17px; }
  @media (max-width: 1023px) {
    .feedback-form {
      padding-top: 100px;
      position: relative; } }
  @media (max-width: 767px) {
    .feedback-form {
      flex-direction: column; } }
  .feedback-form p {
    font-weight: 700;
    margin-bottom: 15px;
    font-style: italic; }
  .feedback-form .__form {
    width: 330px;
    margin-right: 110px;
    max-width: 100%; }
    @media (max-width: 1023px) {
      .feedback-form .__form {
        margin-right: 20px; } }
    @media (max-width: 767px) {
      .feedback-form .__form {
        margin-right: 0;
        margin-bottom: 30px;
        width: auto; } }
  .feedback-form .__img-cont {
    flex: 1; }
    .feedback-form .__img-cont img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center; }
  .feedback-form .__contacts {
    margin-bottom: 40px; }
    @media (max-width: 767px) {
      .feedback-form .__contacts {
        flex-direction: column; }
        .feedback-form .__contacts .social-nets {
          padding-top: 20px; } }
    .feedback-form .__contacts .icon.icon-phone-contact {
      fill: none;
      stroke: var(--default-font-color);
      width: 16px;
      height: 16px;
      margin-right: 10px;
      vertical-align: middle;
      line-height: 1; }
  .feedback-form .btn.btn-main {
    width: 100%; }
  @media (max-width: 1023px) {
    .feedback-form h2 {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center; } }

.mce-content-body .owl-carousel {
  display: block !important; }

.service-spec {
  display: flex;
  margin-bottom: 110px; }
  @media (max-width: 767px) {
    .service-spec {
      flex-direction: column-reverse; } }
  .service-spec .__img-cont {
    width: 45%;
    margin-right: 50px; }
    @media (max-width: 767px) {
      .service-spec .__img-cont {
        width: auto;
        margin-right: 0; } }
    .service-spec .__img-cont img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .service-spec > div:not(.__img-cont) {
    flex: 1; }
    @media (max-width: 767px) {
      .service-spec > div:not(.__img-cont) {
        margin-bottom: 50px; } }
  .service-spec .btn {
    width: 100%; }
