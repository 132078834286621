.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.icon-arrow-down {
	font-size:(6/10)*1rem;
	width:(10/6)*1em;
}
.icon-arrow-up {
	font-size:(18/10)*1rem;
	width:(18/18)*1em;
}
.icon-check {
	font-size:(11/10)*1rem;
	width:(15/11)*1em;
}
.icon-close {
	font-size:(14/10)*1rem;
	width:(14/14)*1em;
}
.icon-down {
	font-size:(20/10)*1rem;
	width:(18/20)*1em;
}
.icon-email {
	font-size:(12/10)*1rem;
	width:(12/12)*1em;
}
.icon-eye {
	font-size:(14/10)*1rem;
	width:(20/14)*1em;
}
.icon-fb {
	font-size:(12/10)*1rem;
	width:(12/12)*1em;
}
.icon-folder {
	font-size:(24/10)*1rem;
	width:(27/24)*1em;
}
.icon-google {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-ic_adapt {
	font-size:(68.03/10)*1rem;
	width:(68.03/68.03)*1em;
}
.icon-inst {
	font-size:(12/10)*1rem;
	width:(12/12)*1em;
}
.icon-lock {
	font-size:(32/10)*1rem;
	width:(24/32)*1em;
}
.icon-menu {
	font-size:(21/10)*1rem;
	width:(5/21)*1em;
}
.icon-pencil {
	font-size:(19/10)*1rem;
	width:(19/19)*1em;
}
.icon-phone {
	font-size:(19/10)*1rem;
	width:(19/19)*1em;
}
.icon-phone-contact {
	font-size:(20/10)*1rem;
	width:(20/20)*1em;
}
.icon-photo {
	font-size:(27/10)*1rem;
	width:(28/27)*1em;
}
.icon-plus-add {
	font-size:(30/10)*1rem;
	width:(30/30)*1em;
}
.icon-plus-thin {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-save {
	font-size:(27/10)*1rem;
	width:(27/27)*1em;
}
.icon-star {
	font-size:(23/10)*1rem;
	width:(24/23)*1em;
}
.icon-star-o {
	font-size:(23/10)*1rem;
	width:(24/23)*1em;
}
.icon-support {
	font-size:(75/10)*1rem;
	width:(76/75)*1em;
}
.icon-tg {
	font-size:(12/10)*1rem;
	width:(14/12)*1em;
}
.icon-trash {
	font-size:(30/10)*1rem;
	width:(26/30)*1em;
}
.icon-twitter {
	font-size:(10/10)*1rem;
	width:(14/10)*1em;
}
.icon-unlock {
	font-size:(32/10)*1rem;
	width:(24/32)*1em;
}
.icon-up {
	font-size:(20/10)*1rem;
	width:(18/20)*1em;
}
.icon-user-o {
	font-size:(46/10)*1rem;
	width:(46/46)*1em;
}
.icon-viber {
	font-size:(18/10)*1rem;
	width:(17/18)*1em;
}
.icon-vk {
	font-size:(9/10)*1rem;
	width:(14/9)*1em;
}
.icon-whatsapp {
	font-size:(14/10)*1rem;
	width:(14/14)*1em;
}
