.flex-tablet-column {
  @include for-tablet {
    flex-direction: column;
  }
}

.flex-tablet-column-reverse {
  @include for-tablet {
    flex-direction: column-reverse;
  }
}


.mb-70 {
  margin-bottom: 85px;

  @include for-tablet {
    margin-bottom: 60px;
  }
}

.bg-img {
  background: {
    repeat: no-repeat;
    size: cover;
  }

  img {
    opacity: 0;
  }
}

.tablet-mb-50 {
  @include for-tablet {
    margin-bottom: 50px;
  }
}

.text-xs-center {
  @include for-xs {
    text-align: center;
  }
}

.mb-6 {
  margin-bottom: 60px !important;

  @include for-xs {
    margin-bottom: 40px;
  }
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-50 {
  margin-bottom: 50px;
}

.mob {
  &-hide {
    @include for-xs {
      display: none !important;
    }
  }

  &-show {
    @include for-xs {
      display: block !important;
    }
  }
}

.mt-60 {
  margin-top: 60px;

  @include for-md {
    margin-top: 40px;
  }
}

.mb-60 {
  margin-bottom: 60px;

  @include for-md {
    margin-bottom: 40px;
  }
}

body {
  .owl-dots.disabled {
    display: none !important;
  }
}

.fs-16 {
  font-size: 16px;
}