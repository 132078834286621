:root {
  --section-mb: 110px;
  @include for-tablet {
    --section-mb: 90px;
  }
}
.header {
  padding-bottom: 22px;

  @include for-xs {
    padding-bottom: 30px;
  }

  &__top {
    padding: 44px 0 31px;

    @include for-tablet {
      padding-top: 36px;
      padding-bottom: 60px;
      position: relative;
      margin-bottom: 35px;
    }

    @include for-xs {
      padding: 12px 0;
      border-bottom: 1px solid var(--border-color);
      margin-bottom: 25px;

      .social-nets {
        display: none !important;
      }

      .main-menu {
        display: none;
      }
    }
  }

  &__phone-number {
    font-size: 18px;
    @include dec-res;
    @include colorDefaultToMain;
    font-weight: bold;

    @include for-tablet {
      font-size: 16px;
    }

    @include for-xs {
      font-size: 12px;
    }

    .icon {
      width: 19px;

      @include for-xs {
        width: 14px;
        height: 14px;
      }
    }
  }

  &__bottom {
    position: relative;

    @include for-xs {
      .btn-main {
        font-size: 12px;
        line-height: 14px;
        white-space: normal;
        background: transparent;
        color: var(--main-color);
        padding: 9px 21px;
        margin-left: 30px;
        box-shadow: none;
        border-width: 1px;
        border-color: #dc1052;
      }
    }

    .container {
      @include for-xs {
        // align-items: flex-start !important;
      }
    }
  }
}

.home-page {
  .header {
    &__bottom {
      @include for-xs {
        padding-bottom: calc(28px + var(--blocks-margin-size) * 2);
      }
    }
  }
}

.logo {
  margin-left: -8px;
  max-width: 245px;

  @include for-tablet {
    max-width: 206px;
  }

  @include for-xs {
    margin-left: -5px;
    flex: 0 0 127px;
  }
}

.social-nets {
  a {
    width: 25px;
    height: 25px;
    background: $main-bg-gradient;
    color: var(--white);
    @include dec-res;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    transition: color 0.3s;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: var(--main-color-light);
      transition: 0.3s;
      transform: scale(0);
      opacity: 0;
      border-radius: 50%;

      @include for-tablet {
        display: none;
      }
    }

    &:hover {
      &::after {
        transform: scale(1);
        opacity: 1;
      }
    }

    .icon {
      max-width: 13px;
      position: relative;
      z-index: 1;
    }
  }
}

.main-menu {
  @include for-tablet {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 calc(var(--container-tablet-padding) - var(--blocks-padding-size));
  }

  @include for-md639 {
    padding: 0;
  }

  ul {
    @include reset-list;

    @include for-tablet {
      justify-content: space-between;
    }

    li {
      margin: 0 20px;

      a {
        @include dec-res;
        font-weight: 700;
        font-size: 16px;
        @include colorDefaultToMain;

        @include for-tablet {
          font-style: 16px;
        }

        &.active {
          color: var(--main-color);
        }
      }
    }
  }
}

.slogan {
  font-size: 19px;
  font-weight: normal;
  font-style: italic;
  max-width: 370px;
  color: var(--black);

  @include for-tablet {
    display: none;
  }
}

.home-page {
  .slogan {
    @include for-xs {
      display: block;
      font-size: 12px;
      margin-bottom: var(--blocks-padding-size) !important;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.main-categories {
  background: var(--dark-bg);
  position: relative;
  z-index: 9;

  &.fixed {
    transition: transform 0.4s;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    transform: translate(0, -100%);

    &.fixed-show {
      transform: translate(0);
    }
  }

  > .container {
    min-height: 70px;
  }

  ul {
    @include reset-list;
  }

  &__list-wrap {
    @include for-tablet {
      display: none;
      width: 320px;
      left: 0;
      top: 100%;
      position: absolute;
      background: var(--dark-bg);
      box-shadow: 0px 0px 4px rgba(238, 238, 238, 0.25);
      padding: 15px 30px 15px 15px !important;
      max-height: calc(100vh - 78px);
      overflow: auto;
    }

    @include for-xs {
      padding-right: 15px !important;
    }

    .main-menu {
      position: relative;
      top: 0;
      left: 0;

      a,
      div {
        color: var(--white);
      }

      ul {
        flex-wrap: wrap;

        li {
          width: 50%;
          margin: 0 0 10px;

          &:nth-child(even) {
            text-align: right;
          }
        }
      }
    }

    .social-nets {
      justify-content: space-between;
      margin-bottom: 30px;

      a {
        background: var(--white);
        color: var(--default-font-color);
      }
    }
  }

  &__list {
    margin-left: -15px;
    display: flex;

    @include for-tablet {
      flex-direction: column;
    }

    @include for-xs {
      margin-bottom: 25px !important;
    }

    .icon {
      width: 10px;
      margin-left: 10px;
      color: var(--owl-nav-btn-color);

      @include for-tablet {
        margin-left: 0;
        margin-right: 1px;
        transition: transform 0.4s;
      }
    }

    a {
      color: var(--white);
      @include dec-res;
      transition: color 0.3s;

      @include for-tablet {
        &.active {
          border-bottom-color: transparent;

          .icon {
            transform: rotate(180deg);
          }
        }
      }

      &:hover,
      &.active {
        color: var(--main-color-light);
      }
    }

    li {
      overflow: hidden;

      &:hover {
        overflow: visible;

        > a {
          color: var(--main-color-light);
        }

        > ul {
          opacity: 1;
        }
      }
    }

    > li {
      position: relative;

      &:not(:last-child) {
        margin-right: 45px;

        @include for-md1279 {
          margin-right: 30px;
        }

        @include for-tablet {
          margin-right: 0;

          > a {
            border-bottom: 2px solid #464646;
          }
        }
      }

      > a,
      div {
        font-weight: 700;
        display: block;
        padding: 35px 0;
        font-size: 16px;
        color: var(--white);

        @include for-tablet {
          padding: 8px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      ul {
        position: absolute;
        top: 100%;
        left: 0;
        box-shadow: 0px 0px 4px rgba(238, 238, 238, 0.25);
        width: 230px;
        padding: 10px 0;
        background: var(--dark-bg);
        font-weight: normal;
        font-size: 15px;
        opacity: 0;
        transition: opacity 0.3s;

        @include for-tablet {
          position: relative;
          top: auto;
          left: auto;
          box-shadow: none;
          opacity: 1;
          display: none;
          width: auto;
          max-width: 90%;
        }

        a {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .icon {
          transform: rotate(-90deg);

          @include for-tablet {
            transform: rotate(0);
          }
        }

        li {
          position: relative;
          padding: 5px 15px;

          @include for-tablet {
            padding: 5px 0;
          }

          &:not(:last-child) {
            margin-bottom: 5px;
          }
        }

        ul {
          padding: 10px;
          font-size: 14px;
          left: 100%;
          top: 0;

          @include for-tablet {
            position: relative;
            top: auto;
            left: auto;
          }
        }
      }

      > ul {
        left: -15px;

        @include for-tablet {
          left: 0;
        }
      }
    }
  }
}

.auth {
  &-block {
    color: var(--white);

    @include for-xs {
      position: relative;
    }

    h5 {
      font-size: 16px;
      font-weight: bold;

      @include for-xs {
        display: none;
      }
    }

    a {
      color: var(--white);
      @include dec-res;

      br {
        display: none;

        @include for-xs {
          display: block;
        }
      }
    }

    .auth-img {
      margin-right: var(--blocks-margin-size);
    }

    > div:not(.auth-img) {
      @include for-xs {
        background: var(--dark-bg);
        top: calc(100% + 10px);
        right: -20px;
        position: absolute;
        padding: 14px 20px;
        text-align: center;
        max-width: 320px;
        font-size: 16px;
        white-space: nowrap;
        box-shadow: var(--default-box-shadow);
        transform: translate(105%, 0);
        transition: transform 0.4s;

        .d-flex {
          flex-direction: column;
        }

        &.active {
          transform: translate(0);
        }

        a {
          &:not(:last-child) {
            margin-bottom: var(--blocks-padding-size);
          }
        }

        span {
          display: none;
        }

        // display: none;
      }
    }
  }

  &-img {
    width: 49px;
    height: 49px;

    .icon-arrow-down {
      display: none;

      @include for-xs {
        display: inline-block;
        vertical-align: top;
        width: 10px;
        height: 6px;
        color: var(--owl-nav-btn-color);
        position: absolute;
        top: calc(50% - 3px);
        left: calc(100% + 5px);
      }
    }

    @include for-xs {
      position: relative;
      margin-right: 15px !important;
    }

    img {
      border-radius: 50%;
    }

    img,
    svg {
      width: 49px;
      height: 49px;
    }
  }
}

main.content {
  padding: 55px 0 50px;
  @include for-md {
    padding: 30px 0;
  }
}

.content {
  &-body {
    flex: 1;
    max-width: calc(100% - (var(--sidebar-width) + var(--sidebar-left-margin)));

    @include for-tablet {
      max-width: 100%;
    }
  }

  &-sidebar {
    width: var(--sidebar-width);
    margin-left: var(--sidebar-left-margin);

    @include for-tablet {
      width: auto;
      margin-left: 0;
    }

    .ad-block {
      margin: 60px 0;
    }
  }
}

.services {
  .service {
    &-item {
      width: calc(50% - 10px);
      box-shadow: var(--default-box-shadow);
      margin-bottom: var(--blocks-margin-size);
      position: relative;
      padding-bottom: 15px;
      overflow: hidden;

      @include for-tablet {
        margin-bottom: 65px;

        &:nth-child(3),
        &:nth-child(4) {
          margin-bottom: var(--blocks-margin-size);
        }
      }

      @include for-xs {
        width: 100%;
        margin: 0 0 var(--blocks-margin-size);
      }

      &:hover {
        a {
          color: var(--main-color);
        }

        .service {
          &-img {
            img {
              transform: scale(1.1);
            }
          }

          &-details {
            transform: translate(0);
          }

          &-desc {
            opacity: 1;
          }
        }
      }
    }

    &-img {
      display: block;
      @include dec-res;
      overflow: hidden;
      height: 180px;
      position: relative;

      img {
        display: block;
        width: 100%;
        height: 100%;
        transition: var(--img-scale-transition);
        object-fit: cover;
        object-position: center;
        position: absolute;
      }
    }

    &-details {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translate(0, calc(100% - 55px));
      transition: transform 0.4s;
      background: var(--white);
      padding: 0 var(--blocks-padding-size) var(--blocks-padding-size);

      @include for-tablet {
        transform: translate(0);
        position: relative;
      }

      header {
        color: var(--main-color);
        // line-height: 55px;
        // white-space: nowrap;
        font-weight: bold;
        font-size: var(--post-item-title-fs);
        align-items: flex-start !important;
        padding: 10px 0;
        line-height: 1.2;

        @include for-xs {
          // line-height: 45px;
        }
        .text-uppercase {
          white-space: nowrap;
        }
      }
    }

    &-title {
      font-weight: 700;
      font-style: italic;
      @include colorDefaultToMain;
      @include dec-res;
    }

    &-desc {
      transition: opacity 0.4s ease-out;
      opacity: 0.2;

      @include for-tablet {
        opacity: 1;
      }
    }
  }
}

.our-services {
  @include for-tablet {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }

  .service {
    &-item {
      box-shadow: var(--default-box-shadow);
      margin-bottom: 12px;
      padding: 15px 30px;
      transition: box-shadow 0.4s;
      background: var(--white);
      justify-content: space-between;
      position: relative;

      &:last-child {
        margin-bottom: -15px;

        @include for-tablet {
          margin-bottom: 0;
        }
      }

      .png-icon-new {
        position: absolute;
        right: -3px;
        top: -3px;
        transform: scale(0.9);
      }

      @include for-tablet {
        width: calc(50% - 10px);
      }

      @include for-xs {
        width: 100%;
        margin: 0 0 var(--blocks-margin-size);
      }

      &:hover {
        box-shadow: var(--default-box-shadow-hover);
      }

      &:nth-child(even) {
        flex-direction: row-reverse;

        .service-img {
          margin-left: 15px;
        }
      }

      &:nth-child(odd) {
        .service-img {
          margin-right: 15px;
        }
      }
    }

    &-title {
      font-style: italic;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 10px;
      @include colorDefaultToMain;
    }

    &-desc {
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;

      a {
        color: var(--default-font-color);
      }
    }
  }
}

.inner-services-list {
  .service-item {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.post-item {
  box-shadow: var(--default-box-shadow);
  transition: box-shadow 0.3s;
  box-shadow: var(--default-box-shadow);

  &:hover {
    box-shadow: var(--default-box-shadow-hover);

    .post-item {
      &__img {
        img {
          transform: scale(1.1);
        }
      }

      &__title {
        a {
          color: var(--main-color);
        }
      }
    }
  }

  &__img {
    position: relative;
    overflow: hidden;
    padding-top: 115px;
    display: block;

    @include for-xs {
      padding-top: 55%;
    }

    img {
      transition: var(--img-scale-transition);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: none;
      object-fit: cover;
      object-position: top;

      @include for-xs {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &__title {
    padding: 15px;
    font-weight: 700;
    font-size: 15px;
    display: block;
    color: var(--default-font-color);
    @include colorDefaultToMain;
    @include dec-res;
    background: var(--white);

    a {
      @include colorDefaultToMain;
      @include dec-res;
    }
  }
}

.posts-group {
  margin-bottom: var(--blocks-margin-size);

  @include for-xs {
    flex-direction: column;
  }

  .post-item {
    &:not(:last-child) {
      margin-bottom: var(--blocks-margin-size);
    }

    &.__large {
      margin-bottom: 0;

      min-height: 100%;
      position: relative;

      @include for-xs {
        margin-bottom: var(--blocks-margin-size);
      }

      .post-item__title {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        @include for-xs {
          position: relative;
        }
      }

      .post-item__img {
        padding-top: 53%;
        min-height: 100%;

        @include for-xs {
          min-height: 0;
          padding-top: 55%;
        }
      }
    }
  }
}

.posts-group-2 {
  .post {
    &-item {
      position: relative;
      width: calc(50% - var(--blocks-padding-size) / 2);

      @include for-xs {
        width: 100%;
      }

      &__img {
        padding-top: 180px;

        @include for-xs {
          padding-top: 135px;
        }
      }

      &__title {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 28%;
        padding: 17px 24px;
        font-size: 20px;
        font-style: italic;

        @include for-xs {
          font-size: 17px;
          position: relative;
          right: auto;
          line-height: 20px;
        }
      }

      &:not(.__large) {
        .post-item {
          &__title {
            right: 0;
          }
        }
      }

      &.__large {
        width: 100%;
        margin-bottom: var(--blocks-padding-size);

        .post {
          &-item {
            &__img {
              padding-top: 400px;

              @include for-xs {
                padding-top: 135px;
              }
            }

            &__title {
              right: auto;
            }
          }
        }
      }
    }
  }
}

.posts-horizontal-list {
  @include for-xs {
    flex-direction: column;
  }

  .grouped-posts {
    @include for-xs {
      flex-direction: column;
    }

    .post-item {
      @include for-xs {
        margin-bottom: var(--blocks-margin-size);
      }

      &:first-child {
        width: 55%;

        @include for-xs {
          width: 100%;
        }
      }

      &:nth-child(2) {
        margin-left: var(--blocks-margin-size);
        width: calc(45% - 20px);

        @include for-xs {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }

  &.mob-carousel-posts {
    .post-item {
      width: 35%;

      &:nth-child(2) {
        flex: 1;
        margin: 0 var(--blocks-margin-size);
      }

      @include for-xs {
        width: 100% !important;
        margin: 0 auto var(--blocks-margin-size) !important;
        max-width: 320px;
      }
    }
  }
}

.grouped-posts {
  width: 65%;
  margin-right: var(--blocks-margin-size);
  min-width: 424px;

  @include for-md639 {
    min-width: 320px;
    width: 320px;
  }

  @include for-xs {
    width: 100%;
    min-width: 0;
  }
}

.articles {
  @include for-tablet {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .article-item {
      width: calc(50% - var(--blocks-margin-size));
    }
  }

  @include for-xs {
    .article-item {
      width: 100%;
    }
  }
}

.services-list {
  .article-item {
    &:nth-child(even) {
      flex-direction: row;

      @include for-tablet {
        flex-direction: row;
      }
    }
  }
}

.article-item {
  line-height: 1.3;

  @include for-tablet {
    align-items: flex-start !important;
  }

  &:hover {
    .article-item {
      &__img {
        border-radius: 0 20px 0 20px;
        box-shadow: var(--default-box-shadow-hover);
      }

      &__title {
        color: var(--main-color);
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: var(--blocks-margin-size);
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    @include for-tablet {
      flex-direction: row;
    }
  }

  &__img {
    display: block;
    max-width: 160px;
    box-shadow: var(--default-box-shadow);
    overflow: hidden;
    transition: border-radius 0.4s, box-shadow 0.4s ease-out;
    height: 140px;
    width: 42%;
    position: relative;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      max-width: none;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__details {
    width: calc(58% - var(--blocks-margin-size));
    line-height: 1.4;

    @include for-tablet {
      width: auto;
      flex: 1;
      margin-left: var(--blocks-margin-size);
    }
  }

  &__title {
    font-weight: 700;
    font-size: var(--post-item-title-fs);
    line-height: 1.2;
    margin-bottom: 7px;
    font-style: italic;
    display: block;
    @include colorDefaultToMain;
    @include dec-res;
  }

  &__date {
    color: var(--gray-color);
    margin-bottom: 7px;
  }

  &__state {
    color: var(--main-color);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 17px;
    margin: 8px 0;
  }
}

.ad-block {
  box-shadow: var(--default-box-shadow);
  overflow: hidden;
  position: relative;

  // :not(.h-auto) {
  //   height: 150px !important;
  //   overflow: hidden;
  // }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    &:not(.__mob) {
      @include for-md {
        display: none;
      }
    }
    &.__mob {
      display: none;
      @include for-md {
        display: block;
      }
    }
  }
}

.color-posts-section {
  position: relative;
  padding: 65px 0 calc(70px - var(--blocks-margin-size));

  @include for-tablet {
    background: var(--dark-bg);
    margin-left: calc(var(--container-tablet-padding) - ((var(--container-tablet-padding) * 2)));
    margin-right: calc(var(--container-tablet-padding) - ((var(--container-tablet-padding) * 2)));
    padding: 55px var(--container-tablet-padding) 35px;
  }

  @include for-md639 {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .sub-title-1 {
    color: var(--white);
  }

  &::before,
  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    content: "";
    display: block;
    background: var(--dark-bg);

    @include for-tablet {
      display: none;
    }
  }

  &::before {
    right: 100%;
    width: 50vw;
  }

  &::after {
    width: calc(100% - var(--sidebar-width));
  }

  &.__full {
    padding-top: 45px;
    padding-bottom: 45px;

    &::after {
      width: auto;
      right: -40px;
      left: 0;
    }

    .title-1 {
      color: var(--white);
      border-color: var(--white);
    }

    .posts-grid {
      .post-item {
        &:hover {
          a {
            color: var(--main-color-light);
          }
        }

        &__title {
          background: none;

          a {
            color: var(--white);
          }
        }
      }
    }
  }

  > * {
    position: relative;
    z-index: 1;
  }

  .posts-group {
    @include for-tablet {
      flex-direction: column;

      > div {
        display: flex;
        justify-content: space-between;

        @include for-xs {
          flex-direction: column;
        }

        .post-item {
          width: calc(50% - 10px);
          margin-bottom: 0;

          @include for-xs {
            width: 100%;
            margin-bottom: var(--blocks-margin-size);
          }
        }
      }
    }

    @include for-xs {
      margin-bottom: 0;
    }
  }

  .post-item {
    width: 310px;
    position: relative;

    &.__large {
      width: auto;
      flex: 1;
      margin-right: var(--blocks-margin-size);

      @include for-tablet {
        margin-bottom: 30px;
        margin-right: 0;
      }

      @include for-xs {
        margin-bottom: var(--blocks-margin-size);
      }

      .post-item {
        &__img {
          height: 100%;

          @include for-tablet {
            height: 400px;
            padding-top: 0;

            img {
              min-height: 100%;
            }
          }

          @include for-xs {
            height: 240px;
          }
        }

        &__title {
          right: 50%;
        }
      }
    }

    &.__medium {
      width: auto;
      margin-right: 0;
      margin-left: calc((var(--sidebar-left-margin) - (var(--sidebar-left-margin) * 2)) + var(--blocks-margin-size));
      height: calc(100% - var(--blocks-margin-size));

      &:hover {
        .post-item {
          &__img {
            img {
              transform: translate(-10%, 0) scale(1.1);
            }
          }
        }
      }

      @include for-tablet {
        margin-left: 0;
        height: 400px;

        &:hover {
          .post-item {
            &__img {
              img {
                transform: scale(1.1);
              }
            }
          }
        }
      }

      @include for-xs {
        height: 240px;
      }

      .post-item {
        &__img {
          height: 100%;
          background-position: center;

          img {
            display: block;
            min-height: 100%;
          }
        }

        &__title {
          right: 40%;
          max-width: 250px;

          @include for-xs {
            right: 0;
          }
        }
      }
    }

    &__img {
      padding-top: 200px;
    }

    &__title {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}

.about {
  strong {
    color: var(--main-color);
    font-weight: 600;
  }

  p {
    margin-bottom: 25px;
  }

  img {
    height: auto;
    width: auto;

    @include for-tablet {
      width: 100%;
    }
  }

  .flex-tablet-column-reverse {
    margin-bottom: 0 !important;

    .about-right {
      margin-bottom: var(--blocks-margin-size);
    }

    .about-left {
      margin-bottom: 0;
    }
  }

  &-left {
    width: 550px;

    @include for-tablet {
      width: auto;
      margin-bottom: var(--blocks-margin-size);
    }
  }

  &-right {
    position: relative;
    width: 640px;

    @include for-tablet {
      width: auto;
    }

    > div {
      position: absolute;
      top: 30px;
      max-width: 270px;
      right: 30px;
      font-weight: 700;
      font-size: 16px;
      color: var(--secondary-font-color);

      @include for-xs {
        display: none;
      }
    }
  }
}

.footer {
  background: var(--dark-bg);
  color: var(--white);

  &-logo {
    margin-left: -8px;
    max-width: 250px;

    @include for-xs {
      margin-left: 0;
    }
  }

  .social-nets {
    a {
      background: var(--white);
      color: var(--dark-bg);

      &::after {
        background-color: var(--body-color);
      }

      &:hover {
        color: var(--main-color-light);
      }
    }
  }

  &-top {
    padding: 110px 0;

    @include for-tablet {
      padding: 75px 0;
    }

    @include for-xs {
      position: relative;
      padding: 50px 0 115px;
    }

    &__left {
      width: 250px;

      @include for-tablet {
        width: auto;
        display: flex;
        justify-content: space-between;
      }

      @include for-xs {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  &-social-nets {
    @include for-tablet {
      padding-top: 10px;
    }

    @include for-xs {
      position: absolute;
      bottom: 50px;
      display: flex;
      left: 0;
      right: 0;
      padding: 0 15px;
      justify-content: center;

      a {
        margin: 0 7px;
      }
    }
  }

  &-nav {
    padding-left: 200px;

    @include for-tablet {
      padding-left: 0;
    }

    ul {
      @include reset-list;

      a {
        color: var(--white);
      }
    }

    .icon {
      display: none;

      @include for-xs {
        display: inline-block;
        color: var(--owl-nav-btn-color);
        width: 10px;
        transition: transform 0.4s;
      }
    }

    > ul {
      @include for-tablet {
        justify-content: space-between;
      }

      > li {
        width: 25%;
        padding-right: var(--blocks-margin-size);

        @include for-tablet {
          width: calc(25% - var(--blocks-margin-size));
          padding-right: 0;
        }

        @include for-xs {
          width: 100%;
          padding-right: 0;

          > a {
            border-bottom: 2px solid #464646;
            transition: $border-color 0.4s;

            &.active {
              border-bottom-color: transparent;

              .icon {
                transform: rotate(180deg);
              }
            }
          }
        }

        > a {
          font-weight: 700;
          font-size: 16px;
          display: inline-block;
          margin-bottom: var(--blocks-margin-size);

          @include for-xs {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0;
            margin-bottom: 0;
            @include dec-res;
          }
        }
      }

      ul {
        @include for-xs {
          display: none;
        }

        li {
          margin-bottom: 10px;
        }
      }
    }
  }

  &-bottom {
    background: var(--dark-bg-2);
    padding: 12px 0;
    color: var(--dark-gray-color);

    br {
      display: none;

      @include for-xs {
        display: block;
      }
    }

    address {
      margin: 0 !important;

      @include for-xs {
        display: block !important;
      }
    }

    a {
      color: var(--dark-gray-color);
      transition: color 0.4s;

      @include for-xs {
        margin: 0 !important;
      }

      &:hover {
        color: var(--main-color-light);
      }
    }
  }

  .block-mod {
    color: black;
  }
}

.default-modal {
  .modal-content {
    background: var(--body-color);
    border: none;
    border-radius: 0;
  }

  .modal-header {
    padding: 0;
    border: none;
  }

  .close {
    position: absolute;
    left: calc(100% + 5px);
    bottom: calc(100% + 19px);
    color: var(--white);
    opacity: 1;
    padding: 0;
    height: 14px;

    @include for-md639 {
      left: calc(100% - 20px);
      bottom: calc(100% + 25px);
    }

    .icon-close {
      width: 14px;
      height: 14px;
      vertical-align: top;
    }
  }

  &.__w484 {
    .modal-dialog {
      max-width: 484px;
    }
  }

  &.__w650 {
    .modal-dialog {
      max-width: 650px;
    }
  }

  .modal-dialog {
    margin: 0 auto;
    padding: 2rem 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.modal-backdrop.show {
  opacity: 0.68;
}

.default-tabs {
  border-bottom: none;

  .nav-link {
    background: var(--dark-bg);
    transition: background-color 0.4s, color 0.3s;
    border-radius: 0 !important;
    border: none !important;
    font-weight: 700;
    font-size: 20px;
    color: var(--white);
    font-style: italic;
    text-align: center;
    padding: 25px 0;

    &.active {
      color: var(--default-font-color);
      background: var(--body-color);
    }
  }
}

.auth-nets {
  margin-bottom: var(--blocks-margin-size);

  a {
    width: 75px;
    height: 50px;
    color: var(--white);

    &.twitter {
      background: #55ace3;

      svg {
        width: 24px;
        height: 19px;
      }
    }

    &.fb {
      background: #4e6297;

      svg {
        width: 21px;
        height: 21px;
      }
    }

    &.vk {
      background: #7294c7;

      svg {
        width: 26px;
        height: 15px;
      }
    }

    &.google {
      background: #c5331e;

      svg {
        width: 23px;
        height: 23px;
      }
    }
  }
}

.auth-hr {
  overflow: hidden;
  margin-bottom: var(--blocks-margin-size);

  span {
    &::before,
    &::after {
      content: "";
      height: 2px;
      width: 350px;
      position: absolute;
      top: calc(50% - 1px);
      background: var(--border-color);
    }

    &::before {
      right: calc(100% + 10px);
    }

    &::after {
      left: calc(100% + 10px);
    }
  }
}

input,
textarea {
  &.form-control {
    border: 1px solid var(--border-color);
    border-radius: 0;
  }
}

input {
  &.form-control {
    height: 50px;
  }
}

.auth-modal {
  .custom-control-label,
  .control-label {
    line-height: 1.1;
  }
}

.help-block {
  margin-bottom: 0;
}

.help-text {
  display: none;

  @include for-xs {
    display: block;
    font-size: 12px;
  }
}

.to-top {
  position: fixed;
  width: 60px;
  height: 60px;
  background: #787878;
  color: var(--white) !important;
  cursor: pointer;
  bottom: 70px;
  right: 15px;
  border: none;
  border-radius: 50%;
  transition: opacity 0.4s;
  opacity: 0;
  z-index: -1;

  @include for-xs {
    bottom: 45px;
  }

  &.active {
    opacity: 0.6;
    z-index: 19;

    &:hover:not(:active) {
      opacity: 1;
    }
  }

  .icon {
    width: 18px;
  }
}

.profile {
  &-wrap {
    @include for-tablet {
      flex-direction: column;
    }
  }

  &-sidebar {
    flex: 0 0 310px;
    margin-right: var(--blocks-margin-size);
    background: var(--dark-bg);
    position: relative;
    padding: 70px 60px 70px 0;
    box-shadow: var(--default-box-shadow);
    border-radius: 2px;

    @include for-tablet {
      width: auto;
      padding: 40px;
      margin: 0
        calc(var(--container-tablet-padding) - (var(--container-tablet-padding) * 2) + var(--blocks-margin-size) + 4px)
        8px;
      font-weight: normal;
      flex: 1;
    }

    @include for-md639 {
      margin: 0 -15px 8px;
      padding: 30px 0 10px 0;
    }

    &::before {
      width: 100px;
      content: "";
      top: 0;
      bottom: 0;
      right: 100%;
      position: absolute;
      background: var(--dark-bg);

      @include for-tablet {
        display: none;
      }
    }

    ul {
      @include reset-list;

      @include for-tablet {
        display: flex;
        justify-content: space-between;
      }

      @include for-md639 {
        flex-wrap: wrap;
      }

      li {
        margin-bottom: var(--blocks-padding-size);

        @include for-tablet {
          margin-bottom: 0;
        }

        @include for-md639 {
          width: 50%;
          text-align: center;
          margin-bottom: var(--blocks-margin-size);
        }

        a {
          display: inline-block;
          border-bottom: 2px solid transparent;
          transition: color 0.4s;
          color: var(--white);
          font-size: 20px;
          font-weight: 700;
          font-style: italic;
          @include dec-res;
          padding-bottom: 5px;
          display: inline-block;

          @include for-tablet {
            height: 100%;
            font-size: 16px;
            margin: 0 var(--blocks-margin-size);
            text-align: center;
            font-style: normal;
            line-height: 1.2;
          }

          &:hover,
          &.active {
            color: var(--red-in-black);
          }

          &.active {
            border-color: var(--red-in-black);
          }
        }
      }
    }
  }

  &-content {
    position: relative;
    padding: 70px 0 70px 110px;
    border-radius: 2px;
    max-width: calc(100% - 310px + var(--blocks-margin-size));

    @include for-md1279 {
      padding-left: 80px;
    }

    @include for-tablet {
      margin: 0
        calc(var(--container-tablet-padding) - (var(--container-tablet-padding) * 2) + var(--blocks-margin-size) + 4px);
      max-width: none;
      background: var(--white);
      box-shadow: var(--default-box-shadow);
      padding-left: 40px;
      padding-right: 40px;
    }

    @include for-md {
      padding: 50px 40px;
    }

    @include for-md639 {
      margin: 0 -15px;
    }

    @include for-xs {
      padding: 50px 15px;
    }

    > * {
      position: relative;
      z-index: 1;
    }

    &::after {
      position: absolute;
      background: var(--white);
      box-shadow: var(--default-box-shadow);
      top: 0;
      left: 0;
      bottom: 0;
      right: -110px;
      content: "";
      z-index: 0;

      @include for-tablet {
        display: none;
      }
    }

    .section {
      margin-bottom: 50px;

      &:last-child {
        margin-bottom: 0;

        .sub-title-1 {
          margin-bottom: 25px;
        }
      }
    }
  }

  &-header {
    margin-bottom: 60px;

    @include for-xs {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin-bottom: 30px;
    }

    &__img {
      margin-right: 40px;
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      width: 130px;
      height: 130px;

      @include for-xs {
        margin-right: 0;
        margin-bottom: var(--blocks-padding-size);
      }

      a {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        flex-direction: column;
        color: var(--white) !important;
        @include dec-res;
        font-size: 12px;
        transition: opacity 0.4s;
        opacity: 0;
        border-radius: 50%;

        &:hover {
          opacity: 1;
        }

        .icon {
          width: 18px;
          height: 18px;
          margin-bottom: 5px;
        }
      }
    }

    &__header {
      .sub-title-1 {
        margin-bottom: 12px;
      }

      .rating {
        margin-bottom: 14px;
      }

      a {
        color: var(--gray-color2);
        transition: color 0.3s;

        &:hover {
          color: var(--main-color);
        }
      }
    }
  }

  &-data {
    @include for-md639 {
      flex-direction: column;
    }

    h4 {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: var(--main-color);
      margin-bottom: 15px;
    }

    &__details {
      width: 420px;
      margin-right: var(--blocks-margin-size);
      font-size: 16px;

      @include for-md1279 {
        width: 370px;
      }

      @include for-tablet {
        width: 360px;
      }

      @include for-md639 {
        width: auto;
        margin-right: 0;
        margin-bottom: var(--blocks-margin-size);
      }

      div {
        margin-bottom: 12px;

        span:first-child {
          width: calc(50% - 10px);
          margin-right: var(--blocks-margin-size);
          color: var(--labels-color);
        }
      }
    }

    &__form {
      max-width: 320px;

      @include for-md {
        max-width: none;
        flex: 1;
      }

      .form-group {
        margin-bottom: var(--blocks-padding-size);
        position: relative;

        button {
          position: absolute;
          border: none;
          background: none;
          color: var(--border-color);
          top: calc(50% - 6.5px);
          right: 0;
          padding: 0;
          height: 13px;
          cursor: pointer;
          transition: color 0.4s;

          &:hover,
          &.active {
            color: var(--black);
          }

          .icon {
            width: 19px;
            height: 13px;
            vertical-align: top;
          }
        }
      }

      .form-control {
        height: 26px;
        padding: 5px 0;
        background: var(--white);
        color: #888888;
        transition: border-color 0.4s;

        border: {
          top: none;
          left: none;
          right: none;
          bottom: 2px solid var(--border-color);
        }

        &:focus {
          box-shadow: none !important;
          border-bottom-color: var(--black);
        }
      }

      .btn-main {
        padding: 10px 35px;
      }
    }
  }
}

.rating {
  color: var(--main-color);
  white-space: nowrap;
  display: flex;
  position: relative;

  &.vote-rating {
    color: var(--border-color);

    &:hover {
      .active-stars {
        z-index: 0;
      }
    }

    .icon {
      transition: color 0.4s;
    }

    &.__isVoted {
      pointer-events: none;
    }

    span {
      cursor: pointer;

      &:hover,
      &.active,
      &.hover {
        color: var(--main-color);
      }
    }
  }

  span {
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 1;

    &:not(:last-child) {
      margin-right: 4px;
    }

    &.inner-span {
      margin: 0;
      display: block;
    }
  }

  .active-stars {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    z-index: 2;
  }

  .icon {
    width: 24px;
    height: 24px;
    line-height: 24px;
    vertical-align: top;

    &.icon-star-o {
      color: var(--white);
      stroke: var(--main-color);
    }
  }
}

.review-modal {
  .modal-header {
    padding-top: 60px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: var(--blocks-margin-size);
  }

  .modal-body {
    padding: 0 60px 60px;

    @include for-md639 {
      padding: 0 30px 30px;
    }

    @include for-xs {
      padding: 0 15px 15px;
    }
  }

  .close {
    left: auto;
    bottom: auto;
    color: var(--default-font-color);
    top: 30px;
    right: 30px;
  }

  .form-group {
    display: flex;

    @include for-xs {
      flex-direction: column;
    }

    label {
      width: 140px;
      font-weight: 700;
      color: var(--default-font-color);
      font-size: 16px;
    }

    > div {
      flex: 1;
    }

    textarea {
      max-width: 330px;
      padding: var(--blocks-padding-size) var(--blocks-margin-size);
    }
  }
}

.owl-theme {
  .owl-dots {
    .owl-dot {
      vertical-align: middle;

      span {
        width: 9px;
        height: 9px;
        margin: 0 3.5px;
        background: #c7c7c7;
      }

      &.active {
        span {
          background: var(--main-color);
          width: 11px;
          height: 11px;
        }
      }
    }

    @include for-xs {
      display: block !important;
      margin-top: 20px;
    }
  }
}

.my-services {
  margin: 0 -10px;

  &.owl-carousel {
    margin: 0;
  }

  .owl-nav,
  .owl-dots {
    display: none;
  }

  .owl-nav {
    @include for-tablet {
      position: absolute;
      left: -28px;
      right: -28px;
      margin: 0;
      font-size: 44px;
      line-height: 22px;
      top: calc(50% - 13px);
      display: flex;
      justify-content: space-between;

      button {
        padding: 0;
        margin: 0 !important;
        border: none;
        color: var(--owl-nav-btn-color) !important;
        background: none !important;

        span {
          display: block;
        }
      }
    }
  }

  .owl-stage {
    display: flex;
    padding: 5px;

    &-outer {
      margin: 0 -5px;
      position: relative;
      z-index: 2;
    }
  }

  .service {
    &-item {
      box-shadow: var(--default-box-shadow);
      transition: box-shadow 0.4s;
      width: 200px;
      margin: 0 10px var(--blocks-margin-size);
      max-width: 100%;

      .btn-main {
        font-size: 15px;
        padding: 16px 30px;
        line-height: 1;

        @include for-xs {
          width: 100%;
          text-align: center;
        }
      }

      &-cont {
        margin: 0 10px var(--blocks-margin-size);

        @include for-xs {
          width: 100%;
        }

        .service {
          &-item {
            margin: 0;
            min-height: calc(100% - 38px);

            @include for-xs {
              width: 100%;
            }
          }
        }
      }

      @include for-xs {
        width: 280px;
      }

      &.__reset-bg {
        background: none;
        box-shadow: none !important;
        margin-bottom: 0 !important;
      }

      &:hover {
        box-shadow: var(--default-box-shadow-hover);
      }
    }

    &-img {
      display: block;
      width: 100%;
      height: 105px;
      object-fit: cover;
      object-position: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &-details {
      padding: var(--blocks-padding-size);

      p {
        color: var(--main-color);
        line-height: 22px;
        margin: 0;
      }
    }

    &-title {
      font-weight: 700;
      padding-right: 10px;
      @include colorDefaultToMain;
      line-height: 1.3;
      font-size: 15px;
    }

    &-price {
      color: var(--main-color);
      font-size: 16px;
      white-space: nowrap;
    }
  }

  &.owl-carousel {
    .service {
      &-item {
        height: 100%;
        margin: 0;
      }
    }
  }
}

.breadcrumb {
  margin-bottom: 50px;

  @include for-tablet {
    margin-bottom: 44px;
  }

  @include for-xs {
    margin-bottom: 25px;
  }

  &-item {
    &.active {
      color: var(--border-color) !important;
    }

    a {
      font-size: 16px;
      color: var(--default-font-color);
      @include for-md {
        font-size: 14px;
      }
    }
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "—";
  color: var(--default-font-color);
  font-size: 16px;
}

.inner-content {
  &__body {
    flex: 1;
  }

  &__sidebar {
    flex: 0 0 340px;
    margin-left: 100px;
  }
}
.table-of-contents {
  background: #f2f5f9;
  margin-bottom: 30px;
  &__header {
    padding: 15px 30px;
    font-weight: 700;
    font-size: 1.1em;
    span {
      cursor: pointer;
      &::after {
        content: "﹀";
        margin-left: 12px;
        line-height: 1;
        font-size: 14px;
        display: inline-block;
        height: 8px;
      }
    }
    &.active {
      span {
        &::after {
          content: "︿";
        }
      }
    }
  }
  ol {
    margin: 0 30px 0;
    padding: 0 0 15px 0;
    list-style: none;
    display: block;
    li {
      padding-left: 0;
      margin-bottom: 0;
      &::before {
        display: inline-block;
        content: counter(point) ". ";
        opacity: 0.5;
        margin-right: 5px;
        margin-left: 0;
      }
      &.level-1 {
        counter-increment: point;
        counter-reset: sublist;
        margin-top: 0.4em;
        margin-bottom: 0.2em;
        &::before {
          width: 1.5em;
        }
      }
      &.level-2 {
        counter-reset: sublist3;
        margin-top: 0.3em;
        margin-bottom: 0.3em;
        margin-left: 30px;
        font-size: 0.92em;
        &::before {
          content: counter(point) "." counter(sublist) ". ";
          counter-increment: sublist;
        }
      }
    }
  }
}
.article {
  font-size: 16px;
  line-height: 1.7;
  blockquote {
    position: relative;
    margin-bottom: 1.5em;
    &:not(.__accent-block):not(.__check-block) {
      font-style: italic;
      padding: 0.5em 3em;

      &::before {
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        background-image: url(../images/quote.png);
        content: "";
        left: 0;
        top: 0;
      }
      &::after {
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        background-image: url(../images/quote.png);
        background-position: -20px 0;
        content: "";
        right: 0;
        bottom: 0;
      }
    }
    &.__accent-block,
    &.__check-block {
      ul,
      ol {
        list-style: none;
        margin: 25px 0;
        padding: 0;
        li {
          &::before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            border: 2px solid #4d3bfe;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            margin-right: 1.3em;
          }
        }
      }
    }
    &.__accent-block {
      background: #e3f1f4;
      background: -webkit-gradient(linear, left top, right top, from(#e3f1f4), to(#e3edff));
      background: -webkit-linear-gradient(left, #e3f1f4 0, #e3edff 100%);
      background: -o-linear-gradient(left, #e3f1f4 0, #e3edff 100%);
      background: linear-gradient(to right, #e3f1f4 0, #e3edff 100%);
      padding: 20px 30px 20px 70px;
      &::before {
        content: "ℹ";
        color: #3da2e0;
        border: 3px solid #3da2e0;
        border-radius: 50%;
        position: absolute;
        width: 30px;
        height: 30px;
        top: calc(50% - 15px);
        left: 20px;
        font-size: 24px;
        line-height: 26px;
        text-align: center;
      }
    }
    &.__check-block {
      background: #def9e5;
      background: -webkit-gradient(linear, left top, right top, from(#def9e5), to(#effbce));
      background: -webkit-linear-gradient(left, #def9e5 0, #effbce 100%);
      background: -o-linear-gradient(left, #def9e5 0, #effbce 100%);
      background: linear-gradient(to right, #def9e5 0, #effbce 100%);
      padding: 20px 20px 20px 65px !important;
      &::before {
        background: url(../images/ok.png) no-repeat center center;
        width: 30px;
        height: 30px;
        top: calc(50% - 15px);
        left: 20px;
        content: "";
        position: absolute;
      }
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    font-style: normal;
  }

  h1 {
    font-weight: 700;
    font-size: 2.43em;
    margin-bottom: 0.8em;
    @include for-md {
      font-size: 1.625em;
    }
  }

  img {
    height: auto;
  }

  strong,
  b {
    color: var(--main-color);
    font-weight: 700;
  }

  span.words {
    display: inline-block;
  }

  p {
    margin: 0 0 25px;

    @include for-xs {
      margin: 0 0 20px;
    }
  }

  .ad-block {
    margin: 60px 0;

    @include for-xs {
      margin: 45px 0;
    }
  }

  h2 {
    font-size: 1.9375em;
    margin-bottom: 0.77419355em;
  }

  h3 {
    font-size: 1.5375em;
    margin: 0 0 25px;
  }

  h4 {
    font-size: 1.2375em;

    margin: 0 0 25px;
  }

  &-header {
    position: relative;
    margin-bottom: 30px;

    .__cat {
      display: inline-block;
      padding: 0.3em 1.4em;
      margin-bottom: 1.2em;
      background: var(--main-color);
      color: #fff;
      -webkit-border-radius: 1em;
      border-radius: 1em;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 0.7em;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      position: absolute;
      bottom: 15px;
      left: 15px;
      max-width: calc(100% - 30px);
    }

    img {
      width: 100%;
      display: block;
      box-shadow: var(--default-box-shadow);
    }

    h2 {
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 80%;
      background: var(--white);
      box-shadow: var(--default-box-shadow);
      font-size: 25px;
      font-weight: 700;
      margin: 0;
      font-style: italic;
      padding: 23px 48px;

      @include for-xs {
        font-size: 17px;
        padding: 18px 23px;
        position: relative;
        width: 100%;
        max-width: none;
      }
    }
  }

  &-footer {
    margin: 40px 0;

    @include for-xs {
      flex-direction: column;
      align-items: flex-start !important;
    }

    .rating {
      @include for-xs {
        margin-bottom: 30px;
      }
    }

    h5 {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 8px;
    }
    .social-nets {
      a {
        background: rgba(0, 0, 0, 0.04);
        width: calc(100% / 7);
        height: 45px;
        border-radius: 0;
        margin: 0 !important;
        color: var(--main-color);
        transition: all 0.3s;
        &::after {
          display: none;
        }
        &:hover {
          color: var(--white);
          background: var(--main-color);
        }
        .icon {
          font-size: 1.2em !important;
          max-width: none;
        }
      }
    }
  }

  &-meta {
    border-bottom: 3px solid #f2f2f2;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 30px;
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1.4;
    > * {
      margin-right: 20px;
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
      @include for-md {
        max-width: calc(50% - 20px);
      }
      i.fa {
        margin-right: 15px;
        font-size: 2em;
        color: var(--main-color);
      }
      span {
        font-weight: 400;
        color: #666;
        display: block;
        font-size: 0.8em;
        line-height: 1;
        text-transform: uppercase;
      }
    }
  }
}

.post-img-table {
  border: none;
  margin: 25px 0 !important;
  display: block;

  &.__margin-reset {
    td {
      margin-bottom: 0 !important;
      padding-top: 0 !important;
    }
  }

  tbody {
    display: block;
    width: 100%;
  }

  tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @include for-xs {
      flex-direction: column;
    }

    &:first-child {
      td {
        &:first-child {
          margin-bottom: -140px;

          @include for-tablet {
            margin-bottom: 0;
          }
        }
      }
    }

    &:nth-child(2) {
      td {
        &:first-child {
          padding-top: 140px;

          @include for-tablet {
            padding-top: 0;
          }
        }
      }
    }
  }

  td {
    width: calc(50% - 7.5px) !important;

    @include for-xs {
      width: 100% !important;
      padding: 15px 0 !important;
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.post-imgs-list-table {
  display: block;
  margin: 25px 0;

  @include for-xs {
    display: none;
  }

  tbody {
    display: block;
    width: 100%;
  }

  tr {
    display: flex;
    justify-content: space-between;
  }

  td {
    width: calc(33% - 12.5px) !important;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

figure.image {
  display: flex;
  align-items: center;
  margin: 25px 0;

  @include for-tablet {
    align-items: flex-start;
  }

  @include for-xs {
    flex-direction: column;
  }

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    &.mr-2 {
      margin-right: var(--blocks-margin-size) !important;
    }

    &.ml-2 {
      margin-left: var(--blocks-margin-size) !important;
    }

    @include for-xs {
      &.mr-2,
      &.ml-2 {
        margin: 0 auto var(--blocks-margin-size) !important;
      }
    }
  }
}

.inner-posts {
  justify-content: space-between;
  margin-bottom: 40px;

  @include for-xs {
    margin-bottom: 30px;
  }

  @include for-xs {
    flex-direction: column;
  }

  .post-item {
    width: calc(50% - 10px);

    @include for-xs {
      width: 100%;
      margin-bottom: var(--blocks-margin-size);

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__img {
      padding-top: 210px;

      @include for-xs {
        padding-top: 160px;
      }
    }
  }
}

.has-bg {
  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }
}

.blog-content-bg {
  position: absolute;
  right: calc(var(--sidebar-width));
  top: 0;
  z-index: 0;

  @include for-tablet {
    width: 208px;
    right: -30px;
  }
}

.answer {
  font-size: 16px;

  &-header {
    position: relative;
    box-shadow: var(--default-box-shadow);

    &__details {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 28px 48px;
      background: var(--white);

      @include for-tablet {
        position: relative;
        padding: 25px 40px;
      }

      @include for-xs {
        padding: 23px 36px;
        flex-direction: column;
        text-align: center;

        .btn-main {
          margin-top: var(--blocks-margin-size);
        }
      }

      p {
        color: var(--main-color);
      }
    }
  }

  &-arguments {
    @include for-tablet {
      align-items: flex-start !important;
      margin-bottom: 40px;
    }

    @include for-xs {
      flex-direction: column;
    }

    &__img {
      flex: 0 0 310px;
      display: block;
      margin-right: var(--blocks-margin-size);
      position: relative;
      padding-top: 280px;

      @include for-tablet {
        flex: 0 0 200px;
        padding-top: 226px;
      }

      @include for-xs {
        width: 100%;
        margin-bottom: 30px;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__details {
      h3 {
        @extend .sub-title-1;
        margin-bottom: var(--blocks-margin-size);

        @include for-xs {
          text-align: center;
        }
      }

      ul {
        @extend .list-1;
      }

      &-footer {
        @include for-xs {
          flex-direction: column-reverse;
          align-items: flex-start !important;
        }
      }

      .answer {
        &-price {
          margin-left: 30px;

          @include for-xs {
            margin-left: 0;
            margin-bottom: var(--blocks-margin-size);
          }
        }
      }
    }
  }

  &-price {
    color: var(--main-color);
    font-weight: 700;
    font-size: 20px;
    font-style: italic;
  }

  &-post-table {
    h3 {
      @extend .sub-title-1;
    }

    ul {
      @extend .list-1;
      margin-bottom: 0;
    }

    @include for-tablet {
      position: relative;
      padding-top: 40px;

      tr {
        align-items: flex-start !important;
      }

      h3 {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
      }
    }

    @include for-xs {
      padding-top: 0;

      h3 {
        position: relative;
      }

      .list-1 {
        margin-bottom: var(--blocks-margin-size) !important;
      }
    }
  }
}

.list-1 {
  @include reset-list;
  margin: var(--blocks-margin-size) 0;

  li {
    position: relative;
    padding-left: 25px;
    line-height: 1.4;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &::before {
      @extend .png-icon-mark;
      content: "";
      position: absolute;
      left: 0;
      top: 5px;
    }
  }
}

.note {
  line-height: 1.6;
  margin: 40px 0;

  @include for-xs {
    align-items: flex-start !important;
  }

  .icon-support {
    width: 75px;
    height: 75px;
    flex: 0 0 75px;
    margin-right: var(--blocks-margin-size);
    color: var(--main-color);
  }
}

.default-card {
  font-size: 16px;
  margin: 0 -48px 0;
  padding: 34px 48px;

  @include for-tablet {
    margin: 0 calc(var(--container-tablet-padding) - ((var(--container-tablet-padding) * 2)) + 15px);
  }

  @include for-xs {
    margin: 0 calc(var(--blocks-margin-size) - (var(--blocks-margin-size) * 2) + 8px);
    padding: 25px 15px;

    &.d-flex {
      flex-direction: column;
    }
  }
}

.result {
  line-height: 1.6;

  blockquote {
    display: block;
    position: relative;
    padding-left: 76px;
    margin: 25px 0;

    @include for-xs {
      padding-left: 55px;
    }

    &::before {
      content: "";
      @extend .png-icon-ex-point;
      position: absolute;
      top: 6px;
      left: 30px;

      @include for-xs {
        left: 12px;
      }
    }
  }

  &-header {
    margin-bottom: 30px;

    div {
      color: var(--main-color);

      .title-1 {
        margin: 0;
      }
    }
  }

  &-card {
    p {
      margin-bottom: 12px;
    }

    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 12px;
    }

    &.color-result-card {
      .result {
        &-tab-nav {
          margin-right: 360px;

          @include for-tablet {
            justify-content: space-between;

            .nav-link {
              margin-right: 0;
              width: calc(50% - var(--blocks-margin-size));
            }
          }

          @include for-md639 {
            margin-right: 0;

            .nav-link {
              width: auto;
              margin-right: 30px;
            }
          }

          @include for-xs {
            .nav-link {
              margin-right: 0;
              width: calc(50% - var(--blocks-margin-size));
            }
          }
        }

        &-img {
          margin-right: 0;
          float: right;
          margin-left: var(--blocks-margin-size);
          margin-top: -60px;
          width: 310px;
          height: 320px;
          border: none;
          padding: 0;
          margin-bottom: 80px;

          @include for-tablet {
            margin-top: -88px;
          }

          @include for-md639 {
            margin-top: 0;
          }

          @include for-xs {
            width: 100%;
            margin: 0 0 var(--blocks-margin-size);
            float: none;

            &.has-color-choise {
              margin-bottom: 70px;
            }
          }
        }
      }

      .tab-pane {
        position: relative;

        .hair-colors-table {
          position: absolute;
          right: 0;
          top: -42px;

          @include for-tablet {
            top: -70px;
          }

          @include for-md639 {
            top: 18px;
          }

          @include for-xs {
            width: 100%;
          }
        }
      }
    }

    &.default-box-shadow {
      background: var(--white);

      .result {
        &-tab {
          max-width: calc(100% - (310px + 20px));

          @include for-tablet {
            max-width: calc(100% - 200px);
          }

          @include for-xs {
            max-width: 100%;
          }
        }
      }
    }
  }

  &-img {
    flex: 0 0 310px;
    height: 310px;
    border: 15px solid transparent;
    position: relative;
    margin-right: 40px;
    margin-left: -15px;
    transition: border-color 0.4s;

    background: {
      repeat: no-repeat;
      position: center;
      size: cover;
    }

    @include for-tablet {
      flex: 0 0 200px;
      height: 200px;
      border-width: 12px;
      margin-right: 8px;
      margin-left: -11px;
    }

    @include for-xs {
      flex: auto;
      width: 100%;
      min-height: 248px;
      padding-top: 70%;
      margin-right: 0;
      border-width: 15px;
      margin-left: 0;
    }
  }

  &-tab {
    max-width: 100%;

    &-nav {
      padding-top: 10px;
      border-bottom: none;
      justify-content: space-between;
      margin-bottom: 20px;

      @include for-tablet {
        justify-content: flex-start;
      }

      .nav-link {
        font-size: 17px;
        font-weight: 700;
        color: var(--default-font-color);
        transition: border-color 0.4s;
        padding: 3px 0;
        margin-right: 32px;
        font-style: italic;

        &:last-child {
          margin-right: 0;
        }

        border: {
          top: none;
          left: none;
          right: none;
          bottom: 2px solid transparent;
        }

        &:hover,
        &.active {
          border-color: var(--main-color);
          color: var(--main-color);
        }
      }
    }
  }
}

.hair-colors-table {
  width: 310px;
  position: relative;
  display: block;
  overflow: hidden;
  text-indent: -9999px;
  font-size: 0;

  > tbody {
    display: block;

    > tr {
      display: block;

      > td {
        width: 100%;
        display: block;
      }

      &:first-child {
        padding-bottom: 35px;

        td {
          width: 86px;
          height: 285px;
          margin-left: var(--blocks-margin-size);
          transition: background-color 0.4s;
        }
      }

      table {
        display: block;

        tbody {
          display: block;
        }

        tr {
          display: flex;
          justify-content: space-between;

          td {
            display: block;
            width: 35px;
            height: 35px;
            @include scaleOnHover;
          }
        }
      }
    }
  }
}

.result-card-carousel {
  .owl-stage-outer {
    position: relative;
    z-index: 2;
  }

  .owl-item {
    height: 1px;
    overflow: hidden;

    &.active {
      height: auto;
      overflow: inherit;
    }
  }

  .owl-nav {
    position: absolute;
    left: -28px;
    right: -28px;
    margin: 0;
    font-size: 44px;
    line-height: 22px;
    top: calc(50% - 22px);
    display: flex;
    justify-content: space-between;

    @include for-xs {
      display: none;
    }

    button {
      padding: 0;
      margin: 0 !important;
      border: none;
      color: var(--owl-nav-btn-color) !important;
      background: none !important;

      span {
        display: block;
      }
    }
  }
}

.img-grid-table {
  margin: 25px 0;

  @include for-xs {
    tr {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  td {
    padding-right: var(--blocks-margin-size);

    @include for-xs {
      display: block;
      width: calc(50% - 10px);
      padding-right: 0;
      margin-bottom: var(--blocks-margin-size);
    }

    img {
      box-shadow: var(--default-box-shadow);
      @include scaleOnHover;

      @include for-xs {
        width: 100%;
      }
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.color-table {
  display: block;
  margin: 25px 0 7px;

  tbody {
    display: block;
  }

  tr {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -11px 0 -9px;

    @include for-xs {
      margin-left: -5px;
      margin-right: -5px;
    }
  }

  td {
    display: block;
    height: 50px;
    width: 50px;
    margin: 0 9px 18px;
    @include scaleOnHover;

    @include for-tablet {
      width: calc(25% - 18px);
    }

    @include for-xs {
      width: calc(25% - 10px);
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 10px;
    }
  }
}

.slider-table {
  border: none;
  width: 100%;

  > tbody {
    > tr {
      > td {
        padding: 0;
      }
    }
  }
}

.wig-list {
  @include reset-list;
  margin: 25px 0;
  display: block;

  .owl-stage-outer {
    z-index: 2;
  }

  .owl-dots {
    margin-top: 15px;
  }

  .owl-nav {
    position: absolute;
    left: -15px;
    right: -15px;
    margin: 0;
    font-size: 44px;
    line-height: 22px;
    top: calc(50% - 13px);
    display: flex;
    justify-content: space-between;

    @include for-tablet {
      margin-left: -30px;
      margin-right: -30px;
    }

    button {
      padding: 0;
      margin: 0 !important;
      border: none;
      color: var(--owl-nav-btn-color) !important;
      background: none !important;

      span {
        display: block;
      }
    }
  }
}

.feedback-block {
  padding: 55px;
  box-shadow: var(--default-box-shadow);
  clear: both;

  > div.d-flex {
    @include for-xs {
      margin-bottom: var(--blocks-margin-size);
    }
  }

  @include for-md639 {
    padding: 40px;
  }

  @include for-xs {
    padding: 38px 24px;
    margin-bottom: 40px;
  }

  .icon-support {
    color: var(--main-color);
    width: 50px;
    height: 50px;
    min-width: 50px;
  }

  p {
    margin: 0 50px 0 30px;
    font-style: italic;
    font-weight: 700;
    font-size: 17px;

    @include for-xs {
      margin-right: 0;
      margin-left: var(--blocks-padding-size);
    }
  }

  .btn-main {
    padding-top: 15.5px;
    padding-bottom: 15.5px;
  }
}

.makeup-table {
  width: 100%;
  display: block;

  > tbody {
    display: block;

    > tr {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;

      &:not(:last-child) {
        margin-bottom: 50px;

        @include for-xs {
          margin-bottom: var(--blocks-margin-size);
        }
      }

      > td {
        width: 200px;
        display: block;
        margin-right: 50px;

        @include for-md639 {
          margin-bottom: 20px;
          margin-right: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }

        @include for-xs {
          width: 100%;
        }

        > * {
          margin: 0;
        }

        table {
          width: 100%;
          margin-top: 10px !important;
          flex-wrap: wrap;

          tr {
            display: flex;
            justify-content: space-between;

            td {
              display: block;
              width: 50px;
              height: 50px;
              @include scaleOnHover;

              @include for-xs {
                width: calc(33.33% - 10px);
              }
            }
          }
        }
      }
    }
  }
}

.blog-categories-list {
  @include reset-list;
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
  line-height: 1.4;

  @include for-xs {
    margin: 0;
  }

  > li {
    width: calc(25% - var(--blocks-margin-size));
    margin: 0 10px var(--blocks-margin-size);

    @include for-tablet {
      width: calc(33% - var(--blocks-margin-size));
    }

    @include for-xs {
      width: 100%;
      margin: 0 0 var(--blocks-padding-size);
    }

    > a {
      font-weight: 700;
      font-style: italic;
      font-size: 17px;
      @include dec-res;
      display: inline-block;
      position: relative;
      padding-right: var(--blocks-margin-size);
      color: var(--main-color);

      @include for-xs {
        display: block;
        position: relative;
        padding-right: 15px;

        br {
          display: none;
        }
      }

      .icon {
        width: 10px;
        height: 6px;
        position: absolute;
        right: 0;
        top: 10px;
        transition: transform 0.4s;

        @include for-xs {
          position: absolute;
          right: 0;
          top: 10px;
        }
      }

      &.active {
        .icon {
          transform: rotate(180deg);
        }
      }
    }

    ul {
      @include reset-list;
      padding-top: var(--blocks-margin-size);
      display: none;

      li {
        margin-bottom: 10px;

        a {
          @include colorDefaultToMain;
        }
      }
    }
  }

  &__toggle {
    @include for-tablet {
      margin-bottom: var(--blocks-margin-size);
    }

    @include for-xs {
      margin-bottom: 50px;
    }

    a {
      @include for-xs {
        width: 100%;
        text-align: center;
      }

      span {
        &:last-child {
          display: none;
        }

        &:first-child {
          display: inline;
        }
      }

      &.active {
        span {
          &:first-child {
            display: none;
          }

          &:last-child {
            display: inline;
          }
        }
      }
    }
  }
}

.simple-post-list {
  margin: 0 -10px;

  &.flex-wrap {
    .article-item {
      margin-bottom: var(--blocks-margin-size);
    }
  }

  @include for-xs {
    flex-direction: column;
    margin: 0;
  }

  .article {
    &-item {
      width: calc(33.33% - 20px);
      margin: 0 10px;

      @include for-xs {
        width: 100%;
        margin: 0 0 var(--blocks-margin-size);
      }

      &__img {
        width: 100%;
        max-width: none;
        height: 200px;
        border-radius: 0 !important;
        margin-bottom: var(--blocks-margin-size);
      }

      &__details {
        width: auto;
        margin: 0 !important;
      }
    }
  }
}

.posts-grid {
  margin: 0 -10px;

  @include for-xs {
    margin: 0;
  }

  .post {
    &-item {
      width: calc(25% - 20px);
      margin: 0 10px;

      @include for-xs {
        width: 100%;
        margin: 0 0 var(--blocks-margin-size);
      }

      &__img {
        padding-top: 160px;
      }

      &__title {
        position: relative;
        padding: var(--blocks-padding-size) 0;
        font-style: italic;
        font-size: 17px;
        line-height: 20px;
      }
    }
  }
}

.pagination {
  margin: 50px 0;

  a {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--main-color);
    color: var(--white);

    &.disabled {
      background-color: var(--border-color);
      pointer-events: none;
    }

    .icon {
      height: 7px;
      width: 11px;
    }
  }

  &-prev {
    .icon {
      transform: rotate(90deg);
    }
  }

  &-next {
    .icon {
      transform: rotate(-90deg);
    }
  }

  &-body {
    margin: 0 var(--blocks-margin-size);
    font-size: 20px;

    span {
      color: #aeaeae;
    }
  }
}

.transformation-steps {
  margin: 45px 0;
  font-size: 14px;
  line-height: 1.6;

  @include for-tablet {
    flex-direction: column;
  }

  &__img {
    flex: 0 0 310px;
    height: 398px;
    position: relative;
    margin-right: var(--blocks-padding-size);
    overflow: hidden;

    @include for-tablet {
      flex: auto;
      width: 100%;
      margin-bottom: 40px;
      height: 287px;
      margin-right: 0;
    }

    @include for-xs {
      margin-bottom: 30px;
    }

    @include for-xs {
      height: 260px;
    }

    img {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: top;
      display: none;

      &:nth-child(1) {
        display: block;

        @include for-tablet {
          display: none;
        }
      }

      &:nth-child(2) {
        @include for-tablet {
          display: block;
        }

        @include for-xs {
          display: none;
        }
      }

      &:nth-child(3) {
        @include for-xs {
          display: block;
        }
      }
    }
  }

  &__list {
    @include reset-list;
    counter-reset: list;

    @include for-xs {
      flex-direction: column;
    }

    li {
      width: calc(50% - 10px);
      padding: 44px 0 0;
      position: relative;

      @include for-xs {
        width: 100%;
      }

      > * {
        position: relative;
        z-index: 1;
      }

      &::before {
        counter-increment: list;
        content: "0" counter(list);
        position: absolute;
        top: 10px;
        left: 0;
        font-size: 90px;
        line-height: 1;
        color: var(--main-color);
        font-style: italic;
        font-weight: 700;
        opacity: 0.2;
      }
    }
  }

  &__title {
    font-size: 20px;
    color: var(--main-color);
    font-style: italic;
    margin-bottom: 30px;
    line-height: 23px;
    font-weight: 700;
    padding-left: 55px;
  }
}

.tariffs {
  margin: 0 -10px;

  .sub-title-1 {
    margin-bottom: var(--blocks-margin-size);
  }

  @include for-tablet {
    text-align: center;
    margin: 0 0 55px;

    &-list {
      text-align: left;
      flex-direction: column;
      justify-content: center !important;
    }
  }

  @include for-xs {
    p {
      br {
        display: none;
      }
    }
  }

  .tariff {
    width: calc(33.33% - 10px);
    margin: 0 10px;
    max-width: 100%;

    @include for-tablet {
      width: 420px;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.active {
      width: calc(33.33% + 34px);
      box-shadow: var(--default-box-shadow-hover);

      @include for-tablet {
        width: 460px;
        margin-bottom: 40px;
      }

      img {
        box-shadow: none;
      }

      .tariff {
        &-details {
          padding: 26px 30px;
          background: var(--white);

          @include for-xs {
            padding: 20px 13px;
          }
        }
      }
    }
  }
}

.tariff {
  font-size: 14px;
  line-height: 1.4;

  p {
    margin-bottom: 8px;
  }

  &-img {
    position: relative;
    height: 200px;

    box-shadow: var(--default-box-shadow);

    img {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      object-position: center;
    }
  }

  &-details {
    padding: var(--blocks-padding-size) 5px;

    @include for-tablet {
      padding-left: 0;
      padding-right: 0;
    }

    &__header {
      font-weight: 700;
      font-size: 17px;
      margin-bottom: 10px;
      line-height: 20px;
      font-style: italic;
    }
  }

  &-price {
    color: var(--main-color);
  }

  .btn-main {
    padding: 15.5px 20px;
    border-width: 1px;
  }
}

.wardrobe {
  @include for-md {
    flex-direction: column;
  }

  &-body {
    width: 420px;
    max-width: 100%;

    @include for-md {
      margin: 0 auto 35px;
    }

    &__inner {
      height: 700px;
      border-radius: 4px;
      box-shadow: var(--default-box-shadow-hover);
      position: relative;

      background: {
        position: center;
        repeat: no-repeat;
        size: auto 95%;
        color: var(--white);
      }

      @include for-xs {
        height: 466px;
      }

      .placeholder {
        padding: var(--blocks-padding-size);
        display: inline-block;
        background: rgba(255, 255, 255, 0.5);
      }

      &.wedding-wardrobe,
      &.women-wardrobe {
        background: {
          image: url(../images/girl.jpg);
        }
      }

      &.men-wardrobe {
        background: {
          image: url(../images/boy.jpg);
        }
      }

      &.photo-uploaded {
        background-image: none !important;

        .wardrobe-main-img {
          display: block;
        }

        .wardrobe-upload {
          display: none !important;
        }
      }

      &.dress-uploaded {
        .dress-img {
          display: block;
          z-index: 4;
        }
      }

      &.veil-uploaded {
        .veil-img {
          display: block;
          z-index: 5;
        }
      }

      &.bouquet-uploaded {
        .bouquet-img {
          display: block;
        }
      }
    }
  }

  &-sidebar {
    flex: 1;
    max-width: calc(100% - 420px - 40px);
    margin-left: calc(var(--blocks-margin-size) * 2);
    padding-top: 10px;

    @include for-tablet {
      margin-left: var(--blocks-margin-size);
      max-width: calc(100% - 420px - var(--blocks-margin-size));
    }

    @include for-md {
      flex: 1;
      margin: 0;
      width: 100%;
      text-align: center;
      max-width: none;
    }

    .sub-title-1 {
      color: var(--main-color);
    }

    .default-tabs {
      margin-bottom: var(--blocks-padding-size);
      flex-wrap: nowrap;
      margin-bottom: 25px;

      @include for-md {
        justify-content: space-between;
      }

      .nav-link {
        background: none;
        color: var(--default-font-color);
        position: relative;
        padding: 0 0 0 25px;
        line-height: 1.2;
        font-size: 16px;
        font-style: normal;

        @include for-tablet {
          font-weight: normal;
          font-size: 16px;
        }

        span {
          height: 16px;
          width: 16px;
          border: 1px solid var(--default-font-color);
          display: block;
          position: absolute;
          top: 2px;
          left: 0;
          border-radius: 50%;
          transition: border-color 0.4s;

          &::after {
            content: "";
            height: 10px;
            width: 10px;
            position: absolute;
            left: calc(50% - 5px);
            top: calc(50% - 5px);
            background: var(--main-color);
            border-radius: 50%;
            opacity: 0;
            transition: opacity 0.4s, transform 0.4s;
            transform: scale(0.2);
          }
        }

        &.active {
          background: none;
          color: var(--main-color);

          span {
            border-color: var(--main-color);

            &::after {
              transform: scale(1);
              opacity: 1;
            }
          }
        }

        &:first-child {
          margin-right: 50px;

          @include for-tablet {
            margin-right: var(--blocks-margin-size);
          }
        }
      }
    }
  }

  &-upload {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    cursor: pointer;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    color: var(--white);
    opacity: 0;
    transition: opacity 0.4s;

    &:hover {
      opacity: 1;
    }

    .sub-title-1 {
      color: var(--white);
    }

    p {
      margin-bottom: var(--blocks-margin-size);
    }

    .btn-main {
      background: var(--white);
      color: var(--default-font-color);
      padding: 16.5px 40px;
      font-size: 15px;
      border: none;
      box-shadow: none !important;

      &:hover {
        background: var(--main-color);
        color: var(--white);
      }
    }
  }

  &-watermark {
    position: absolute;
    bottom: var(--blocks-margin-size);
    left: var(--blocks-margin-size);
    width: 180px;
    z-index: 6;
    opacity: 0.6;

    @include for-md {
      width: 140px;
    }

    @include for-xs {
      width: 90px;
    }
  }

  .resizable {
    left: 0;
    top: 0;
    z-index: 3;
    width: auto;
    display: none;
    height: 50%;

    &.bouquet-img {
      height: 20%;
    }

    &.veil-img {
      height: 40px;
    }

    &.wardrobe-main-img {
      height: 100%;

      img {
        height: auto;
      }
    }

    .resizers {
      width: auto;
    }

    img {
      max-height: 100%;
      min-width: 100%;
      height: 100%;
    }
  }

  &-footer {
    padding: var(--blocks-margin-size) 0;

    header {
      @include for-md639 {
        flex-direction: column;
      }
    }

    footer {
      @include for-xs {
        padding-top: 10px;
        text-align: center !important;
      }
    }

    .default-tabs {
      .nav-link {
        padding: 5px var(--blocks-padding-size);
        margin: 0 5px;
        background-color: var(--border-color);

        @include for-xs {
          padding: 3px 10px;
        }

        &.active {
          background: none;
          color: var(--main-color);
        }
      }
    }
  }

  &-actions {
    padding-top: var(--blocks-margin-size);

    .btn {
      padding: 14.5px 28px !important;

      @include for-md {
        width: 100%;
        text-align: center;
      }
    }

    a:not(.btn) {
      font-size: 15px;
      font-style: italic;
      border-bottom: 2px solid var(--main-color);
      @include dec-res;
      font-weight: 700;
      color: var(--main-color);
    }
  }
}

.resizable {
  cursor: grab;
}

.dresses {
  position: relative;

  @include for-tablet {
    display: none;
    padding: 0;
  }

  &.__for-tablet {
    display: none;

    @include for-tablet {
      display: block;
    }

    @include for-md {
      display: none;
    }
  }

  &.__for-mob {
    display: none;

    @include for-md {
      display: block;
    }
  }

  &.__for-mob,
  &.__for-tablet {
    .dresses {
      &-item {
        a {
          width: calc(50% - 20px);
          height: 120px;

          @include for-md {
            height: 205px;
          }
        }
      }
    }
  }

  .owl-stage-outer {
    padding: 20px;
    margin: -20px;
  }

  &-item {
    width: 100%;

    @include for-md1279 {
      margin-bottom: 7.5px;
    }

    &__inner {
      margin: 0 -10px;
    }

    a {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(33% - 20px);
      transition: border-color 0.4s;
      color: var(--main-color);
      margin: 0 10px 20px;
      height: 160px;
      box-shadow: var(--default-box-shadow);
      background: var(--white);
      transition: transform 0.4s;

      &.active {
        transform: scale(1.1);

        .remove-img {
          display: block;
        }
      }

      @include for-md {
        width: auto;
      }

      img {
        max-width: 95%;
        max-height: 95%;
        width: auto !important;
      }

      .remove-img {
        position: absolute;
        top: 8px;
        right: 8px;
        height: 10px;
        display: none;
        width: 10px;
        left: auto;
        bottom: auto;
        opacity: 1;
        color: var(--main-color);

        .icon {
          width: 10px;
          height: 10px;
          z-index: 2;
          vertical-align: top;
        }
      }
    }
  }

  > button {
    position: absolute;
    width: 100%;
    text-align: center;
    color: var(--owl-nav-btn-color);
    border: none;
    background: none;
    cursor: pointer;

    &.disabled {
      opacity: 0.4;
    }

    .icon {
      width: 20px;
      height: 20px;
    }

    &.prev-dress {
      top: 0;

      .icon {
        transform: rotate(180deg);
      }
    }
  }
}

.accessory-carousel {
  margin-bottom: 30px;

  .owl-stage-outer {
    padding: 20px 10px;
    margin: -20px -10px;
  }

  .resizeable-img {
    height: 140px;
    box-shadow: var(--default-box-shadow);
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white);
    transition: transform 0.4s;

    &.active {
      transform: scale(1.1);

      .remove-img {
        display: block;
      }
    }

    @include for-md {
      width: auto;
    }

    img {
      max-width: 95%;
      max-height: 95%;
      width: auto !important;
    }

    .remove-img {
      position: absolute;
      top: 8px;
      right: 8px;
      height: 10px;
      display: none;
      width: 10px;
      left: auto;
      bottom: auto;
      opacity: 1;
      color: var(--main-color);

      .icon {
        width: 10px;
        height: 10px;
        z-index: 2;
        vertical-align: top;
      }
    }
  }
}

.wardrobe-price {
  position: absolute;
  font-size: 20px;
  font-weight: 700;
  right: 10px;
  bottom: 10px;
  color: var(--main-color);
  background: rgba(255, 255, 255, 0.6);
}

.remove-img {
  position: absolute;
  bottom: calc(100% - 30px);
  left: calc(100% - 30px);
  @include colorDefaultToMain;
  opacity: 0;
  transition: opacity 0.4s;

  .icon {
    width: 15px;
    height: 15px;
  }

  &.__outside {
    bottom: calc(100% + 10px);
    left: calc(100% + 10px);
  }
}

.resizer {
  position: absolute;
  background: #000;
}

.resize {
  &-drag {
    touch-action: none;
  }

  &-top,
  &-bottom {
    width: 100%;
    height: 2px;
  }

  &-top {
    left: -7.5px;
    top: -7.5px;
  }

  &-bottom {
    right: -7.5px;
    bottom: -7.5px;
  }
}

.screen-reader-text {
  display: none !important;
}

.tests-list {
  .article-item {
    width: calc(50% - 20px);

    @include for-md639 {
      width: 100%;
    }

    &__price {
      position: absolute;
      top: 10px;
      right: 10px;
      color: var(--main-color-light);
      font-weight: 700;
      font-size: 17px;
      z-index: 2;
    }

    &__img {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-end;
      text-align: center;
      position: relative;

      .btn-main {
        background: rgba(255, 255, 255, 0.4);
        color: var(--main-color-light);
        position: relative;
        z-index: 2;
        margin-bottom: var(--blocks-padding-size);
      }
    }
  }
}

.lips {
  padding: 0 15px;
  margin: 0 20px;
  line-height: 40px;
  font-size: 16px;
  overflow: hidden;
  background: #fff;
  color: var(--main-color);
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  border-radius: 5px;
  border: 3px solid var(--main-color);
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.lips:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: url(../img/lips.png) 0 0 no-repeat;
  width: 27px;
  height: 15px;
  margin-right: 15px;
}

.lips:hover,
.lips.active {
  background: var(--main-color);
  color: #fff;
}

.lips:hover:before,
.lips.active:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: url(../img/lips2.png) 0 0 no-repeat;
  width: 27px;
  height: 15px;
  transition: 0.3s;
}

.usi {
  padding: 0 15px;
  margin: 0 20px;
  line-height: 40px;
  font-size: 16px;
  overflow: hidden;
  background: #fff;
  color: #000;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  border-radius: 5px;
  border: 3px solid #000;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.usi:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: url(../img/usi.png) 0 0 no-repeat;
  width: 39px;
  height: 13px;
  margin-right: 15px;
}

.usi:hover,
.usi.active {
  background: #000;
  color: #fff;
}

.usi:hover:before,
.usi.active:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: url(../img/usi2.png) 0 0 no-repeat;
  width: 39px;
  height: 13px;
  transition: 0.3s;
}

.wedd {
  padding: 0 15px;
  margin: 0 20px;
  line-height: 40px;
  font-size: 16px;
  overflow: hidden;
  background: #fff;
  color: #000;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  border-radius: 5px;
  border: 3px solid #000;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.wedd:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: url(../img/rings.png) 0 0 no-repeat;
  width: 31px;
  height: 23px;
  margin-right: 15px;
}

.wedd:hover,
.wedd.active {
  background: var(--main-color);
  color: #fff;
}

.wedd:hover:before,
.wedd.active:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: url(../img/rings2.png) 0 0 no-repeat;
  width: 31px;
  height: 23px;
  transition: 0.3s;
}

.tests-btns {
  border-bottom: none;

  a {
    @include dec-res;

    @include for-xs {
      width: 100%;
      margin: 5px 0;
      text-align: center;
    }
  }
}

.owl-carousel.owl-custom-btns {
  .owl-nav {
    padding-top: 0;
    margin-top: 0;
    text-align: right;

    @include for-md {
      text-align: center;
    }

    button {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: var(--main-color);
      color: var(--white);
      font-size: 17px;

      // &.owl-prev {
      //   margin-right: 68px;
      // }

      &.disabled {
        background-color: var(--border-color);
        pointer-events: none;
      }

      .icon {
        height: 7px;
        width: 11px;
      }
    }
  }
}

.collage {
  box-shadow: var(--default-box-shadow);

  --collage-body-height: 500px;

  @include for-md {
    --collage-body-height: 360px;
  }

  @include for-xs {
    flex-direction: column;
  }

  &-body {
    background: #d6d8d8;
    max-width: calc(100% - 74px);
    position: relative;
    height: var(--collage-body-height);

    &.__savedCollage {
      max-width: none;
    }

    @include for-xs {
      max-width: none;
      height: calc(100vh - 74px);
      margin: 0 -20px;
    }

    &.collages-uploaded {
      .collage-upload {
        display: none !important;
      }
    }
  }

  &-sidebar {
    width: 74px;
    background: var(--white);
    padding: var(--blocks-padding-size);
    --icon-color: var(--default-font-color);

    @include for-xs {
      width: auto;
      margin: 0 -20px;
      flex-direction: row !important;
      padding-bottom: 0;
    }

    > * {
      width: 100%;
      display: block;
      padding: var(--blocks-margin-size) 0;
      color: var(--icon-color);
      text-align: center;
      transition: color 0.4s;

      &:hover {
        color: var(--main-color);
      }

      @include for-xs {
        margin-bottom: 20px;
        display: inline-block;
        vertical-align: top;
        width: auto;
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--border-color);

        @include for-xs {
          border-bottom: none;
          border-right: 1px solid var(--border-color);
        }
      }

      &:hover,
      &.active {
        --icon-color: var(--main-color);
      }

      @include for-md {
        padding: 12px 0;
      }

      @include for-xs {
        padding: 0 10px;

        &.hide-for-mob,
        &.toggle-for-mob {
          display: none;
        }
      }

      &.lock-btn {
        &:not(.active) {
          .icon:last-child {
            display: none;
          }
        }

        &.active {
          .icon:first-child {
            display: none;
          }
        }
      }

      .icon {
        stroke: var(--icon-color);
        display: block;
        margin: 0 auto;
        transition: stroke 0.4s;

        &-photo {
          width: 25px;
          height: 25px;

          @include for-md {
            width: 20px;
            height: 20px;
          }

          @include for-md {
            width: 30px;
            height: 30px;
          }
        }

        &-up,
        &-down {
          width: 17px;
          height: 19px;

          @include for-md {
            width: 15px;
            height: 17px;
          }

          @include for-xs {
            width: 19px;
            height: 22px;
          }
        }

        &-lock,
        &-unlock {
          width: 23px;
          height: 31px;

          @include for-md {
            width: 17px;
            height: 23px;
          }

          @include for-xs {
            width: 24px;
            height: 32px;
          }
        }

        &-trash {
          width: 25px;
          height: 29px;

          @include for-md {
            width: 19px;
            height: 22px;
          }

          @include for-xs {
            width: 25px;
            height: 30px;
          }
        }

        &-save {
          width: 24px;
          height: 25px;

          @include for-md {
            width: 19px;
            height: 20px;
          }

          @include for-xs {
            width: 26px;
            height: 27px;
          }
        }

        &-folder {
          width: 24px;
          height: 22px;

          @include for-md {
            width: 22px;
            height: 20px;
          }

          @include for-xs {
            width: 30px;
            height: 27px;
          }
        }

        &-menu {
          height: 20px;
          width: 4px;
        }

        &-photo,
        &-save,
        &-folder {
          fill: none;
        }

        &-up,
        &-down,
        &-lock,
        &-unlock,
        &-trash {
          stroke: none;
        }
      }
    }
  }

  &-uploaded-images {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-upload {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(../images/temp/collage-bg.jpg) no-repeat left center / cover;

    .sub-title-1 {
      @include for-xs {
        font-size: 15px;
      }
    }

    p {
      margin-bottom: var(--blocks-margin-size);
      padding: 0 var(--blocks-margin-size);
    }
  }

  &-filter {
    > a {
      @include colorDefaultToMain;
      font-weight: 700;
      @include dec-res;

      .icon {
        width: 12px;
        height: 8px;
        margin-left: 5px;
      }
    }

    &__content {
      width: 400px;
      padding: 30px 45px;
      box-shadow: var(--default-box-shadow-hover);
      background: #fff;
      position: absolute;
      right: 0;
      top: calc(100% + var(--blocks-padding-size));
      z-index: 2;
      max-width: 100%;

      @include for-xs {
        padding: 30px 10px;
      }

      h4 {
        font-size: 17px;
        font-weight: 700;
        color: var(--default-font-color);
        margin-bottom: var(--blocks-margin-size);
        line-height: 1;
      }
    }

    .form-group {
      margin-bottom: 35px;
    }

    &__gender {
      .text-checkbox {
        &:first-child {
          margin-right: 47px;
        }
      }
    }

    &__colors {
      margin: 0 -5px;

      .color-checkbox {
        margin: 0 10px var(--blocks-margin-size);

        @include for-xs {
          margin: 0 5px 10px;
        }
      }
    }

    &__clothes {
      .form-group {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        .text-checkbox {
          width: calc(33.33% - var(--blocks-margin-size));
          margin: 0 10px var(--blocks-margin-size);
        }
      }
    }
  }

  &-catalog {
    header {
      margin-bottom: 30px;
      position: relative;
      z-index: 3;

      .sub-title-1 {
        color: var(--main-color);
      }

      > * {
        margin: 0;
      }
    }

    &__body {
      margin: 0 -10px;
    }

    &__item {
      margin: 0 10px var(--blocks-margin-size);
      width: calc(16.66% - var(--blocks-margin-size));
      position: relative;
      padding-top: 14%;
      overflow: hidden;

      @include for-md {
        width: calc(25% - var(--blocks-margin-size));
        padding-top: 22%;
      }

      @include for-xs {
        width: calc(50% - var(--blocks-margin-size));
        padding-top: 44%;
      }

      img,
      .adsbygoogle {
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
        height: 100%;
        max-height: 100%;
      }

      .icon {
        position: absolute;
        z-index: 2;
        width: 29px;
        height: 29px;
        top: calc(50% - 14.5px);
        left: calc(50% - 14.5px);
        color: var(--white);
        opacity: 0;
        transition: opacity 0.4s;
      }

      &:not(.__ad-block) {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 1;
          background: rgba(0, 0, 0, 0.2);
          opacity: 0;
          transition: opacity 0.4s;
        }

        &:hover {
          &::before,
          .icon {
            opacity: 1;
          }
        }
      }
    }

    .pagination {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

  &-img {
    &.active {
      .resizers {
        border-color: var(--main-color) !important;
      }
    }

    &.locked {
      .resizer {
        display: none;
      }
    }
  }
}

.collage-notification {
  text-align: center;

  .close {
    position: absolute;
    right: var(--blocks-padding-size);
    top: var(--blocks-padding-size);

    .icon {
      height: 12px;
      width: 12px;
      vertical-align: top;
    }
  }

  .modal {
    &-content {
      padding: 50px;
      border-radius: 0;
    }

    &-header {
      border: none;
      margin-bottom: var(--blocks-margin-size);
      padding: 0;

      .sub-title-1 {
        color: var(--main-color);
      }
    }

    &-body {
      padding: 0;
    }
  }

  .btn-main {
    margin-bottom: var(--blocks-padding-size);
  }
}

.collages-list {
  box-shadow: none;
  background: none;

  .collage-item {
    width: calc(50% - var(--blocks-padding-size));
    padding-bottom: 42%;
    position: relative;
    margin-bottom: 30px;
    position: relative;
    cursor: pointer;

    &::before {
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      content: "";
      z-index: 1;
      transition: opacity 0.4s;
      opacity: 0;
    }

    .icon {
      position: absolute;
      z-index: 2;
      width: 29px;
      height: 29px;
      top: calc(50% - 14.5px);
      left: calc(50% - 14.5px);
      color: var(--white);
      opacity: 0;
      transition: opacity 0.4s;
    }

    &:hover {
      &::before,
      .icon {
        opacity: 1;
      }
    }

    .collage-body {
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;

      .collage-uploaded-images {
        overflow: hidden;
      }
    }
  }
}

.styles-list {
  box-shadow: none;
  background: none;

  .style-item {
    width: calc(33.33% - var(--blocks-padding-size));
    padding-bottom: 42%;
    position: relative;
    margin-bottom: 30px;
    position: relative;
    cursor: pointer;

    .resizer {
      display: none !important;
    }

    .resizers {
      border: none !important;
    }

    &::before {
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      content: "";
      z-index: 4;
      transition: opacity 0.4s;
      opacity: 0;
    }

    .icon {
      position: absolute;
      z-index: 5;
      width: 29px;
      height: 29px;
      top: calc(50% - 14.5px);
      left: calc(50% - 14.5px);
      color: var(--white);
      opacity: 0;
      transition: opacity 0.4s;
    }

    &:hover {
      &::before,
      .icon {
        opacity: 1;
      }
    }

    .wardrobe-body {
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      overflow: hidden;

      &__inner {
        width: 420px;
      }
    }
  }
}

.collage-zoomed,
.style-zoomed {
  .modal-header {
    border: none;
    margin: 0;
    padding: 0;

    .close {
      position: absolute;
      bottom: 100%;
      left: 100%;
      color: var(--white) !important;
      padding: 10px;

      .icon {
        vertical-align: top;
        width: 15px;
        height: 15px;
      }
    }
  }

  .modal-body {
    padding: 0;
  }

  .modal-dialog {
    max-width: 768px;
  }
}

.style-zoomed {
  .modal-dialog {
    width: 420px;
  }

  .resizers {
    border: none !important;
  }

  .remove-img,
  .resizer {
    display: none;
  }

  .wardrobe-body__inner {
    overflow: hidden;
  }
}

.all-services {
  h2 {
    font-size: 25px;
    font-style: italic;
    font-weight: 700;
    margin-bottom: 30px;
  }
  h3 {
    font-size: 20px;
    font-style: italic;
    margin-bottom: 20px;
    color: var(--main-color);
  }
  h4 {
    font-size: 17px;
    font-style: italic;
    margin-bottom: 20px;
    color: var(--main-color);
  }
  strong {
    font-weight: 700;
    color: var(--main-color);
  }
  .btn-main {
    font-size: 16px;
  }
  &__header {
    box-shadow: var(--default-box-shadow);
    margin-bottom: var(--section-mb);
    .__img-cont {
      height: 336px;
      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .__capture {
      padding: 30px 40px 30px 50px;
      font-size: 16px;
      flex-wrap: wrap;
      @include for-tablet {
        flex-direction: column;
      }
      @include for-md {
        padding: 30px 20px;
        font-size: 15px;
      }
      > div {
        flex: 1;
      }
      h2 {
        margin-bottom: 15px;
        @include for-md {
          font-size: 17px;
        }
      }

      .btn-main {
        margin-left: 35px;
        @include for-tablet {
          margin-left: 0;
          width: 100%;
        }
      }
    }
  }
  &__steps {
    margin-bottom: var(--section-mb);
    @include for-md {
      flex-direction: column;
    }
    p {
      font-size: 18px;
    }
    .flex-1 {
      flex: 1;
      @include for-md {
        margin-bottom: 40px;
        .btn {
          width: 100%;
        }
      }
    }
    .list-owl-carousel {
      width: 380px;
      margin-left: 40px;
      max-width: 100%;
      .steps-slider-item {
        width: 380px;
        height: 500px;
        max-width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .owl-nav {
        position: absolute;
        top: 50%;
        height: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        font-size: 70px;
        line-height: 1;
        margin: 0;
        button {
          opacity: 1 !important;
          span {
            color: var(--main-color);
            display: block;
            line-height: 0;
          }
          &.disabled {
            span {
              color: #d3d3d3;
            }
          }
        }
      }
      .owl-dots {
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
      }
    }
  }
}

.consulting {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
  margin-bottom: var(--section-mb);
  @include for-tablet {
    grid-gap: 20px;
  }
  @include for-md {
    display: block;
  }
  &-item {
    @include for-md {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
    &__img {
      height: 235px;
      box-shadow: var(--default-box-shadow);
      margin-bottom: 17px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  h3 {
    font-size: 20px;
    font-weight: 700;
    color: var(--main-color);
    margin-bottom: 10px;
  }
}

.consulting-steps {
  margin-bottom: var(--section-mb);
  @include for-md {
    h2 {
      text-align: center;
    }
  }
  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    text-align: center;
    font-size: 14px;
    @include for-tablet {
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px;
    }
    @include for-md {
      display: block;
    }
    h3 {
      margin-bottom: 10px;
    }
    img {
      margin-bottom: 10px;
    }
  }
  &.__serv2 {
    .consulting-steps__item {
      img {
        max-width: 118px;
        @include for-tablet {
          max-width: 158px;
        }
      }
    }
  }
  &__item {
    @include for-md {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
  &.__spec-sel {
    .consulting-steps__inner {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.consulting-tarrifs {
  margin-bottom: var(--section-mb);
  h2,
  h3 {
    @include for-md {
      text-align: center;
    }
  }
  .d-flex {
    display: flex;
    justify-content: space-between;
    @include for-tablet {
      flex-direction: column;
    }
  }
  .btn {
    width: 100%;
    text-align: center;
  }
  &.__serv2 {
    background: #fff3f7;
    padding: 50px 55px;
    @include for-md {
      margin: 0 -20px 50px;
      padding: 50px 20px;
    }
    .d-flex {
      @include for-tablet {
        flex-direction: row;
      }
      @include for-md {
        flex-direction: column;
      }
    }
    .consulting-tarrifs__left {
      box-shadow: none;
      width: 50%;
      padding: 0;
      @include for-md {
        width: 100%;
      }
      &:first-child {
        padding-right: 30px;
        @include for-md {
          padding-right: 0;
        }
      }
    }
    h2 {
      max-width: 60%;
      @include for-tablet {
        max-width: 100%;
        text-align: center;
      }
    }
    h3 {
      font-size: 20px;
      color: var(--default-font-color);
    }
    .__old-price {
      color: #b3b3b3;
      font-size: 20px;
      font-weight: 700;
      span {
        position: relative;
        &::after {
          position: absolute;
          width: 100%;
          height: 0px;
          left: 0;
          top: 10px;
          content: "";
          border: 1px solid #f19386;
          transform: rotate(15deg);
        }
      }
    }
    .__price {
      margin-bottom: 30px;
      strong {
        font-weight: 700;
        font-size: 24px;
        color: var(--main-color);
        margin-right: 25px;
      }
    }
  }
  &__left {
    background: #fff3f7;
    box-shadow: 0px 0px 10px rgba(0, 10, 35, 0.09);
    padding: 50px 55px;
    flex: 1;
    @include for-tablet {
      margin-bottom: 40px;
    }
    @include for-md {
      padding: 30px 20px;
    }
  }
  &__right {
    width: 360px;
    margin-left: 36px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 10, 35, 0.04);
    padding: 50px 35px 50px 50px;
    @include for-tablet {
      width: auto;
      margin: 0;
    }
    @include for-md {
      padding: 30px 20px;
    }
    h3 {
      color: var(--default-font-color);
    }
  }
}

.gift-block {
  position: relative;
  padding: 50px 50% 50px 54px;
  color: var(--white);
  min-height: 344px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: var(--section-mb);

  @include for-tablet {
    padding-right: 40%;
  }
  @include for-md {
    color: var(--default-font-color);
    padding: 30px 20px 0;
  }
  &.__sale {
    padding-right: 35%;
    @include for-tablet {
      padding-right: 25%;
    }
    @include for-md {
      padding: 30px 0 0;
    }
    h2 {
      margin: 0;
    }
    ul {
      margin: 30px 0;
      padding: 0;
      list-style: none;
      li {
        background: url(../images/temp/ok.png) no-repeat left 5px;
        padding-left: 25px;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        @include for-md {
          background: url(../images/temp/ok-red.png) no-repeat left 5px;
        }
      }
    }
    .d-flex {
      white-space: nowrap;
      width: 100%;
      @include for-md {
        flex-wrap: wrap;
        margin-bottom: 30px;
        flex-direction: column-reverse;
        align-items: flex-start !important;
        justify-content: flex-start;
        h2 {
          color: var(--main-color);
        }
        .btn-main {
          width: 100%;
          margin-top: 15px;
        }
      }
      .btn-main {
        margin-right: 35px;
      }
    }
  }
  > *:not(img) {
    z-index: 2;
    position: relative;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
    @include for-md {
      position: relative;
      object-position: right;
      min-height: 190px;
    }
  }
  &:not(.__sale) {
    .btn-main {
      @media (min-width: 768px) {
        background: var(--white);
        color: var(--main-color);
        border: none;
      }
      @include for-md {
        width: 100%;
        margin-bottom: 33px;
      }
    }
  }
  h2 {
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 20px;
  }
}

.faq-block {
  display: flex;
  margin-bottom: var(--section-mb);

  @include for-md {
    position: relative;
    padding-top: 90px;
    flex-direction: column;
  }
  h2 {
    @include for-md {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: list;
    li {
      margin-bottom: 25px;
      > h4 {
        color: var(--default-font-color);
        position: relative;
        padding-right: 50px;
        cursor: pointer;
        &.active {
          color: var(--main-color);
          &::after {
            background: url(../images/arrow-up.png) no-repeat;
          }
        }
        &::before {
          counter-increment: list 1;
          content: counter(list) ". ";
        }
        &::after {
          content: "";
          width: 20px;
          height: 12px;
          background: url(../images/arrow-down.png) no-repeat;
          position: absolute;
          right: 0;
          top: 8px;
        }
      }
      div {
        display: none;
      }
    }
  }
  &__list {
    width: 390px;
    margin-left: 50px;
    @include for-md {
      width: auto;
      margin-left: 0;
    }
  }
  .__img-cont {
    flex: 1;
    @include for-md {
      margin-bottom: 30px;
      text-align: center;
    }
    img {
      max-width: 100%;
    }
  }
  .btn {
    width: 100%;
  }
}

.feedback-form {
  display: flex;
  margin-bottom: var(--section-mb);
  font-style: 17px;
  @include for-tablet {
    padding-top: 100px;
    position: relative;
  }
  @include for-md {
    flex-direction: column;
  }
  p {
    font-weight: 700;
    margin-bottom: 15px;
    font-style: italic;
  }
  .__form {
    width: 330px;
    margin-right: 110px;
    max-width: 100%;
    @include for-tablet {
      margin-right: 20px;
    }
    @include for-md {
      margin-right: 0;
      margin-bottom: 30px;
      width: auto;
    }
  }
  .__img-cont {
    flex: 1;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .__contacts {
    margin-bottom: 40px;
    @include for-md {
      flex-direction: column;
      .social-nets {
        padding-top: 20px;
      }
    }
    .icon.icon-phone-contact {
      fill: none;
      stroke: var(--default-font-color);
      width: 16px;
      height: 16px;
      margin-right: 10px;
      vertical-align: middle;
      line-height: 1;
    }
  }
  .btn.btn-main {
    width: 100%;
  }
  h2 {
    @include for-tablet {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
    }
  }
}

.mce-content-body {
  .owl-carousel {
    display: block !important;
  }
}

.service-spec {
  display: flex;
  margin-bottom: 110px;
  @include for-md {
    flex-direction: column-reverse;
  }
  .__img-cont {
    width: 45%;
    margin-right: 50px;
    @include for-md {
      width: auto;
      margin-right: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  > div:not(.__img-cont) {
    flex: 1;
    @include for-md {
      margin-bottom: 50px;
    }
  }
  .btn {
    width: 100%;
  }
}
