.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: -19px;
  display: none;

  @include for-tablet {
    display: block;
  }
}

.hamRotate.active {
  transform: rotate(45deg);
}

.hamRotate180.active {
  transform: rotate(180deg);
}

.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: var(--white);
  stroke-width: 4;
  stroke-linecap: round;
}

.ham1 .top {
  stroke-dasharray: 40 139;
}

.ham1 .bottom {
  stroke-dasharray: 40 180;
}

.ham1.active .top {
  stroke-dashoffset: -93px;
}

.ham1.active .bottom {
  stroke-dashoffset: -145px;
}