// breakpoints
$bp-320: 320px;
$bp-575: 575px;
$bp-639: 639px;
$bp-767: 767px;
$bp-991: 991px;
$bp-1023: 1023px;
$bp-1279: 1279px;
$bp-1440: 1440px;
$bp-1700: 1700px;

$main-bg-gradient: linear-gradient(180deg, #CA0444 0%, #E01355 52.08%, #CA0444 100%);
;