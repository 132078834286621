@mixin for-xs {
  @media (max-width: $bp-575) {
    @content;
  }
}

@mixin for-md {
  @media (max-width: $bp-767) {
    @content;
  }
}

@mixin for-tablet {
  @media (max-width: $bp-1023) {
    @content;
  }
}

@mixin for-lg {
  @media (max-width: $bp-1440) {
    @content;
  }
}

@mixin for-md639 {
  @media (max-width: $bp-639) {
    @content;
  }
}

@mixin for-md1279 {
  @media (max-width: $bp-1279) {
    @content;
  }
}


@mixin colorDefaultToMain {
  transition: color 0.3s;
  color: var(--default-font-color);

  &:hover {
    color: var(--main-color);
  }
}

@mixin scaleOnHover {
  transition: transform 0.4s;
  cursor: pointer;

  &:hover {
    transform: scale(1.15);
  }
}