.png-icon-ex-point {
  background-image: url('../images/sprite.png');
  background-position: -281px -78px;
  width: 10px;
  height: 65px;
  +breakpoint(retina) {
    background-image: url('../images/sprite-2x.png');
    background-size: 304px 194px;
  }
}

.png-icon-mark {
  background-image: url('../images/sprite.png');
  background-position: -207px -148px;
  width: 15px;
  height: 11px;
  +breakpoint(retina) {
    background-image: url('../images/sprite-2x.png');
    background-size: 304px 194px;
  }
}

.png-icon-new {
  background-image: url('../images/sprite.png');
  background-position: -207px -78px;
  width: 74px;
  height: 70px;
  +breakpoint(retina) {
    background-image: url('../images/sprite-2x.png');
    background-size: 304px 194px;
  }
}

.png-icon-service-1 {
  background-image: url('../images/sprite.png');
  background-position: -97px -104px;
  width: 98px;
  height: 80px;
  +breakpoint(retina) {
    background-image: url('../images/sprite-2x.png');
    background-size: 304px 194px;
  }
}

.png-icon-service-2 {
  background-image: url('../images/sprite.png');
  background-position: 0px -104px;
  width: 97px;
  height: 90px;
  +breakpoint(retina) {
    background-image: url('../images/sprite-2x.png');
    background-size: 304px 194px;
  }
}

.png-icon-service-3 {
  background-image: url('../images/sprite.png');
  background-position: -102px 0px;
  width: 105px;
  height: 89px;
  +breakpoint(retina) {
    background-image: url('../images/sprite-2x.png');
    background-size: 304px 194px;
  }
}

.png-icon-service-4 {
  background-image: url('../images/sprite.png');
  background-position: -207px 0px;
  width: 97px;
  height: 78px;
  +breakpoint(retina) {
    background-image: url('../images/sprite-2x.png');
    background-size: 304px 194px;
  }
}

.png-icon-service-5 {
  background-image: url('../images/sprite.png');
  background-position: 0px 0px;
  width: 102px;
  height: 104px;
  +breakpoint(retina) {
    background-image: url('../images/sprite-2x.png');
    background-size: 304px 194px;
  }
}

