.resizable {
  // background: var(--white);
  width: 100px;
  height: 100px;
  position: absolute;
  top: 100px;
  left: 100px;
}

.resizable .resizers {
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  box-sizing: border-box;
  transition: border-color 0.4s;

  &:hover {
    border-color: var(--main-color);

    .resizer,
    .remove-img {
      opacity: 1;
    }
  }
}

.resizable .resizers .resizer {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  /*magic to turn square into circle*/
  background: var(--white);
  border: 2px solid var(--main-color);
  position: absolute;
  opacity: 0;
  transition: opacity 0.4s;
}

.resizable .resizers .resizer.top-left {
  left: -5px;
  top: -5px;
  cursor: nwse-resize;
  /*resizer cursor*/
}

.resizable .resizers .resizer.top-right {
  right: -5px;
  top: -5px;
  cursor: nesw-resize;
}

.resizable .resizers .resizer.bottom-left {
  left: -5px;
  bottom: -5px;
  cursor: nesw-resize;
}

.resizable .resizers .resizer.bottom-right {
  right: -5px;
  bottom: -5px;
  cursor: nwse-resize;
}